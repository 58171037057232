import React, { useEffect, useState, version } from 'react'
import styled from 'styled-components'
import NoCom from 'assets/images/png/Group 48097700.png'
import { ReactComponent as LocationIcon } from 'assets/images/svg/Location.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/svg/arrow-icon.svg'
import { ReactComponent as IconRepair } from 'assets/images/svg/repair-icon.svg'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import IconUpdateOrg from 'assets/images/svg/gear-orange-update.svg'
import IconUpdateGreen from 'assets/images/svg/gear-green.svg'
import { Button } from 'antd'
// import DisplayAddress from '../getAddress'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const CardTracking = ({
  complaintId,
  setPreviewImg,
  setPreviewVdo,
  setOpenModalImage,
  setOpenModalVdo,
  dataComplaint,
}) => {
  const [MeOrganize, setMeOrganize] = useState([])
  const [dataTracking, setDataTracking] = useState([])
  const navigate = useNavigate()
  const token = localStorage.getItem('access_token')

  useEffect(() => {
    GetMeData()
    getAllTracking()
  }, [])

  const getAllTracking = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/all/tracking?filters=complaint_id:eq:${complaintId}&?sort=updated_at:ASC`
      const resTracing = await axios.get(`${shemadata}`)
      if (resTracing.status === 200 || resTracing.status === 201) {
        setDataTracking(resTracing?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const GetMeData = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/me`
      const resMeData = await axios.get(`${shemadata}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (resMeData.status === 200 || resMeData.status === 201) {
        setMeOrganize(resMeData?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const Mapmedia = dataMedia => {
    const CardUi = dataMedia.map((item, index) => {
      if (item.includes('mp4')) {
        return (
          <div
            className="show-image-div click"
            onClick={() => {
              setPreviewVdo(item)
              setOpenModalVdo(true)
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
              <video style={{ width: '180px', height: '120px', borderRadius: '8px', margin: '10px 5px' }}>
                <source src={item} type="video/mp4" />
              </video>
              <div style={{ position: 'absolute' }}>
                {/* นำ icon มาวางที่นี่ */}
                <IvdoPlay />
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <img
              style={{ borderRadius: '8px', width: '180px', height: '120px', margin: '10px 5px' }}
              className="show-image click"
              src={item}
              alt="image"
              onClick={() => {
                setOpenModalImage(true)
                setPreviewImg(item)
              }}
            />
          </div>
        )
      }
    })
    return CardUi
  }

  const NextPageTracking = () => {
    navigate('/complaint-staff/update-form-status', {
      state: {
        type: 'tracking',
        complaintId,
      },
    })
  }

  return (
    <>
      {dataTracking.length > 0 && (
        <div>
          <ContainerView className="pink-bg">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Title>ข้อมูลการติดตามงาน</Title>
            </div>

            <div>หากการติดตามเสร็จสิ้น สามารถกด “ปฎิบัติงานเสร็จสิ้น” เพื่อจบการปฎิบัติงาน</div>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              {dataTracking.map((itemBox, indexBox) => {
                return (
                  <Boxbg Bgcolor="#ffff" Padding="1.5em" BdRadius="24px" Bwidth={'96%'}>
                    <BoxResult>
                      <HeadeResult>
                        <IconRepair />
                        <div className="header-result" style={{ marginLeft: '15px' }}>
                          ข้อมูลติดตามการปฎิบัติงานครั้งที่ {itemBox?.no}
                          <div style={{ fontSize: '14px' }}>{itemBox?.description}</div>
                        </div>
                      </HeadeResult>

                      <div style={{ display: 'flex' }}>{Mapmedia(itemBox?.media_url)}</div>

                      <div style={{ display: 'flex' }}>
                        <LocationIcon />
                        <div>
                          <p style={{ color: '#C4C4C4', marginLeft: '15px' }}>
                            {itemBox?.address}
                            {/* {itemBox?.coordinates && <DisplayAddress coordinates={itemBox?.coordinates} />} */}
                          </p>
                        </div>
                      </div>
                    </BoxResult>
                  </Boxbg>
                )
              })}
            </div>
          </ContainerView>
        </div>
      )}
    </>
  )
}

export default CardTracking

const ButtonEditCustom = styled(Button)`
  font-size: 16px;
  width: 129px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border: 1px solid var(--web-backend-border-primary, #f36b24);
  border-color: #f36b24;
  color: black;
  :focus,
  :hover {
    font-size: 16px;
    width: 129px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
    border-radius: 8px;
    border: 1px solid var(--web-backend-border-primary, #f36b24);
    border-color: #f36b24;
    color: black;
  }
  span {
    color: #f36b24;
  }
`

const LayoutTracking = styled.div`
  background-color: #fff8f4;
  padding: 20px;
`

const Title = styled.div`
  display: flex;
  font-size: 18px;
`

const Boxcard = styled.div`
  display: flex;
`

const ImageCard = styled.div`
  /* margin: 3%; */
  align-self: center;
  img {
    /* min-height: 5.5vw; */
    /* 
 width: '180px', height: '120px' */
    min-width: 180px;
    min-height: 120px;
  }
`

const ContainerView = styled.div`
  background-color: #ffff;
  border-radius: 24px;
  padding: 2em;
  margin-bottom: 20px;

  &.pink-bg {
    background-color: #fff8f4;
  }
`
const Boxbg = styled.div`
  background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#ffff')};
  border-radius: ${props => (props.BdRadius ? `${props.BdRadius}` : '16px')};
  padding: ${props => (props.Padding ? `${props.Padding}` : '1em')};
  width: ${props => (props.Bwidth ? `${props.Bwidth}` : '100%')};

  margin: 10px;
`
const HeadeResult = styled.div`
  display: flex;
  flex-direction: row;
  /* margin: 0 1em; */
`

const BoxMedia = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 19px;
`
const ArrowIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`

const BoxResult = styled.div`
  display: flex;
  flex-direction: column;
  margin: -1 10px;

  .header-result {
    display: flex;
    flex-direction: column;
    font-size: 19px;
    margin: 0 4px;
  }

  .center-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ButtonEdit = styled(Button)`
  &.ant-btn {
    box-shadow: none;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    /* background: #f36b24; */

    background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#f36b24')};
    width: 140px;
    height: 40px;
  }

  &.ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    border-radius: 6px;
    border: none;
    width: 140px;
    height: 40px;
  }
`
