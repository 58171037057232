import { Button, Form, Input, Select, Modal, Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import { BoxForm, CustomUploadfile, BoxBtn, BoxBtnInMap, CustomModal, CustomPopover, BoxTimer } from './form.style'
import TextArea from 'antd/lib/input/TextArea'
import UploadFile from 'components/Inputs/UploadFile'
import Viewmap from './MapSelectLocation'
import { conversDate } from 'core/utils/DateTimeFormat'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import moment from 'moment'
import { getList } from 'core/action/collection'
import { dePartMentIcon } from '../container'
import { ReactComponent as CloseModelMap } from '../image/svg/CloseModelMap.svg'
import { ReactComponent as Back } from '../image/svg/Back.svg'
import useGetAddress from 'core/hooks/useGetAddress'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import axios from 'axios'
import iconInfo from '../image/svg/icon-info.svg'
import DateIcon from '../image/png/Group 48097655.png'
import BeforeIcon from '../image/png/Before Success.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { unixToDateThai } from 'util/date-format'

const { Option } = Select
export default function Index() {
  const [formAntd] = Form.useForm()
  const navigate = useNavigate()
  let location = useLocation()
  const [cover, setCover] = useState([])
  const [typeComplant, setTypeComplant] = useState([])
  const [subCatagory, setSubCatagory] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenError, setIsModalOpenError] = useState(false)
  const [clickedLatLng, setClickedLatLng] = React.useState(null)
  const [loaddingUpdate, setloaddingUodate] = useState(false)
  const [loaddingPage, setloaddingPage] = useState(false)
  const [disableBtn, setDisableBtn] = useState(true)
  const [leaveModalOpen, setLeaveModalOpen] = useState(false)
  const [meLocation, setMeLocation] = useState({ lat: 0, lng: 0 })
  const [dueDate, setDuedate] = useState(0)
  const [notiDate, setNotiDate] = useState(0)
  const [masterDepartment, setMasterDepartment] = useState([])
  const [statusMaster, setStatusMaster] = useState([])
  const [dataEdit, setDataEdit] = useState([])
  const [modalConfirmEdit, setModalConfirmEdit] = useState(false)
  const token = localStorage.getItem('access_token')

  const address = useGetAddress(clickedLatLng ? clickedLatLng : meLocation)

  let arrArear = []
  NakhonRatchasima?.features[0]?.geometry?.coordinates[0]?.map((item, index) => {
    arrArear.push({ lat: item[1], lng: item[0] })
  })
  useEffect(() => {
    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords
            setMeLocation({ lat: latitude, lng: longitude })
          },
          error => {
            console.error('Error getting location:', error.message)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }
    fetchUserLocation() // Call the function when the component mounts
  }, [])

  useEffect(() => {
    getMasterData()
    getAllDepartment()
    getAllUrgency()
    getComplantByID()
  }, [])

  useEffect(() => {
    onChangeNotiDate()
  }, [dueDate])

  useEffect(() => {
    handleChangeForm()
  }, [cover])

  const fnGetSize = async url => {
    return fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
        const calculate = (blob?.size / (1024 * 1024)).toFixed(0)
        return calculate
      })
  }

  const getComplantByID = async () => {
    setloaddingPage(true)
    axios
      .get(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint/${location.state?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async res => {
        setDataEdit(res?.data?.data)
        handleChangeCategory(res?.data?.data?.category?.id, 'getCategory')
        let arrImgUrl = []
        await res?.data?.data?.media_url?.map(async (value, index, array) => {
          arrImgUrl.push(value)
          let sizeFile = await fnGetSize(value)
          await setCover(pre => [
            ...pre,
            {
              name: value.split('/media/videos/')[1],
              status: 'success',
              size: sizeFile,
              img: value,
              urlkey: value,
            },
          ])
        })
        setClickedLatLng({
          lat: res?.data?.data?.coordinates?.latitude,
          lng: res?.data?.data?.coordinates?.longitude,
        })
        const moment1 = moment.unix(res?.data?.data?.notidate_at)
        const moment2 = moment.unix(res?.data?.data?.duedate_at)
        // Calculate the difference in days
        const diffInDays = moment2.diff(moment1, 'days')
        formAntd.setFieldsValue({
          fristName: res?.data?.data?.name?.first_name,
          lastName: res?.data?.data?.name?.last_name,
          tel: phoneNumberAutoFormat(res?.data?.data?.mobile_number),
          category_id: res?.data?.data?.category?.id,
          sub_category_id: res?.data?.data?.sub_category_data?.id,
          complaint_name: res?.data?.data?.complaint_name,
          description: res?.data?.data?.description,
          media: arrImgUrl,
          notiDate: res?.data?.data?.notidate_at ? diffInDays : 'ทุกวัน',
          dueDate: res?.data?.data?.duedate_at
            ? [
                moment.unix(res?.data?.data?.duedate_at).diff(moment.unix(res?.data?.data?.created_at), 'days') < 0
                  ? 0
                  : moment
                      .unix(res?.data?.data?.duedate_at)
                      .diff(moment.unix(res?.data?.data?.created_at), 'days')
                      .toString(),
              ]
            : [],
          department_data: res?.data?.data?.department_data?.map((value, index, array) => {
            return value?.id
          }),
          urgency: res?.data?.data?.urgency?.id ? res?.data?.data?.urgency?.id : [],
        })
        setTimeout(() => {
          setloaddingPage(false)
        }, 2000)
      })
      .catch(err => console.log('err', err))
  }

  const checkArear = val => {
    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arrArear,
    })
    const pointToCheck = new window.google.maps.LatLng(val?.lat, val?.lng)
    if (
      window.google.maps.geometry &&
      window.google.maps.geometry.poly &&
      window.google.maps.geometry.poly.containsLocation
    ) {
      const isInside = window.google.maps.geometry.poly.containsLocation(pointToCheck, bermudaTriangle)
      return isInside
    } else {
      console.error("Google Maps 'containsLocation' method is not available.")
      return false // Return false assuming the location is outside if the check cannot be performed
    }
  }
  const getAllDepartment = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/master/api/v1/all/organizations`
      const resDepartment = await axios.get(`${shemadata}`)
      if ((resDepartment.status === 200) | (resDepartment.status === 201)) {
        setMasterDepartment(resDepartment?.data?.data)
      }
    } catch (error) {}
  }
  const getAllUrgency = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/master/api/v1/all/urgency`
      const resStatus = await axios.get(`${shemadata}`)
      if ((resStatus.status === 200) | (resStatus.status === 201)) {
        setStatusMaster(resStatus?.data?.data)
      }
    } catch (error) {}
  }
  const getMasterData = async () => {
    const resCategories = await getList({
      schema: '/master/api/v1/all/categories',
      params: '',
    })
    setTypeComplant(resCategories?.items)
  }
  let masterDataTypeComplant = typeComplant?.map(item => {
    return {
      label: (
        <div>
          <img src={item?.icon_url} style={{ marginRight: '10px', width: '14px', height: '14px' }} />
          {item.name}
        </div>
      ),
      value: item.id,
    }
  })

  const validatePhone = async () => {
    let valForm = formAntd.getFieldsValue()
    const res = await getList({
      schema: '/complaint/api/v1/all/complaint',
    })
    let arrPhone = []
    res?.items?.map(item => {
      arrPhone.push(item?.mobile_number)
    })
    let checkPhone = arrPhone.includes(valForm?.tel.replaceAll('-', ''))
    const isValidThaiPhoneNumber = /^0\d{2}-\d{3}-\d{4}$/.test(valForm?.tel)
    if (valForm?.tel?.length === 12 && isValidThaiPhoneNumber && !checkPhone) {
      handleChangeForm()
      formAntd.setFields([
        {
          name: 'tel', // required
          value: formAntd.getFieldValue('tel'), //optional
          errors: [],
        },
      ])
    } else if (checkPhone) {
      formAntd.setFields([
        {
          name: 'tel', // required
          value: formAntd.getFieldValue('tel'), //optional
          errors: ['หมายเลขโทรศัพท์นี้ ถูกใช้งานแล้ว กรุณากรอกหมายเลขอื่น '],
        },
      ])
    } else if (valForm?.tel?.length === 12 && !isValidThaiPhoneNumber && !checkPhone) {
      formAntd.setFields([
        {
          name: 'tel', // required
          value: formAntd.getFieldValue('tel'), //optional
          errors: ['รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง'],
        },
      ])
    } else {
      setDisableBtn(true)
      formAntd.setFields([
        {
          name: 'tel', // required
          value: formAntd.getFieldValue('tel'), //optional
          errors: ['กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก'],
        },
      ])
    }
  }

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber?.trim()?.replace(/[^0-9]/g, '')
    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const validateName = () => {
    let valForm = formAntd.getFieldsValue()
    if ((valForm.fristName || valForm?.lastName)?.length) {
      if (
        /^[ก-๛ ]+$/.test(valForm.fristName || valForm?.lastName) &&
        (valForm.fristName || valForm?.lastName)?.length <= 255
      ) {
        handleChangeForm()
        return Promise.resolve()
      } else if ((valForm.fristName || valForm?.lastName)?.length > 255) {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกไม่เกิน 255 ตัวอักษร')
      } else {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกภาษาไทยเท่านั้น')
      }
    }
  }

  const validateComplantDetail = () => {
    let valForm = formAntd.getFieldsValue()
    if ((valForm?.complaint_name || valForm?.description)?.length) {
      if ((valForm?.complaint_name || valForm?.description)?.length <= 255) {
        handleChangeForm()
        return Promise.resolve()
      } else if ((valForm?.complaint_name || valForm?.description)?.length > 255) {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกไม่เกิน 255 ตัวอักษร')
      }
    }
  }

  const handleChangeForm = async () => {
    setTimeout(async () => {
      let valForm = formAntd.getFieldsValue()
      if (valForm.tel) {
        formAntd.setFieldValue('tel', phoneNumberAutoFormat(valForm.tel))
      }
      let arrFile = []
      cover &&
        cover.map(item => {
          arrFile?.push(item?.img)
        })
      if (
        !valForm?.fristName?.length ||
        !valForm?.lastName?.length ||
        !valForm?.tel?.length ||
        !valForm?.category_id?.length ||
        !valForm?.sub_category_id?.length ||
        !valForm?.complaint_name?.length ||
        !valForm?.department_data?.length ||
        !valForm?.department_data?.length ||
        !arrFile?.length ||
        (valForm?.category_id === '1735509869718401030' &&
          !valForm?.water_number?.length &&
          !valForm?.area_number?.length) ||
        (valForm?.category_id === '1735509869739372551' && !valForm?.description?.length)
      ) {
        setDisableBtn(true)
      } else {
        setDisableBtn(false)
      }
    }, 500)
  }

  const handleChangeCategory = async (val, type) => {
    if (type !== 'getCategory') {
      setSubCatagory([])
    }
    const res = await getList({
      schema: `/master/api/v1/all/subcategories/${val}`,
      params: '',
    })
    res?.items.map((value, index, array) => {
      if (type !== 'getCategory') {
        formAntd.setFieldValue('sub_category_id', null)
      }
      setSubCatagory(per => [
        ...per,
        {
          label: value?.name,
          value: value?.ID,
        },
      ])
    })
  }

  const handleClickMap = event => {
    if (isModalOpen) {
      const cord = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }

      if (checkArear(cord)) {
        setClickedLatLng({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        })
      } else {
        setIsModalOpenError(true)
      }
    }
  }
  const handleUpdateStatusFile = async code => {
    cover.map(item => {
      const body = {
        status: 'active',
        url: item?.img,
      }
      axios
        .put(process.env.REACT_APP_MEDIA + '/medias', body)
        .then(res => {
          if (res?.status === 200 || res?.status === 201) {
            setTimeout(() => {
              setloaddingUodate(false)
              localStorage.setItem('isEditComplant', true)
              navigate('/complaint/bof-overview-complaint')
            }, 2000)
          }
        })
        .catch(err => {
          setloaddingUodate(false)
          console.log('err', err)
        })
    })
  }

  const handleUpdateComplant = async () => {
    setloaddingUodate(true)
    let isInsidePolygon = await checkArear({
      lat: clickedLatLng?.lat || meLocation?.lat,
      lng: clickedLatLng?.lng || meLocation?.lng,
    })
    if (!isInsidePolygon) {
      setIsModalOpenError(true)
      setDisableBtn(true)
      setModalConfirmEdit(false)
    } else {
      const value = formAntd.getFieldsValue()
      let arrFile = []
      cover.map(item => {
        arrFile.push(item?.img)
      })
      const body = {
        address: dataEdit?.address,
        area_number: value?.area_number ? value?.area_number : '',
        category_id: value?.category_id,
        channel: 'เว็บไซต์',
        complaint_name: value?.complaint_name,
        coordinates: {
          latitude: clickedLatLng?.lat ? clickedLatLng?.lat : meLocation?.lat,
          longitude: clickedLatLng?.lng ? clickedLatLng?.lng : meLocation?.lng,
        },
        description: value?.description,
        district_id: dataEdit?.district_id,
        duedate_at: dueDate ? dueDate : dataEdit?.duedate_at,
        media_url: arrFile,
        mobile_number: value?.tel?.replace(/-/g, ''),
        notidate_at: notiDate === 'ทุกวัน' ? '' : notiDate,
        name: {
          first_name: value?.fristName,
          last_name: value?.lastName,
        },
        department_data: value?.department_data,
        province_id: dataEdit?.province_id,
        org_id: '19118133238380',
        sub_category_id: value?.sub_category_id,
        sub_district_id: dataEdit?.sub_district_id,
        water_number: value?.water_number ? value?.water_number : '',
        urgency_id: value?.urgency,
        zib_code: dataEdit?.zib_code,
      }
      axios
        .put(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint/${dataEdit?.id}`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res?.data?.code === 200) {
            setModalConfirmEdit(false)
            handleUpdateStatusFile(res?.data?.code)
          } else {
            setloaddingUodate(false)
            setModalConfirmEdit(false)
          }
        })
        .catch(err => {
          setloaddingUodate(false)
          console.log('err', err)
        })
    }
  }

  const handleOk = () => {
    if (checkArear(clickedLatLng?.lat && clickedLatLng?.lng ? clickedLatLng : meLocation)) {
      setClickedLatLng({
        lat: clickedLatLng?.lat ? clickedLatLng?.lat : meLocation?.lat,
        lng: clickedLatLng?.lng ? clickedLatLng?.lng : meLocation?.lng,
      })
      setIsModalOpen(false)
      handleChangeForm()
    } else {
      setIsModalOpenError(true)
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setClickedLatLng(null)
  }

  const onChangeDuedate = value => {
    const momentObject = moment.unix(dataEdit?.created_at)
    const dataDate = moment(momentObject)
      .add(+value, 'days')
      .unix()
    setDuedate(dataDate)
  }

  const onChangeNotiDate = value => {
    let dateNoti = formAntd.getFieldValue('notiDate')
    if (dueDate) {
      const momentObject = moment.unix(dueDate)
      const dataDate = momentObject?.subtract(dateNoti, 'days').unix()
      setNotiDate(dataDate)
    }
  }

  const generateDateOptions = () => {
    const options = []
    for (let i = 1; i <= 15; i++) {
      options.push(
        <Option key={i} value={i.toString()} label={i + ' วัน'}>
          {i + ' วัน'}
        </Option>
      )
    }
    return options
  }

  return (
    <>
      {loaddingPage ? (
        <div style={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin />
        </div>
      ) : (
        <Form layout="vertical" form={formAntd} onValuesChange={handleChangeForm}>
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <Back style={{ marginRight: '-10px', cursor: 'pointer' }} onClick={() => setLeaveModalOpen(true)} />{' '}
            <p style={{ fontSize: '24px', fontWeight: '500', color: '#150F0B', margin: 0 }}>
              แก้ไขข้อมูลเรื่องร้องเรียน
            </p>
          </div>
          <BoxForm>
            <div style={{ width: '800px' }}>
              <div className="box-date-complaint">
                <img src={DateIcon} style={{ marginRight: '10px' }} /> เวลาแจ้งเรื่อง:{' '}
                {conversDate(dataEdit?.created_at)}
              </div>
              <Form.Item
                name="fristName"
                label={
                  <div>
                    ชื่อ <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                rules={[{ validator: validateName }]}
              >
                <Input placeholder="โปรดระบุชื่อ ของผู้ร้องเรียน" disabled />
              </Form.Item>
              <Form.Item
                name="lastName"
                label={
                  <div>
                    นามสกุล <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                rules={[{ validator: validateName }]}
              >
                <Input placeholder="โปรดระบุนามสกุล ของผู้ร้องเรียน" disabled />
              </Form.Item>
              <Form.Item
                name="tel"
                label={
                  <div>
                    เบอร์โทร <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Input placeholder="0XX-XXX-XXXX" maxLength={12} onChange={validatePhone} disabled />
              </Form.Item>
              <div style={{ fontSize: '20px', fontWeight: 500, marginBottom: '10px' }}>รายละเอียดเรื่องร้องเรียน</div>
              <Form.Item
                name="category_id"
                label={
                  <div>
                    ประเภทเรื่องร้องเรียน <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Select
                  placeholder="กรุณาเลือก"
                  options={masterDataTypeComplant}
                  onSelect={val => {
                    setTimeout(() => {
                      handleChangeCategory(val, 'category')
                    }, 500)
                  }}
                  onClear={val => setSubCatagory([])}
                  allowClear
                />
              </Form.Item>
              {subCatagory?.length > 0 && (
                <Form.Item
                  name="sub_category_id"
                  label={
                    <div>
                      ประเภทย่อย <span style={{ color: 'red' }}>*</span>
                    </div>
                  }
                >
                  <Select placeholder="ประเภทย่อย" options={subCatagory} optionLabelProp="label" allowClear />
                </Form.Item>
              )}
              {formAntd.getFieldValue('category_id') === '1735509869718401030' && (
                <Form.Item
                  name="water_number"
                  label={
                    <div>
                      เลขที่ผู้ใช้น้ำ
                      <span style={{ color: 'red' }}>*</span>
                    </div>
                  }
                >
                  <Input placeholder="เลขที่ผู้ใช้น้ำ" />
                </Form.Item>
              )}
              {formAntd.getFieldValue('category_id') === '1735509869718401030' && (
                <Form.Item
                  name="area_number"
                  label={
                    <div>
                      เขตผู้ใช้น้ำ <span style={{ color: 'red' }}>*</span>
                    </div>
                  }
                >
                  <Input placeholder="เขตผู้ใช้น้ำ" />
                </Form.Item>
              )}
              <Form.Item
                name="complaint_name"
                rules={[{ validator: validateComplantDetail }]}
                label={
                  <div>
                    เรื่องร้องเรียน <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Input placeholder="โปรดระบุเรื่องร้องเรียนสั้นๆ เช่น “น้ำไม่ไหล” ,“ต้นไม้เกี่ยว..." />
              </Form.Item>
              <Form.Item
                name="description"
                rules={[{ validator: validateComplantDetail }]}
                label={
                  <div>
                    รายละเอียดเพิ่มเติม <span>(ถ้ามี)</span>
                    {formAntd.getFieldValue('category_id') === '1735509869739372551' && (
                      <span style={{ color: 'red' }}>*</span>
                    )}
                  </div>
                }
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="ระบุรายละเอียดบริเวณที่เกิดปัญหา หรือรายละเอียดของปัญหาเพิ่มเติม"
                />
              </Form.Item>
              <Form.Item name="media">
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <div>
                    อัปโหลดรูปภาพและวิดีโอ (อัปโหลดได้ไม่เกิน 3 ไฟล์) <span style={{ color: 'red' }}>*</span>
                  </div>
                  <CustomPopover
                    placement="leftTop"
                    content={
                      <div style={{ marginTop: '6px' }}>
                        <p>ภาพที่อัปโหลดต้องเป็น “ภาพที่เกี่ยวข้องกับเหตุการณ์”</p>
                      </div>
                    }
                  >
                    <img src={iconInfo} style={{ width: 16, height: 16, cursor: 'pointer' }} />
                  </CustomPopover>
                </div>
                <CustomUploadfile>
                  <UploadFile
                    accept={{
                      'image/jpeg': [],
                      'image/png': [],
                      'video/mp4': [],
                    }}
                    keys={1}
                    files={cover}
                    isCrop={false}
                    maxFile={3}
                    maxSize={1024 * 1024 * 5}
                    typeUpload={'imagevdo'}
                    text={'ไฟล์ที่อัปโหลดต้องเป็นไฟล์นามสกุล JPEG , PNG , MP4 และ มีขนาดแต่ละไฟล์ไม่เกิน 10 MB'}
                    // abortController={abortController}
                    onFileRetuen={(files, file) => {
                      let _image = [...files]
                      _image.push(file)
                      setCover(_image)
                    }}
                    onFileRemove={(files, res) => {
                      // setAbortController(new AbortController())
                      let _image = [...files]
                      _image = _image.filter((r, i) => i !== res.index)
                      setCover(_image)
                    }}
                  />
                </CustomUploadfile>
              </Form.Item>
              <div style={{ marginBottom: '5%' }}>
                <div style={{ marginBottom: '10px' }}>สถานที่แจ้งเรื่อง</div>
                <Viewmap
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  clickedLatLng={clickedLatLng}
                  setClickedLatLng={setClickedLatLng}
                  meLocation={meLocation}
                  address={address[2]?.formatted_address}
                  arrArear={arrArear}
                  setIsModalOpenError={setIsModalOpenError}
                  handleOk={handleOk}
                  handleClickMap={handleClickMap}
                  checkArear={checkArear}
                  dataComplaint={dataEdit}
                />
              </div>
              <Form.Item
                name="dueDate"
                label={
                  <div>
                    วันที่กำหนดเสร็จ <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Select onChange={onChangeDuedate} placeholder="เลือกวันที่กำหนดเสร็จ">
                  {generateDateOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                name="notiDate"
                label={
                  <div>
                    การแจ้งเตือน <span style={{ color: 'red', fontSize: '14px' }}>*</span>
                  </div>
                }
              >
                <Select onChange={onChangeNotiDate} placeholder="เลือกการแจ้งเตือน">
                  <Option value={'ทุกวัน'} label={'ทุกวัน'}>
                    ทุกวัน
                  </Option>
                  <Option value={1} label={'1 วันก่อนเกินระยะเวลาที่กำหนด'}>
                    1 วันก่อนเกินระยะเวลาที่กำหนด
                  </Option>
                  <Option value={2} label={'2 วันก่อนเกินระยะเวลาที่กำหนด'}>
                    2 วันก่อนเกินระยะเวลาที่กำหนด
                  </Option>
                  <Option value={3} label={'3 วันก่อนเกินระยะเวลาที่กำหนด'}>
                    3 วันก่อนเกินระยะเวลาที่กำหนด
                  </Option>
                  <Option value={4} label={'4 วันก่อนเกินระยะเวลาที่กำหนด'}>
                    4 วันก่อนเกินระยะเวลาที่กำหนด
                  </Option>
                </Select>
              </Form.Item>
              <div>
                <div>วันที่คาดว่าจะแล้วเสร็จ</div>
                <BoxTimer>
                  <div style={{ margin: '0 1em', color: '#FFC4AA' }}>
                    {!dueDate && dataEdit?.duedate_at
                      ? unixToDateThai(dataEdit?.duedate_at)
                      : dueDate && (!dataEdit?.duedate_at || dataEdit?.duedate_at)
                      ? unixToDateThai(dueDate)
                      : 'วันที่คาดว่าจะแล้วเสร็จ'}
                  </div>
                </BoxTimer>
              </div>
              <Form.Item
                name="department_data"
                label={
                  <div>
                    หน่วยงานที่รับผิดชอบ <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Select mode="multiple" maxTagCount={3}>
                  {masterDepartment &&
                    masterDepartment?.map((item, index) => (
                      <Option key={index} value={item?.id}>
                        {dePartMentIcon[item?.name]} {item?.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="urgency"
                label={
                  <div>
                    สถานะความเร่งด่วน <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Select>
                  {statusMaster &&
                    statusMaster?.map((item, index) => (
                      <Option key={index} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <Modal
              open={isModalOpen}
              closeIcon={<CloseModelMap onClick={handleCancel} />}
              className="model-map-create-complant"
              title="เลือกตำแหน่งที่ตั้ง"
              onCancel={handleCancel}
              footer={null}
              bodyStyle={{ height: '80vh', width: '90vw', borderRadius: '10px' }}
              style={{ borderRadius: '10px' }}
              centered
            >
              <Viewmap
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                clickedLatLng={clickedLatLng}
                setClickedLatLng={setClickedLatLng}
                meLocation={meLocation}
                address={address[2]?.formatted_address}
                arrArear={arrArear}
                setIsModalOpenError={setIsModalOpenError}
                handleOk={handleOk}
                handleClickMap={handleClickMap}
                checkArear={checkArear}
              />
            </Modal>
            <Modal
              closable={false}
              open={isModalOpenError}
              footer={null}
              className="model-map-create-complant-error"
              centered
              zIndex={1000}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'Prompt , sans-serif',
                  textAlign: 'center',
                }}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '15%',
                    }}
                  >
                    <img src={BeforeIcon} style={{ width: '35px', height: '35px' }} />
                  </div>
                  <p style={{ fontSize: '20px', margin: 0 }}>ไม่อยู่ในเขตที่รับผิดชอบ</p>
                  <p style={{ fontSize: '16px', color: '#C4C4C4', marginBottom: '15%' }}>กรุณาเลือกตำแหน่งใหม่</p>
                  <BoxBtnInMap>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        style={{ width: '100px' }}
                        onClick={() => {
                          setIsModalOpenError(false)
                          setloaddingUodate(false)
                        }}
                      >
                        ตกลง
                      </Button>
                    </div>
                  </BoxBtnInMap>
                </div>
              </div>
            </Modal>
          </BoxForm>
          <BoxBtn>
            <Button
              className="btn-submit-complant"
              type="primary"
              disabled={disableBtn}
              loading={loaddingUpdate}
              onClick={() => setModalConfirmEdit(true)}
            >
              ยืนยัน
            </Button>
          </BoxBtn>
          <CustomModal
            transitionName=""
            centered
            open={leaveModalOpen}
            onOk={() => {
              setLeaveModalOpen(false)
              navigate(-1)
            }}
            onCancel={() => setLeaveModalOpen(false)}
            closable={false}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
          >
            <div style={{ paddingBottom: '10px' }}>
              <ExclamationCircle />
            </div>
            <p
              style={{
                color: '#001F34',
                fontSize: '16px',
                marginBottom: '2px',
                fontWeight: '400',
              }}
            >
              คุณต้องการออกจากหน้านี้?
            </p>
            <p
              style={{
                color: '#597081',
                fontSize: '14px',
                fontWeight: '300',
              }}
            >
              หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก
            </p>
          </CustomModal>
          <CustomModal
            transitionName=""
            centered
            open={modalConfirmEdit}
            onOk={() => {
              handleUpdateComplant()
            }}
            onCancel={() => setModalConfirmEdit(false)}
            closable={false}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
          >
            <div style={{ paddingBottom: '10px' }}>
              <ExclamationCircle />
            </div>
            <p
              style={{
                color: '#001F34',
                fontSize: '16px',
                marginBottom: '2px',
                fontWeight: '400',
              }}
            >
              ยืนยันการแก้ไข ?
            </p>
            <p
              style={{
                color: '#597081',
                fontSize: '14px',
                fontWeight: '300',
              }}
            >
              ข้อมูลที่ถูกแก้ไข จะถูกส่งให้เจ้าหน้าที่ที่รับผิดชอบ และจะไม่สามารถกู้คืนข้อมูลเดิมได้อีก
            </p>
          </CustomModal>
        </Form>
      )}
    </>
  )
}
