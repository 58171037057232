import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Title, BoxBtn, Menucustom, ButtonBack } from './viewMobile.style'
import { CustomModal } from './view_drawer.style'
import {
  checkColorStatusBg,
  checkColorStatusText,
  checkRating,
  checkDueDate,
  checkStatusText,
  dePartMentIcon,
} from '../../container'
import { ReactComponent as Back } from '../../image/svg/Back.svg'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import moment from 'moment'
import AddOfficer from '../../AddOfficer'
import EditOfficer from '../../EditOfficer'
import { adminauth2 } from 'core/schemas'
import { Button, Space, Modal, Dropdown, Tooltip, notification } from 'antd'
import styled from 'styled-components'
import { Spin } from 'antd'
import TableCustom from 'components/TableCustom/Row'
import { ReactComponent as AddStraff } from '../../image/svg/AddStraff.svg'
import { ReactComponent as RepairIcon } from '../../image/svg/Repair.svg'
import Viewmap from '../../viewmap'
import UserContext from 'core/contexts/userContext'
import { ReactComponent as IconViewAdd } from '../../image/svg/Component 16.svg'
import IconNoti from 'assets/images/svg/icon-noti.svg'
import IconClose from 'assets/images/svg/icon-close.svg'
import IconCloseModal from 'assets/images/svg/close-modal.svg'
import axios from 'axios'
import { getData } from 'core/action/collection'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import ExportComplante from '../../export'
import { unixToDateThai } from 'util/date-format'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import { conversDate } from 'core/utils/DateTimeFormat'
import CardResultBox from './cardResult'
import Iconpreview from 'assets/images/svg/icon-preview.svg'
import PaginationCustom from 'components/PaginationCustom'
import { useReactToPrint } from 'react-to-print'
import { ReactComponent as OutOfGroup } from '../../image/svg/Out of group.svg'
import { ReactComponent as Export } from '../../image/svg/Export.svg'
import { ReactComponent as Warning } from '../../image/svg/Warning.svg'
import DeleteComplant from '../../deleteComplant'
import TransferComplaint from '../../TransferComplaint'
import CardTracking from './CardTracking'
import { ReactComponent as Delete } from '../../image/svg/Delete.svg'
import { ReactComponent as Ungroup } from '../../image/svg/Ungroup file.svg'
import { MoreOutlined, EllipsisOutlined } from '@ant-design/icons'
import { chanelData } from 'core/utils/common'

const DropdownCustom = Dropdown

const ViewCompliantMobile = () => {
  const navigate = useNavigate()
  const { meData } = useContext(UserContext)
  const [previewImg, setPreviewImg] = useState(null)
  const [previewVdo, setPreviewVdo] = useState(null)
  const [OpenModalImage, setOpenModalImage] = useState(false)
  const [OpenModalVdo, setOpenModalVdo] = useState(false)
  const [dataComplaint, setDatacomPlaint] = useState([])
  const [mediaShow, setMediaShow] = useState([])
  const [dataTranSaction, setDataTransaction] = useState([])
  const [loading, setLoading] = useState(true)
  const [dataTable, setDataTable] = useState([])
  const [recordID, setRecordID] = useState('')
  const [leaveModalOpen, setLeaveModalOpen] = useState(false)
  const [loadingAddOfficer, setLoadingAddOfficer] = useState(true)
  const [deparmentData, setDeparmentData] = useState([])
  const [openDrawerAddOfficer, setOpenDrawerAddOfficer] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [listAdmin, setListAdmin] = useState([])
  const [complaintId, setComplaintId] = useState('')
  const [openDrawerEditOfficer, setOpenDrawerEditOfficer] = useState(false)
  const [dataLength, setDataLength] = useState([])
  const [curantPage, setCurantPage] = useState(1)
  const [dataExport, setdataExport] = useState([])
  const [openDrawerTransfer, setOpenDrawerTransfer] = useState(false)
  const [isPrint, setIsPrint] = useState(false)
  const [checkBtnEditWork, setCheckBtnEditWork] = useState([])
  const [idSubGroup, setIdSubGroup] = useState([])
  const [modalOutOfGroup, setModalOutOfGroup] = useState({ modal: false, isOut: false })

  const [modalHeadGroup, setModalHeadGroup] = useState({
    modal: false,
  })
  const [isDeleteCompalnt, setIsDeleteCompalnt] = useState({
    Modal: false,
    id: '',
  })
  const organizeId = localStorage.getItem('organize')
  const token = localStorage?.getItem('access_token')
  const location = useLocation()
  const componentRef = useRef()
  useEffect(() => {
    getDataId()
    getListAllAdmin()
  }, [curantPage, location])

  const groupData = data => {
    if (data) {
      const uniqueDepartmentIds = []
      const groupedData = []
      for (let i = 0; i < data?.length; i++) {
        const item = data[i]
        const { department_id } = item
        if (!uniqueDepartmentIds?.includes(department_id)) {
          uniqueDepartmentIds?.push(department_id)
          groupedData?.push([item])
        } else {
          const index = uniqueDepartmentIds?.indexOf(department_id)
          groupedData[index]?.push(item)
        }
      }
      setDataTransaction(groupedData)
    }
  }

  function paginateArray(array, perPage, currentPage) {
    const startIndex = (currentPage - 1) * perPage
    const endIndex = startIndex + perPage
    const paginatedArray = array.slice(startIndex, endIndex)
    return paginatedArray
  }

  const getDataId = async () => {
    setDataTable([])
    setDataLength([])
    setIdSubGroup([])

    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
      const compalintId = location?.state?.record
      const resp = await axios.get(`${shemadata}/${compalintId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      if (resp?.data?.code === 200) {
        setDatacomPlaint(resp?.data?.data)
        setRecordID(resp?.data?.data?.id)
        const dataMedia = resp?.data?.data?.media_url
        setMediaShow(dataMedia)
        const dataTranSaction = resp?.data?.data?.transaction_data
        await groupData(dataTranSaction)
        if (resp?.data?.data?.is_group) {
          let arr = []
          resp?.data?.data?.sub_group?.map((value, index, array) => {
            axios
              .get(`${shemadata}/${value?.id}`, {
                headers: {
                  Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                },
              })
              .then(res => {
                if (res?.data?.code === 200) {
                  arr.push(res?.data?.data)
                  let newArrHeaderKey = arr.filter(item => item?.is_group === true)
                  let newArr = arr?.filter(item => item?.is_group !== true)
                  newArr.unshift(newArrHeaderKey[0])
                  setIdSubGroup(per => [...per, res?.data?.data?.id])
                  setDataLength(per => [...per, res?.data?.data])
                  let addNO = newArr?.map((value, index) => {
                    return { ...value, no: index + 1 }
                  })
                  setDataTable(paginateArray(addNO, 5, curantPage))
                }
              })
          })
        }
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setIsPrint(false)
    },
    documentTitle: `ใบงานเรื่องร้องเรียน_${
      dataExport?.sub_category_name ? dataExport?.sub_category_name : ''
    }_${moment().add(543, 'year').format('DD-MM-YYYY')}.pdf`,
  })
  const handleMenuList = (e, data) => {
    const key = e?.key.split('/')
    if (key[3] === 'update') {
      navigate(e.key, {
        state: {
          record: '',
          isEdit: true,
        },
      })
    } else if (key[0] === 'view-update-status') {
      navigate(e.key, {
        state: {
          record: data?.id,
          isEdit: true,
        },
      })
    } else if (key[3] === 'delete') {
      navigate('/operation-tools/bof-group/view', {
        state: {
          record: '',
          isDelete: true,
        },
      })
    } else if (key[0] === 'export') {
      setIsPrint(true)
      setdataExport(data)
      setTimeout(() => {
        handlePrint(data)
      }, 2000)
    }
  }
  const getDataByRowID = id => {
    const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
    axios
      .get(`${shemadata}/${id ? id : recordID}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      .then(async res => {
        if (res?.data?.code === 200) {
          setDatacomPlaint(res?.data?.data)
          const dataMedia = res?.data?.data?.media_url
          setMediaShow(dataMedia)
          const dataTranSaction = res?.data?.data?.transaction_data
          await groupData(dataTranSaction)
        }
      })
  }

  const getListAllAdmin = async () => {
    const res = await getData({
      schema: `${adminauth2}/all/admin`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        setListAdmin(res.data)
        setLoadingAddOfficer(false)
      } else {
        console.log('Err', res)
      }
    }
  }

  const LastIndex = data => {
    return (
      <div>
        <TextStatus
          bg={data?.length ? checkColorStatusBg(data[data?.length - 1]?.name) : ''}
          text={data?.length ? checkColorStatusText(data[data?.length - 1]?.name) : ''}
        >
          {data?.length ? (data[data?.length - 1]?.name ? checkStatusText(data[data?.length - 1]?.name) : '-') : '-'}
        </TextStatus>
      </div>
    )
  }
  const menu = value => {
    const filteredOrganize = value?.department_data
      ? Array.isArray(value?.department_data)
        ? value?.department_data?.filter(item => item?.id === organizeId)
        : value?.department_data
      : ''

    return (
      <Menucustom onClick={e => handleMenuList(e, value)}>
        {/* {value?.status_data[value?.status_data?.length - 1]?.name !== 'done' ? (
          deparmentData?.officer_data?.officer_id ? (
            <Menucustom.Item onClick={e => showDrawerEditOfficer(e, value, 'editOfficer')}>
              <Edit style={{ marginRight: '5px' }} />
              แก้ไขผู้รับผิดชอบ
            </Menucustom.Item>
          ) : (
            <Menucustom.Item onClick={e => showDrawerAddOfficer(e, value, 'addOfficer')}>
              <Officer style={{ marginRight: '5px' }} />
              เพิ่มผู้รับผิดชอบ
            </Menucustom.Item>
          )
        ) : (
          ''
        )} */}
        {location?.state?.group && meData?.position_data?.id === '1730459515154853888' && (
          <Menucustom.Item
            onClick={e => {
              if (value?.is_group) {
                handleOutOfGroup(e, value)
              } else if (dataLength?.length === 2) {
                handleAllOutOfGroup(e, location?.state?.record)
              } else {
                handleOutOfGroup(e, value)
              }
            }}
          >
            <OutOfGroup style={{ marginRight: '5px' }} />
            นำออกจากกลุ่ม
          </Menucustom.Item>
        )}
        {/* {deparmentData?.officer_data?.id === meData?.id &&
          value?.status_data[value?.status_data?.length - 1]?.name !== 'done' && (
            <Menucustom.Item key={'view-update-status'}>
              <Update style={{ marginRight: '5px' }} />
              อัปเดตข้อมูล
            </Menucustom.Item>
          )} */}
        {/* {value?.status_data[value?.status_data?.length - 1]?.name === 'received' && !value?.is_group && (
          <Menucustom.Item onClick={e => showDrawerTransfer(e, value)}>
            <Transfer style={{ marginRight: '5px' }} />
            โอนย้าย
          </Menucustom.Item>
        )} */}
        <Menucustom.Item key={'export'}>
          <Export style={{ marginRight: '5px' }} />
          ออกใบงาน
        </Menucustom.Item>
      </Menucustom>
    )
  }

  const showDrawerTransfer = (e, value) => {
    setDeparmentData(value?.department_data)
    setComplaintId(value?.id)
    setOpenDrawerTransfer(true)
  }
  const columnsDataTable = [
    {
      title: '',
      render: (_, data) => {
        return (
          <DropdownCustom overlay={() => menu(data)} trigger={['click']}>
            <MoreOutlined style={{ cursor: 'pointer', fontSize: '26px' }} />
          </DropdownCustom>
        )
      },
    },
    {
      title: 'ลำดับ',
      dataIndex: 'no',
      width: '70px',
      render: text => <div style={{ textAlign: 'center' }}>{text}.</div>,
    },
    {
      title: 'เรื่องที่ร้องเรียน',
      dataIndex: 'complaint_name',
      render: (text, data) => (
        <div style={{ display: 'grid', gridTemplateColumns: '10px 1fr', gap: '2px' }}>
          <div
            style={{
              width: '5px',
              height: '100%',
              borderRadius: '5px',
              backgroundColor: data?.is_group ? '#F36B24' : 'transparent',
            }}
          />
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100px' }}>
            {text}
          </div>
        </div>
      ),
    },
    {
      title: 'เลขที่เรื่องร้องเรียน',
      dataIndex: 'complaint_no',
      render: (text, data) =>
        text ? <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{text}</div> : '-',
    },
    {
      title: 'ประเภทเรื่องร้องเรียน',
      dataIndex: 'category',
      render: (text, data) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <img src={data?.category?.icon_url} style={{ width: '14px', height: '14px' }} />
          {data?.category?.name}
        </div>
      ),
    },
    {
      title: 'ประเภทย่อย',
      dataIndex: 'sub_category_name',
      render: (text, data) => <div>{data?.sub_category_data?.name ? data?.sub_category_data?.name : '-'}</div>,
    },
    {
      title: 'หน่วยงานที่รับผิดชอบ',
      dataIndex: 'department_data',
      render: (text, data) => (
        <div>
          {text?.map((value, index, array) => {
            return (
              <div key={index} style={{ color: '#556A82' }}>
                {value?.name}
              </div>
            )
          })}
        </div>
      ),
    },
    {
      title: <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>สถานะความเร่งด่วน</div>,
      render: (text, data) =>
        data?.urgency?.name ? (
          <span
            style={{
              backgroundColor: data?.urgency?.name === 'ทำทันที' ? '#F85858' : '#F3F3F3',
              borderRadius: '10px',
              color:
                data?.urgency?.name === 'ปกติ'
                  ? '#13AFA6'
                  : data?.urgency?.name === 'ด่วน'
                  ? '#FFA643'
                  : data?.urgency?.name === 'ด่วนมาก'
                  ? '#EC82F8'
                  : data?.urgency?.name === 'ด่วนที่สุด'
                  ? '#F36B24'
                  : '#FFFFFF',
              padding: '10px 20px',
              height: '40px',
            }}
          >
            {data?.urgency?.name}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'ความพึงพอใจ',
      render: (text, data) => <div>{checkRating(data?.rating) ? checkRating(data?.rating) : '-'}</div>,
    },
    {
      title: <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>วันที่แจ้งเรื่อง</div>,
      render: (text, data) => <div>{conversDate(data?.created_at)}</div>,
    },
    {
      title: 'วันที่กำหนดเสร็จ',
      render: (text, data) => <div>{data?.duedate_at ? checkDueDate(data?.duedate_at) : '-'}</div>,
    },
    {
      title: 'สถานะการร้องเรียน',
      render: (text, data) =>
        data?.status_data?.length ? (
          <span
            style={{
              width: 'auto',
              height: '24px',
              background: checkColorStatusBg(data?.status_data[data?.status_data?.length - 1]?.name),
              borderRadius: '4px',
              textAlign: 'center',
              padding: '6px',
              color: checkColorStatusText(data?.status_data[data?.status_data?.length - 1]?.name),
            }}
          >
            {data?.status_data[data?.status_data?.length - 1]?.name
              ? checkStatusText(data?.status_data[data?.status_data?.length - 1]?.name)
              : '-'}
          </span>
        ) : (
          '-'
        ),
    },
  ]
  const Mapmedia = dataMedia => {
    const CardUi =
      dataMedia &&
      dataMedia.map((item, index) => {
        if (item.includes('mp4')) {
          return (
            <>
              <div
                className="show-image-div click"
                onClick={() => {
                  setPreviewVdo(item)
                  setOpenModalVdo(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <video style={{ width: '144px', height: '82px', borderRadius: '8px', margin: '1em' }}>
                    <source src={item} type="video/mp4" />
                  </video>
                  <div style={{ position: 'absolute' }}>
                    <IvdoPlay />
                  </div>
                </div>
              </div>
            </>
          )
        } else {
          return (
            <img
              style={{ borderRadius: '8px', width: '144px', height: '82px', margin: '10px 5px' }}
              className="show-image click"
              src={item}
              alt="image"
              onClick={() => {
                setOpenModalImage(true)
                setPreviewImg(item)
              }}
            />
          )
        }
      })
    return CardUi
  }

  const onRowClick = (record, rowIndex) => {
    setRecordID(record?.id)
    getDataByRowID(record?.id)
  }

  const getRowClassName = record => {
    return record?.id === recordID ? 'color-row' : '' // Add your condition here
  }

  const onCloseAddOfficer = () => {
    setOpenDrawerAddOfficer(false)
    setDeparmentData([])
    setLoadingAddOfficer(true)
  }

  const onCloseTransfer = () => {
    setOpenDrawerTransfer(false)
    setDeparmentData([])
  }

  const handleOutOfGroup = (e, val) => {
    if (val?.is_group) {
      setModalOutOfGroup({
        modal: true,
        isOut: false,
      })
    } else {
      setModalOutOfGroup({
        modal: true,
        isOut: true,
        id: val?.id,
      })
    }
  }

  const openNotification = typeNoti => {
    notification.info({
      message: (
        <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>
          {typeNoti === 'transfer'
            ? 'โอนย้ายเรื่องร้องเรียนสำเร็จ'
            : typeNoti === 'editofficer'
            ? 'แก้ไขข้อมูลเจ้าหน้าที่แล้ว'
            : 'เพิ่มข้อมูลเจ้าหน้าที่แล้ว'}
        </div>
      ),
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 3,
    })
  }
  const showDrawerAddOfficer = (e, value, typeDrawer) => {
    setDeparmentData(value?.department_data)
    setComplaintId(value?.id)
    setOpenDrawerAddOfficer(true)
    setTimeout(() => {
      setLoadingAddOfficer(false)
    }, 1000)
  }

  const showDrawerEditOfficer = (e, value, typeDrawer) => {
    setDeparmentData(value?.department_data)
    setComplaintId(value?.id)
    setOpenDrawerEditOfficer(true)
    setTimeout(() => {
      setLoadingAddOfficer(false)
    }, 1000)
  }
  const onCloseEditOfficer = () => {
    setOpenDrawerEditOfficer(false)
    setDeparmentData([])
    setLoadingAddOfficer(true)
  }

  const items = [
    {
      label: (
        <div
          style={{ display: 'flex', width: '100%', alignItems: 'center' }}
          onClick={e => handleAllOutOfGroup(e, dataComplaint)}
        >
          <Ungroup style={{ marginRight: '5px' }} />
          แตกกลุ่ม
        </div>
      ),
      key: '0',
    },
  ]

  const handleAllOutOfGroup = (e, val) => {
    setModalHeadGroup({
      modal: true,
      id: val?.id ? val?.id : val,
    })
  }

  const handleConfirmOutOfGroup = () => {
    const compalintId = location?.state?.record
    let data = idSubGroup
    data = data.filter(item => item !== compalintId)
    data = data.filter(item => item !== modalOutOfGroup?.id)
    data.unshift(compalintId)
    const body = {
      complaint_id: data,
    }
    axios
      .put(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/group/${compalintId}`, body, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      .then(res => {
        setModalOutOfGroup({
          modal: false,
        })
        getDataId()
        openNotificationOutGroup('นำเรื่องร้องเรียนออกจากกลุ่มนี้แล้ว')
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const openNotificationOutGroup = text => {
    notification.info({
      message: <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>{text}</div>,
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 5,
    })
  }

  const handleConfirmDeleteGroup = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    }
    axios
      .delete(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/group/${location?.state?.record}`, config)
      .then(res => {
        localStorage.setItem('isDeleteSingle', true)
        navigate('/complaint-staff/bof-overview-complaint')
      })
      .catch(err => {
        console.log('err', err)
      })
  }
  const openNotificationDelete = typeNoti => {
    notification.info({
      message: (
        <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>ลบข้อมูลเรื่องร้องเรียนสำเร็จ</div>
      ),
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 5,
    })
  }
  const checkbtnUpdate = () => {
    // ;(dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name !== 'done' &&
    //   dataComplaint?.department_data?.filter((value, index, array) => {
    //     return value?.id === organizeId
    //   })[0]?.officer_data?.id === meData?.id &&
    //   dataComplaint?.is_group) ||
    //   (dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name !== 'done' &&
    //     dataComplaint?.department_data?.filter((value, index, array) => {
    //       return value?.id === organizeId
    //     })[0]?.officer_data?.id &&
    //     meData?.position_data?.id !== '1730459515121299456' &&
    //     location?.state?.group &&
    //     dataComplaint?.is_group) ||
    //   (!location?.state?.group && !dataComplaint?.is_group)
    if (
      dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name !== 'done' &&
      dataComplaint?.department_data?.filter((value, index, array) => {
        return value?.id === organizeId
      })[0]?.officer_data?.id &&
      meData?.position_data?.id === '1730459515154853888'
    ) {
      return true
    } else if (
      dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name !== 'done' &&
      dataComplaint?.department_data?.filter((value, index, array) => {
        return value?.id === organizeId
      })[0]?.officer_data?.id === meData?.id &&
      meData?.position_data?.id === '1730459515159048192'
    ) {
      return true
    } else {
      return false
    }
  }
  return (
    <>
      {contextHolder}
      {loading ? (
        <BoxTopView
          style={{
            height: '85vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            borderRadius: '10px',
            margin: '1em 2em',
          }}
        >
          <Spin />
        </BoxTopView>
      ) : (
        <Content>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space
              style={{
                paddingTop: '20px',
                display: 'grid',
                gridTemplateRows: '1fr 1fr',
                width: '100%',
              }}
            >
              <Space style={{ paddingLeft: '30px' }}>
                <ButtonBack
                  shape="circle"
                  icon={<IconArrowLeft />}
                  onClick={() => navigate('/complaint-staff/bof-overview-complaint')}
                />
                <Title>ข้อมูลเรื่องร้องเรียน </Title>
              </Space>
              <BoxBtn>
                {(location?.state?.group && dataComplaint?.is_group) ||
                (!location?.state?.group && !dataComplaint?.is_group) ? (
                  dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name !== 'done' ? (
                    dataComplaint?.department_data?.filter((value, index, array) => {
                      return value?.id === organizeId
                    })[0]?.officer_data?.id ? (
                      <Button
                        className="btn-edti-staff"
                        icon={<AddStraff style={{ marginRight: '5px' }} />}
                        onClick={e => showDrawerEditOfficer(e, dataComplaint, 'editOfficer')}
                      >
                        โอนย้าย/แก้ไขผู้รับผิดชอบ
                      </Button>
                    ) : (
                      <Button
                        className="btn-edti-staff"
                        icon={<IconViewAdd style={{ marginRight: '5px' }} />}
                        onClick={e => showDrawerAddOfficer(e, dataComplaint, 'addOfficer')}
                      >
                        เพิ่มผู้รับผิดชอบ
                      </Button>
                    )
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {checkbtnUpdate() ? (
                  <Button
                    className="btn-update-complant"
                    onClick={() => {
                      navigate('/complaint-staff/bof-overview-complaint/view-update-status', {
                        state: {
                          record: dataComplaint?.id,
                          path: 'previous-view',
                          group: dataComplaint?.is_group,
                        },
                      })
                    }}
                    icon={<RepairIcon style={{ marginRight: '5px' }} />}
                  >
                    อัปเดตข้อมูลการดำเนินงาน
                  </Button>
                ) : (
                  ''
                )}
                {meData?.position_data?.id === '1730459515154853888' && location?.state?.group && (
                  <Dropdown menu={{ items }} trigger={['click']} className="dropdown-complaint-admin-view">
                    <div
                      style={{
                        border: '1px solid #f36b24',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        height: '30px',
                        width: '30px',
                        transform: 'rotate(90deg)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                      }}
                    >
                      <EllipsisOutlined style={{ fontSize: '24px', color: '#f36b24' }} />
                    </div>
                  </Dropdown>
                )}
              </BoxBtn>
            </Space>
            <Space></Space>
          </div>
          <div style={{ margin: '1em 2em' }}>
            <BoxTopView>
              {location?.state?.group && (
                <ContainerView>
                  <TableCustom
                    columns={columnsDataTable}
                    dataSource={dataTable}
                    onRow={onRowClick}
                    getRowClassName={getRowClassName}
                    scroll={{
                      x: 2000,
                    }}
                  />
                  <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <PaginationCustom
                      page={curantPage}
                      setPage={setCurantPage}
                      dataLength={dataLength.length}
                      pageSize={5}
                    />
                  </div>
                </ContainerView>
              )}
              <ContainerView>
                <HeaderboxView>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                    <Tooltip placement="top" title={dataComplaint?.complaint_name}>
                      <TextTile>{dataComplaint?.complaint_name}</TextTile>
                    </Tooltip>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                      {LastIndex(dataComplaint?.status_data)}
                    </div>
                  </div>
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: '500px',
                    }}
                  >
                    {dataComplaint?.description && (
                      <Tooltip placement="top" title={dataComplaint?.description}>
                        {dataComplaint?.description ? dataComplaint?.description : ''}
                      </Tooltip>
                    )}
                  </div>
                  <div>
                    <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                      <span>แจ้งเมื่อ : {unixToDateThai(dataComplaint?.created_at)}</span>
                      <hr style={{ border: '1px solid #FFC4AA', width: '10px', transform: 'rotate(90deg)' }} />
                      <span>เลขที่ : {dataComplaint?.complaint_no}</span>
                      <hr style={{ border: '1px solid #FFC4AA', width: '10px', transform: 'rotate(90deg)' }} />
                      <span>
                        ร้องเรียน : {chanelData[dataComplaint?.channel] ? chanelData[dataComplaint?.channel] : '-'}
                      </span>
                    </div>
                  </div>
                </HeaderboxView>
                <div>
                  <div>
                    <Boxbg Bgcolor="#FBFBFB">
                      <BoxDescription>
                        <div className="text-title">ประเภทเรื่องร้องเรียน</div>
                        <div className="text-title">ประเภทย่อย</div>
                      </BoxDescription>
                      <BoxDescription>
                        {dataComplaint?.category?.name ? (
                          <div>
                            {dataComplaint?.category?.icon_url && (
                              <img
                                src={`${dataComplaint?.category?.icon_url}`}
                                style={{ width: '20px', height: '20px' }}
                                alt="cate"
                              />
                            )}
                            <span style={{ margin: '0 3px' }}>{dataComplaint?.category?.name}</span>
                          </div>
                        ) : (
                          '-'
                        )}
                        <div className="to-right">{dataComplaint?.sub_category_data?.name}</div>
                      </BoxDescription>
                      <BoxDescription>
                        <div>
                          <div className="text-title">สถานะความเร่งด่วน</div>
                          <div
                            style={{
                              backgroundColor: dataComplaint?.urgency?.name === 'ทำทันที' ? '#F85858' : '#F3F3F3',
                              borderRadius: '10px',
                              color:
                                dataComplaint?.urgency?.name === 'ปกติ'
                                  ? '#13AFA6'
                                  : dataComplaint?.urgency?.name === 'ด่วน'
                                  ? '#FFA643'
                                  : dataComplaint?.urgency?.name === 'ด่วนมาก'
                                  ? '#EC82F8'
                                  : dataComplaint?.urgency?.name === 'ด่วนที่สุด'
                                  ? '#F36B24'
                                  : '#FFFFFF',
                              padding: '8px 10px',
                              height: '30px',
                              textAlign: 'center',
                              width: '80px',
                              fontSize: '10px',
                              marginTop: '10px',
                            }}
                          >
                            {dataComplaint?.urgency?.name}
                          </div>
                        </div>
                        {dataComplaint?.department_data?.length === 1 && (
                          <div>
                            <div className="text-title">ผู้รับผิดชอบ</div>
                            <div className="text-sub-title">
                              {dataComplaint?.department_data[0]?.officer_data
                                ? `${dataComplaint?.department_data[0]?.officer_data?.first_name} ${dataComplaint?.department_data[0]?.officer_data?.last_name}`
                                : '-'}
                            </div>
                          </div>
                        )}
                      </BoxDescription>

                      <BoxOrganize>
                        <div className="text-title">หน่วยงานที่รับผิดชอบ</div>
                      </BoxOrganize>
                      <BoxOrganize>
                        {dataComplaint?.department_data ? (
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {dataComplaint &&
                              dataComplaint?.department_data?.map((item, index) => {
                                return (
                                  <BoxIcon key={index}>
                                    <div>{dePartMentIcon[item?.name]}</div>
                                    <div style={{ margin: '0 4px' }}> {item?.name}</div>
                                  </BoxIcon>
                                )
                              })}
                          </div>
                        ) : (
                          '-'
                        )}
                      </BoxOrganize>

                      <BoxDescription>
                        <div>
                          <div className="text-title">วันที่กำหนดเสร็จ</div>
                          <div>
                            <div className="text-sub-title">
                              {dataComplaint?.duedate_at ? checkDueDate(dataComplaint?.duedate_at) : '-'}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="text-title">วันที่แล้วเสร็จ</div>
                          <div>
                            <div className="text-sub-title">
                              {dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name === 'done'
                                ? checkDueDate(
                                    dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.created_at
                                  )
                                : '-'}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="text-title">ผู้ร้องเรียน</div>
                          <div className="text-sub-title">
                            {dataComplaint?.name
                              ? `${dataComplaint?.name?.first_name} ${dataComplaint?.name?.last_name}`
                              : '-'}
                          </div>
                        </div>
                        <div>
                          <div className="text-title">เบอร์โทรศัพท์</div>
                          <div>
                            <div className="text-sub-title">
                              {dataComplaint?.mobile_number ? dataComplaint?.mobile_number : '-'}
                            </div>
                          </div>
                        </div>
                      </BoxDescription>
                      <BoxDescription>
                        {dataComplaint?.department_data?.map(val => {
                          return (
                            val?.id === meData?.organize_data?.org_id && (
                              <div>
                                <div className="text-title">เหตุผลการปฏิเสธการโอนย้าย</div>
                                <div>
                                  <div className="text-sub-title">
                                    {val?.transfer_reason ? val?.transfer_reason : '-'}
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        })}
                      </BoxDescription>
                    </Boxbg>
                  </div>
                  <div>
                    <div>
                      <div className="text-title">รูปภาพและวิดีโอ</div>
                      <div
                        style={{
                          display: 'flex',
                          overflow: 'auto',
                          maxWidth: '768px',
                        }}
                      >
                        {Mapmedia(dataComplaint?.media_url)}
                      </div>
                    </div>
                    <div>
                      <div className="text-title" style={{ marginBottom: '10px', marginTop: '10px' }}>
                        สถานที่แจ้งเรื่อง
                      </div>
                      <div>
                        <Viewmap
                          latitude={dataComplaint?.coordinates?.latitude}
                          longitude={dataComplaint?.coordinates?.longitude}
                          dataComplaint={dataComplaint}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ContainerView>
            </BoxTopView>

            <CardResultBox
              dataTranSaction={dataTranSaction}
              setPreviewImg={setPreviewImg}
              setPreviewVdo={setPreviewVdo}
              setOpenModalImage={setOpenModalImage}
              setOpenModalVdo={setOpenModalVdo}
              complaintId={dataComplaint?.id}
            />
            <CardTracking
              dataComplaint={dataComplaint}
              complaintId={dataComplaint?.id}
              setPreviewImg={setPreviewImg}
              setPreviewVdo={setPreviewVdo}
              setOpenModalImage={setOpenModalImage}
              setOpenModalVdo={setOpenModalVdo}
            />
            <DeleteComplant
              isDeleteCompalnt={isDeleteCompalnt}
              setIsDeleteCompalnt={setIsDeleteCompalnt}
              openNotificationDelete={openNotificationDelete}
              getDataId={getDataId}
              path="view"
              deleteGroup={isDeleteCompalnt?.isGroup}
              checkGroup={location?.state?.group}
              handleConfirmDeleteGroup={handleConfirmDeleteGroup}
            />
            <div>
              <ModalPreviewImage
                title=""
                centered
                transitionName=""
                closable={false}
                open={OpenModalImage}
                footer={false}
                onOk={() => {
                  setPreviewImg(null)
                  setOpenModalImage(false)
                }}
                onCancel={() => {
                  setPreviewImg(null)
                  setOpenModalImage(false)
                }}
              >
                <ModelContent>
                  <div
                    className="close-ui"
                    onClick={() => {
                      setOpenModalImage(false)
                      setPreviewImg(null)
                    }}
                  >
                    <img src={Iconpreview} alt="dd" />
                  </div>
                  {previewImg ? (
                    <div className="vdo-content">
                      <img src={previewImg} style={{ width: '100%', height: 'auto' }} />
                    </div>
                  ) : null}
                </ModelContent>
              </ModalPreviewImage>
            </div>
            <div style={{ display: 'block', position: 'fixed', bottom: '100%' }}>
              <ExportComplante componentRef={componentRef} data={dataExport} />
            </div>
            {/* Drawer add officer */}
            <AddOfficer
              openDrawerAddOfficer={openDrawerAddOfficer}
              setLeaveModalOpen={setLeaveModalOpen}
              loadingAddOfficer={loadingAddOfficer}
              deparmentData={deparmentData}
              organizeId={organizeId}
              complaintId={complaintId}
              listAdmin={listAdmin}
              onCloseAddOfficer={onCloseAddOfficer}
              openNotification={openNotification}
              getDataId={getDataId}
            />
            {/* Drawer edit officer */}
            <div style={{ width: '100px' }}>
              <EditOfficer
                openDrawerEditOfficer={openDrawerEditOfficer}
                setLeaveModalOpen={setLeaveModalOpen}
                loadingAddOfficer={loadingAddOfficer}
                deparmentData={deparmentData}
                organizeId={organizeId}
                complaintId={complaintId}
                listAdmin={listAdmin}
                onCloseEditOfficer={onCloseEditOfficer}
                openNotification={openNotification}
                getDataId={getDataId}
              />
            </div>

            {/* DrawerTransfer */}
            <TransferComplaint
              openDrawerTransfer={openDrawerTransfer}
              onCloseTransfer={onCloseTransfer}
              meData={meData}
              complaintId={complaintId}
              openNotification={openNotification}
              getDataId={getDataId}
            />

            <CustomModal
              transitionName=""
              centered
              open={modalOutOfGroup?.modal}
              onOk={() => {
                if (modalOutOfGroup?.isOut) {
                  handleConfirmOutOfGroup()
                } else {
                  setModalOutOfGroup({
                    modal: false,
                  })
                }
              }}
              onCancel={() =>
                setModalOutOfGroup({
                  modal: false,
                })
              }
              closable={false}
              okText="ตกลง"
              cancelText={'ยกเลิก'}
              zIndex={1200}
            >
              <div style={{ paddingBottom: '10px' }}>
                {modalOutOfGroup?.isOut ? <ExclamationCircle /> : <Warning />}
              </div>
              <p className="modal-title">
                {modalOutOfGroup?.isOut
                  ? 'นำเรื่องร้องเรียนนี้ออกจากกลุ่ม?'
                  : 'ไม่สามารถนำเรื่องร้องเรียนนี้ออกจากกลุ่มได้'}
              </p>
              <p className="modal-message">
                {modalOutOfGroup?.isOut
                  ? ' หากนำออก จะสามารถจัดการเรื่องร้องเรียนนี้ได้ที่หน้าแรก'
                  : 'เนื่องจากเรื่องร้องเรียนนี้เป็นเรื่องร้องเรียนหลักของกลุ่มกรุณาเปลี่ยนเรื่องหลักของกลุ่ม เป็นเรื่องร้องเรียนอื่นเพื่อดำเนินการต่อ'}
              </p>
            </CustomModal>
            <CustomModal
              transitionName=""
              centered
              open={modalHeadGroup?.modal}
              zIndex={1200}
              onOk={() => {
                handleConfirmDeleteGroup()
              }}
              onCancel={() =>
                setModalHeadGroup({
                  modal: false,
                })
              }
              closable={false}
              okText="ตกลง"
              cancelText="ยกเลิก"
            >
              <div style={{ paddingBottom: '10px' }}>
                <ExclamationCircle />
              </div>
              <p className="modal-title">ต้องการแตกกลุ่มข้อมูล ?</p>
              <p className="modal-message">คุณสามารถรวมกลุ่มได้อีกครั้งโดยการกดเครื่องมือ “จัดกลุ่ม”</p>
            </CustomModal>
            <CustomModal
              transitionName=""
              centered
              open={leaveModalOpen}
              onOk={() => {
                setLeaveModalOpen(false)
                onCloseAddOfficer()
                onCloseEditOfficer()
              }}
              onCancel={() => setLeaveModalOpen(false)}
              closable={false}
              okText="ตกลง"
              cancelText="ยกเลิก"
              zIndex={1200}
            >
              <div style={{ paddingBottom: '10px' }}>
                <ExclamationCircle />
              </div>
              <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
              <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
            </CustomModal>
            <div>
              <ModalPreviewImage
                title=""
                centered
                transitionName=""
                closable={false}
                open={OpenModalVdo}
                footer={false}
                onOk={() => {
                  setOpenModalVdo(false)
                }}
                onCancel={() => {
                  setOpenModalVdo(false)
                }}
              >
                <ModelContent>
                  <div
                    className="close-ui"
                    onClick={() => {
                      setOpenModalVdo(false)
                    }}
                  >
                    <img src={IconCloseModal} alt="dd" style={{ width: '80px', height: '70px' }} />
                  </div>
                  {previewVdo ? (
                    <div className="vdo-content">
                      <video width="370" height="250" controls>
                        <source src={previewVdo} type="video/mp4" />
                      </video>{' '}
                    </div>
                  ) : null}
                </ModelContent>
              </ModalPreviewImage>
            </div>
          </div>
        </Content>
      )}
    </>
  )
}

export default ViewCompliantMobile
const Content = styled.div`
  div {
    font-size: 10px;
  }
`
const TextStatus = styled.div`
  width: auto;
  height: 24px;
  background: ${props => (props?.bg ? props?.bg : '')};
  border-radius: 4px;
  text-align: center;
  padding: 3px;
  color: ${props => (props?.text ? props?.text : '')};
`

const BoxIcon = styled.div`
  background-color: #fff;
  padding: 5px;
  margin: 0 3px;
  display: flex;
`

export const ModalPreviewImage = styled(Modal)`
  font-family: 'IBM Plex Sans Thai', sans-serif;

  .close-ui {
    position: fixed;
    top: 35px;
    right: 30px;
    padding: 0px;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    cursor: pointer;
    line-height: 35px;
    font-size: 14px;
    font-weight: 800;
    color: #3f54d1;
    z-index: 100;
    transition: none;
    img {
      width: 70px;
      height: 70px;
    }
  }
  .ant-modal-content {
    border-radius: 16px;
    width: auto;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
    .ant-btn-default {
      border-radius: 12px;
      border-color: #00ada4 !important;
      color: #00ada4 !important;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #00ada4 !important;
      border-color: #00ada4;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

const ModelContent = styled.div`
  .vdo-content {
    position: relative;
    padding: 0;
  }
  .model-ui {
    font-style: normal;
    .header-ui {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 47px;
      color: #19263f;
      text-align: left;
    }
    .model-crop {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
    .controls {
      background: #f8e2e2;
      border-radius: 35px;
      width: 200px;
      padding: 0.2em 12px;
      margin: auto;
      margin-top: 10px;
      display: flex;
      .slider {
        width: 160px;
        padding-left: 4px;
        padding-right: 4px;
      }
      .button-zoom {
        line-height: 34px;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        color: #1f3983;
        width: 20px;
        height: 20px;
        user-select: none;
      }
    }
  }
`

const BoxTopView = styled.div`
  margin-bottom: 20px;
  .color-row {
    background-color: #ffeee7;
  }
  .text-title {
    color: #c4c4c4;
    font-size: 10px;
  }
  .text-sub-title {
    font-size: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .to-right {
    font-size: 10px;
  }
  .ant-table-row:hover {
    .ant-table-cell-fix-left {
      background: #ffeee7 !important;
    }
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: #fafafa !important;
  }
  .ant-table-cell-fix-left {
    background: transparent !important;
  }
`

const BoxDescription = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1em;
`
export const BoxOrganize = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 1em;
`

const Boxbg = styled.div`
  background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#ffff')};
  border-radius: ${props => (props.BdRadius ? `${props.BdRadius}` : '16px')};
  padding: ${props => (props.Padding ? `${props.Padding}` : '1em')};
  width: ${props => (props.Bwidth ? `${props.Bwidth}` : '100%')};
  height: 100%;
  .box-media-view {
    display: flex;
    flex-direction: row;
  }
`

const TextTile = styled.p`
  color: #f36b24;
  display: -webkit-box;
  max-width: 700px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Prompt;
  font-size: 16px;
  margin: 0;
`

const ContainerView = styled.div`
  background-color: #ffff;
  border-radius: 24px;
  padding: 12px;
  margin-bottom: 20px;
  .color-row {
    background-color: #ffeee7;
  }
`
const HeaderboxView = styled.div`
  border-radius: 15px;
  border: 1px solid var(--web-backend-extended-orange-400, #ffeee7);
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;

  span {
    font-size: 8px;
  }
`
