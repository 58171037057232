import styled from 'styled-components'
import { Button, Menu, Drawer, Divider, Input } from 'antd'

export const MainMenuStyle = styled.div`
  ${({ index, checkclick }) => `
    &.main-menu${index - 1} {
      background-color :${checkclick == true || checkclick == undefined ? '#FFEEE7' : 'none'};
      border-radius: 12px;    
    }
  

  `}
`

export const Content = styled.div`
  height: 100vh;

  /* @media only screen and (max-width: 500px) {
    display: none;
  } */

  .sider-menu {
    .submenu {
      display: ${props => (props.collapAction ? 'none' : 'flex')};
      padding: 8px;
      span {
        font-family: 'Prompt';
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 14px !important;
      }
      .sub-menu:hover {
        background: white !important;

        span {
          color: #f36b24 !important;
        }
      }

      /* .text-menu:hover{
        background: white !important;
        padding: -8px !important;
      
        span{
          color:#F36B24 !important;
        }
      } */
    }

    .touch-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      top: 10px;
      gap: 8px;
      height: 10%;
      /* cursor: pointer; */
    }

    .smart-logo {
      width: 160px;
      position: absolute;
      top: 30px;
      left: 10px;
    }
    .to-s-logo {
      width: 70px;
      left: 30px;
    }

    /* .to-t-logo {
      width: 90px;
      left: 20px;
    } */
    .arrow-sub-menu {
      display: ${props => (props.collapAction ? 'none' : 'flex')};
    }
    z-index: 10;
    position: relative;
    /* padding: 33px; */
    padding-top: 110px;
    height: 100%;
    transition: 0.2s;
    font-weight: 400;
    width: ${props => (props.collapAction ? '120px' : '216px')};
    background: linear-gradient(184.87deg, #f9f9f9 -4.29%, #f9f9f9 14.11%, #ffebe2 65.63%, #ffe2d6 98.47%);
    color: #3f54d1;
    text-align: center;
  }

  .sub-sider-menu {
    transition: 0.2s;
    width: ${props => (props.subSiderMenu ? '0px' : '200px')};
    transition: 0.5s;
  }

  .text-menu {
    font-family: 'Prompt';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    transition: color 0.2s;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;

    /* margin: 15px 0 0 0; */
    .icon {
      opacity: 0.6;
      padding-right: ${props => (props.collapAction ? '0px' : '10px')};
    }
    /* :hover{
      background-color:#F36B24;
    } */
    /* :focus, */
    &.selected {
      /* background-color:none; */
      color: #ffffff !important;

      .icon {
        opacity: 1;
      }

      &.border-menu {
        /* border-right: 4px solid var(--white) !important; */
        /* background-color: #fc6521 !important; */
      }
    }

    justify-content: ${props => (props.collapAction ? 'center' : '')};
  }

  .arrow {
    right: -16px;
    position: absolute;
    bottom: 45px;
    transition: 0.2s;
    transform: ${props => (props.collapAction ? 'rotate(0deg)' : 'rotate(-180deg)')};
  }

  .menu-scrollbar {
    padding-bottom: 110px;
    height: 93%;
    width: 100%;
    overflow-y: overlay;
    margin: 0 auto;
    padding-left: 8px;
    padding-right: 8px;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
    }
  }
`
export const BtCollap = styled(Button)`
  border: none;
  background: white;
  color: #150f0b;
  :focus,
  :hover {
    background-color: white;
    color: #150f0b;
  }
`
export const TextMenu = styled.span`
  display: ${props => (props.collapAction ? 'none' : 'block')};
  line-height: 24px;
  font-weight: ${props => (props.selected ? '400' : '400')};
  font-size: 16px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  width: ${props => (props.width ? props.width : '145px')};
  color: ${props => (props.selected ? '#150F0B' : '#150F0B')};

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
  }

  @media only screen and (min-width: 321px) and (max-width: 767px) {
    font-size: 14px;
  }
`

export const BoxVersion = styled.span`
  position: absolute;
  bottom: 15px;
  left: 0px;
  right: 0px;
  text-align: center;
  color: #000000;
  font-size: small;
  user-select: none;
`

export const BoxName = styled.div`
  position: absolute;
  bottom: 0;
  left: -18px;
  right: 0px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #150f0b;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Prompt';
  background: transparent;
  gap: 8px;
  width: 200px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 35px;
  border-radius: 8px;

  &:hover {
    background-color: #ffeee7;
    color: #fc6521;
    cursor: pointer;
  }

  p {
    width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    margin-bottom: 0;
  }
`

export const CustomMenu = styled(Menu)`
  position: absolute;
  bottom: 48px;
  left: 97px;
  width: 184px;
  height: 96px;
  background: white;
  color: #001f34;
  box-shadow: 0px 4px 16px rgba(46, 60, 79, 0.1) !important;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-menu-item {
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin: 0 !important;
    font-weight: 400;
    font-size: 14px;

    &:hover,
    :active,
    :focus {
      background: white;
      color: #001f34;
    }
  }

  .ant-menu-item:last-child {
    color: #ed4160;
  }

  .ant-menu-item-selected {
    background-color: white !important;
    color: #001f34 !important;
  }
`

export const CustomDrawer = styled(Drawer)`
  font-family: 'Prompt' !important;
  .ant-drawer-wrapper-body {
    /* padding: 0 40px; */
  }

  .ant-drawer-content {
    box-shadow: 0px 4px 30px rgba(46, 60, 79, 0.04);
  }

  .ant-drawer-mask {
    background: rgba(0, 31, 52, 0.5);
    backdrop-filter: blur(8px);
  }

  .ant-drawer-header {
    border: none;
  }

  .ant-drawer-body {
    padding: 0 !important;
    overflow: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 6.5em; */
    margin-bottom: 1em;

    h1 {
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 24px;
      color: #150f0b;
      text-align: center;
      margin-bottom: 1.5em;
    }
  }

  .info-title {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #150f0b;
    margin-bottom: 5px;
    /* padding-left: 40px; */
  }

  .edit-profile {
    font-family: 'Prompt';
    font-weight: 500;
    font-size: 18px;
    color: #fc6521;
  }

  .top {
    margin-top: 70px;
  }

  .row-data {
    width: 100%;
    display: flex;

    .col-1 {
      width: 49%;
    }

    .col-2 {
      width: 2%;
      display: flex;
      justify-content: center;
      height: 97px;
      color: #ffeee7 !important;
    }
  }

  .text-head {
    margin-bottom: 0;
    /* padding-left: 40px; */
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #c4c4c4;
    padding-top: 14px;
    padding-bottom: 10px;
  }

  .text-data {
    padding-bottom: 10px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #150f0b;
    /* padding-left: 40px; */
    margin-bottom: 0;
  }

  .text-head-right {
    margin-bottom: 0;
    padding-left: 32px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #c4c4c4;
    padding-top: 14px;
    padding-bottom: 10px;
  }

  .text-data-right {
    padding-bottom: 10px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #150f0b;
    padding-left: 32px;
    margin-bottom: 0;
  }

  .text-data-department {
    padding-bottom: 10px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #556a82;
    padding-left: 32px;
    margin-bottom: 0;
  }

  .ant-drawer-header-title {
    justify-content: flex-end;
  }

  .close-icon {
    position: absolute;
    right: 0px;
    cursor: pointer;
  }

  .ant-input::placeholder {
    font-size: 14px !important;
    color: var(--web-backend-extended-orange-700, var(--web-backend-orange-700, #ffc4aa)) !important;
  }
  .ant-input:focus {
    border-color: #f36b24;
    color: #f36b24;
  }

  .ant-input {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    max-width: 824px;
    min-height: 40px;
    color: #150f0b;
    :focus {
      /* box-shadow: 0 0 0 1px rgb(24 144 255 / 20%); */
      color: #fc6521;
      box-shadow: none;
      border: 1px solid #fc6521;
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    height: 48px;
    max-width: 824px;
    color: #fc6521;
    .ant-input {
      border-right: none;
      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    :focus {
      box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
      border: 1px solid #fc6521;
    }
  }

  .ant-input-affix-wrapper:focus .ant-input {
    //box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
    border: 1px solid #fc6521;

    border-right: none;
    border-left: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fc6521;
    //box-shadow: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
    border: 1px solid #ffeee7;
  }
  .ant-input-affix-wrapper-focused {
    border-color: #fc6521;
    box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
  }
  .ant-input[disabled],
  .ant-input-affix-wrapper-disabled {
    background-color: #fbfbfb;
    border-color: #fbfbfb !important;
    color: #9baab1;
  }
`

export const CustomDivider = styled(Divider)`
  margin: 0;
  top: 0;
  height: auto !important;

  &.ant-divider {
    border-top: 1px solid #ffeee7 !important;
  }

  &.ant-divider-vertical {
    border-left: 1px solid #ffeee7 !important;
  }
`

export const BtLogout = styled(Button)`
  width: 32.5em;
  height: 40px;
  border-radius: 8px;
  color: #f85858;
  border-color: #f85858;
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 14px;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    border-color: #f85858;
    background-color: #f85858;
  }
`

export const CustomInputDrawer = styled(Input)`
  /* border-color: #ffeee7 !important; */
  font-family: 'Prompt' !important;
  font-weight: 400;
  font-size: 16px;
  border-color: ${props => (props.disabled ? 'transparent' : '#ffeee7')};
  color: #150f0b !important;
  border-radius: 8px;
  width: 100%;
  height: 40px;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #fc6521 !important;
    box-shadow: none;
    color: #fc6521 !important;
  }

  &.ant-input-affix-wrapper > .ant-input {
    &:focus,
    &:active {
      color: #fc6521 !important;
      border-color: #fc6521 !important;
    }
  }

  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper:focus {
    box-shadow: none !important;
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    border: 1px solid #fc6521;
  }

  .ant-input-clear-icon,
  .anticon.ant-input-clear-icon {
    display: flex;
  }

  &.ant-input-affix-wrapper-disabled {
    border-color: transparent !important;
    background-color: transparent !important;
  }
  .ant-input[disabled] {
    color: #150f0b !important;
  }
`

export const BtSubmit = styled(Button)`
  background-color: #fc6521;
  /* background-color: ${props => (props.disabled ? 'transparent' : '#fc6521')}; */
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border-radius: 8px;
  width: 120px;
  height: 48px;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    background-color: #fc6521;
    border-color: #fc6521;
  }

  &.ant-btn[disabled] {
    background: rgba(252, 101, 33, 1) !important;
    color: #ffffff;
    opacity: 0.2;
  }
`
