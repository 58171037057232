import React from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import { ReactComponent as Warning } from './image/svg/Warning.svg'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function DeleteComplant({
  isDeleteCompalnt,
  setIsDeleteCompalnt,
  getDataComplant,
  getDataId,
  path,
  deleteGroup,
  openNotificationDelete,
  openNotification,
  checkGroup,
  handleConfirmDeleteGroup,
}) {
  const navigate = useNavigate()
  const deleteComplaint = () => {
    axios
      .delete(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint/${isDeleteCompalnt?.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`, // Include the token in the Authorization header
        },
      })
      .then(res => {
        setIsDeleteCompalnt({ Modal: false, id: '' })
        getDataComplant && getDataComplant()
        getDataId && getDataId()
        if (!checkGroup && path === 'view' && !deleteGroup && !isDeleteCompalnt?.idHeader) {
          localStorage.setItem('isDeleteSingle', true)
          navigate('/complaint/bof-overview-complaint')
        } else if (checkGroup && path === 'view' && !deleteGroup && !isDeleteCompalnt?.idHeader) {
          openNotification && openNotification('ลบข้อมูลเรียบร้อยแล้ว')
        } else if (isDeleteCompalnt?.idHeader && path === 'view') {
          handleConfirmDeleteGroup(isDeleteCompalnt?.idHeader)
          openNotification && openNotification('ลบข้อมูลเรียบร้อยแล้ว')
        }
      })
  }

  const deleteAllComplaint = async () => {
    const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
    const compalintId = isDeleteCompalnt?.id
    const token = localStorage?.getItem('access_token')
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    if (!Array.isArray(isDeleteCompalnt)) {
      const resp = await axios.get(`${shemadata}/${compalintId}`, config)
      if (resp?.data?.data?.is_group) {
        resp?.data?.data?.sub_group?.map((value, index, array) => {
          axios.delete(`${shemadata}/${value?.id}`, config).then(res => {})
        })
        setIsDeleteCompalnt && setIsDeleteCompalnt({ Modal: false, id: '' })
        openNotification && openNotification('ลบกลุ่มเรียบร้อยแล้ว')
        getDataComplant && getDataComplant()
      }
    } else {
      await isDeleteCompalnt?.arrId?.map((value, index, array) => {
        axios.delete(`${shemadata}/${value}`, config).then(res => {})
      })
      if (checkGroup && path === 'view' && deleteGroup) {
        localStorage.setItem('isDeleteGroup', true)
        navigate('/complaint/bof-overview-complaint')
      }
    }
  }

  return (
    <div>
      <CustomModal
        transitionName=""
        centered
        open={isDeleteCompalnt?.Modal}
        closable={false}
        onOk={() => (deleteGroup ? deleteAllComplaint() : deleteComplaint())}
        onCancel={() => setIsDeleteCompalnt({ Modal: false, id: '' })}
        cancelText="ยกเลิก"
        okText="ยืนยัน"
      >
        <div>
          <Warning />
        </div>
        <p
          style={{
            color: '#001F34',
            fontSize: '16px',
            marginBottom: '2px',
            fontWeight: '400',
          }}
        >
          {deleteGroup ? 'ลบกลุ่มข้อมูลเรื่องร้องเรียน​ ?' : 'ลบข้อมูลเรื่องร้องเรียน​ ?'}
        </p>
        <p
          style={{
            color: '#597081',
            fontSize: '14px',
            fontWeight: '300',
          }}
        >
          หากคุณยืนยัน ข้อมูลเรื่องร้องเรียนนี้จะถูกลบ โดยสามารถกู้คืนข้อมูลผ่านเมนู Activity Logs ได้ภายใน 30 วัน
        </p>
      </CustomModal>
    </div>
  )
}

const CustomModal = styled(Modal)`
  font-family: 'Prompt';
  .ant-modal-mask {
    background: rgba(21, 15, 11, 0.5) !important;
    backdrop-filter: blur(8px) !important;
  }

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      color: #c4c4c4 !important;
      font-style: normal;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`
