import React, { useEffect, useRef, useState } from 'react'
import ColumnsChart from 'components/Charts/ColumnsChart'
import DonutChart from 'components/Charts/DonutChart'
import LinCharts from 'components/Charts/LineChart'
import FormSelect from './form'
import { useReactToPrint } from 'react-to-print'
import ReportPdf from './reportPdf/reportPdf'
import MegaphoneIcon from 'assets/images/svg/MegaphoneIcon.svg'
import { Button, Dropdown } from 'antd'
import OpenLayersHeatmap from 'components/OlHeatMap'
import { conversDate } from 'core/utils/DateTimeFormat'
import IconImport from 'assets/images/svg/icon-import-complaint.svg'
import IconArrowDown from 'assets/images/svg/icon-arrow-down.svg'
import qs from 'qs'
import {
  BoxLegendChart,
  BoxPointMap,
  BoxOverAll,
  HeaderTopic,
  HeaderBox,
  Menucustom,
  BoxForm,
  BoxAllState,
  MonthText,
  FlexContainer,
  BoxContainer,
  BoxNumOfComp,
  LeftBoxNumOfComp,
  StyledImage,
  TextSatus,
  TextSatusStay,
  NumStyle,
  BoxsSatus,
  TopicHeaderStatus,
  BoxSatisfaction,
  RightBox,
  FlexOvEx,
  BoxOverLoad,
  BoxExigent,
  FlexAgency,
  BoxTitle,
} from './style'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import { checkColorDonut, checkColorDonutOvertTime, checkColorStatusPoint, convertStatusToThai } from './container'
import axios from 'axios'
import styled, { createGlobalStyle } from 'styled-components'
import { getList } from 'core/action/collection'
import moment from 'moment'
import EmtyData from 'assets/images/svg/Search Empty.svg'
import { notification } from 'antd'
import IconNoti from 'assets/images/svg/icon-noti.svg'
import IconClose from 'assets/images/svg/icon-close.svg'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'

function DashboardStatusAdmin() {
  const token = localStorage?.getItem('access_token')
  const componentRef = useRef(null)
  const [isPrint, setIsPrint] = useState(false)
  const [dataTotal, setDataTotal] = useState(0)
  const [api, contextHolder] = notification.useNotification()

  const [dataDonutStatus, setDataDonutStatus] = useState({
    donutSeries: [],
    donutXaxis: [],
    donutColor: [],
  })
  const [dataDonutOvertime, setDataDonutOvertime] = useState({
    donutOverTimeSeries: [],
    donutOverTimeXaxis: [],
    donutOverTimeColor: [],
    AllOvertime: [],
  })
  const [dataLine, setDataLine] = useState({
    dataLineSeries: [],
    dataLineXaxis: [],
  })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const [dataUrgency, setDataUrgency] = useState([])

  const [dataSumDepartment, setDataSumDepartment] = useState([])
  const [NoData, setNodata] = useState(false)
  const [dataStatusDepart, setDataStatusDepart] = useState({
    dataStatusSeries: [],
    dataStatusXaxis: [],
    AllStatusDepart: [],
  })

  const [dataOfficer, setDataOfficer] = useState({
    dataOfficerSeries: [],
    dataOfficersXaxis: [],
    // AllStatusDepart: [],
  })
  const [dataHeatMap, setDataHeatMap] = useState({
    heatmapData: [],
    heatmapColor: [],
    // AllStatusDepart: [],
  })

  const [searchData, setSearchData] = useState({
    date: [moment(new Date()).subtract(1, 'months'), moment(new Date())?.endOf('day')],
  })
  let latlng = NakhonRatchasima?.features[0]?.geometry?.coordinates[0]
  const [Loading, setLoading] = useState(false)

  const Donutlabels = [
    'รับเรื่อง',
    'อยู่ระหว่างดำเนินการ',
    'ดำเนินการแล้วเสร็จ',
    'ติดตาม',
    'ไม่อยู่ในความรับผิดชอบ',
    'เกินระยะเวลาที่กำหนด',
  ]

  const NotdefaultColor = {
    ด่วนมาก: '#EC82F8',
    ปกติ: '#27AE60',
    ด่วนที่สุด: '#1D19EC',
    ทำทันที: '#EB5757',
    ด่วน: '#FFA643',
  }

  const departMentColor = {
    สำนักช่าง: '#1D19EC',
    สำนักคลัง: '#FFA643',
    สำนักการประปา: '#56CCF2',
    สำนักการศึกษา: '#F2F2F2',
    กองสวัสดิการสังคม: '#27AE60',
    กองการเจ้าหน้าที่: '#EC82F8',
    สำนักปลัดเทศบาล: '#EB5757',
    กองยุทธศาตร์และงบประมาณ: '#F2C94C',
    สำนักสาธารณสุขและสิ่งแวดล้อม: '#9B51E0',
  }

  const AllProgessColor = {
    สำนักช่าง: '#CDCEFA',
    สำนักคลัง: '#FFF6EB',
    สำนักการประปา: '#D2EDF6',
    สำนักการศึกษา: '#F2F2F2',
    กองสวัสดิการสังคม: '#D9FFE9',
    กองการเจ้าหน้าที่: '#FDF2FE',
    สำนักงานปลัดเทศบาล: '#FECCCC',
    กองยุทธศาตร์และงบประมาณ: '#F9EDC7',
    สำนักสาธารณสุขและสิ่งแวดล้อม: '#EDF',
  }

  const [masterData, setMasterData] = useState([])

  const [meData, setMedata] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    openNotification('top')
    getMasterData()
    getDataDashboardAdmin()
    GetMeData()
  }, [searchData])

  const openNotification = placement => {
    api.info({
      message: (
        <div style={{ color: '#ffffff', fontFamily: 'Prompt', fontWeight: '400' }}>
          เรื่องร้องเรียนที่ เกินระยะเวลากำหนด
        </div>
      ),
      placement,
      // icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      // duration: 5,
      onClick: () => {
        localStorage?.setItem('ugency-dashboard', true)
        navigate('/complaint/bof-overview-complaint', {
          state: {
            ugency: true,
          },
        })
      },
    })
  }

  const GetMeData = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/me`
      const resMeData = await axios.get(`${shemadata}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (resMeData.status === 200 || resMeData.status === 201) {
        setMedata(resMeData?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getMasterData = async () => {
    const resCategories = await getList({
      schema: '/master/api/v1/all/categories',
      params: '',
    })
    const resStatus = await getList({
      schema: '/master/api/v1/all/status',
      params: '',
    })
    const resUrgency = await getList({
      schema: '/master/api/v1/all/urgency',
      params: '',
    })
    const resDepartment = await getList({
      schema: '/master/api/v1/all/organizations',
      params: '',
    })
    const resChannel = await getList({
      schema: '/master/api/v1/all/channel',
      params: '',
    })

    setMasterData({
      categories: resCategories?.items,
      status: resStatus?.items,
      urgency: resUrgency?.items,
      department: resDepartment?.items,
      chanel: resChannel?.items,
    })
  }
  const handleMenuImportClick = e => {
    setIsDropdownOpen(false)
    if (e.key === 'pdf') {
      setIsPrint(true)
      setTimeout(() => {
        handlePrint()
      }, 2000)
    } else if (e.key === 'xlsx') {
      onLoadFile('xlsx')
    } else if (e.key === 'csv') {
      onLoadFile('csv')
    }
  }
  const handleDropdownVisibleChange = visible => {
    setIsDropdownOpen(visible)
  }
  const menuImport = value => {
    return (
      <Menucustom onClick={handleMenuImportClick}>
        <Menucustom.Item key={`pdf`}>PDF</Menucustom.Item>
        <Menucustom.Item key={`xlsx`}>XLS</Menucustom.Item>
        <Menucustom.Item key={`csv`}>CSV</Menucustom.Item>
      </Menucustom>
    )
  }

  const disabledDate = current => {
    return current && current > moment().endOf('day')
  }

  const getDataDashboardAdmin = async () => {
    setNodata(false)
    // setLoading(true)
    const queryParams = {
      params: {
        filters: [
          searchData?.category?.length > 0 ? `category_id:eq:${searchData?.category}` : undefined,
          searchData?.status?.length > 0 ? `status_data.name:inLast:${searchData?.status}` : undefined,
          searchData?.department?.length > 0 ? `officer_data.department_id:eq:${searchData?.department}` : undefined,
          searchData?.chanel?.length > 0 ? `channel:eq:${searchData?.chanel}` : undefined,
          `urgency_id:isNotNull`,
        ],
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    }

    try {
      const checkStartDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[0]).startOf('day')?.unix()}`
          : moment(new Date()).subtract(1, 'months').unix()
      const checkEndDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[1]).endOf('day')?.unix()}`
          : moment(new Date())?.endOf('day')?.unix()

      // const queryParams = {}; // add by devops
      const resp = await axios.get(
        `${process.env.REACT_APP_BASEURL}/dashboard-report/api/v1/dashboard/status?start_at=${checkStartDate}&end_at=${checkEndDate}`,
        queryParams
      )

      if (resp?.status === 200 || resp?.status === 201) {
        const dataGraph = resp?.data?.data.dashboard_data
        const donutXaxis = dataGraph?.summary_status?.map(item => item?.status_name)
        const donutSeries = dataGraph?.summary_status?.map(item => item?.total)
        const donutColor = dataGraph?.summary_status?.map(item => checkColorDonut(item?.status_name))
        const donutOverTimeSeries = dataGraph?.summary_status_date?.map(item => item?.total_date)
        const donutOverTimeXaxis = dataGraph?.summary_status_date?.map(item => item?.status_date)
        const donutOverTimeColor = dataGraph?.summary_status_date?.map(item =>
          checkColorDonutOvertTime(item?.status_date)
        )

        const AllOvertime = dataGraph?.summary_status_date
        const Urgencys = dataGraph?.summary_urgent
        const Department = dataGraph?.summary_complaint_by_department
        const dataStatusXaxis = dataGraph?.summay_department_of_status?.categories
        const dataStatusSeries = dataGraph?.summay_department_of_status?.series

        const dataOfficerSeries = dataGraph?.summary_status_officer?.series
        const dataOfficersXaxis = dataGraph?.summary_status_officer?.categories

        const dataLineXaxis = dataGraph?.summary_rating.map(item => `${item?.rating} คะแนน`)
        const dataLinex = dataGraph?.summary_rating?.map(item => item?.total_rating)

        setDataTotal(dataGraph?.total_complaint)

        const heatmapData = dataGraph.map_status_data

        const dataLineSeries = [
          {
            data: dataLinex?.length > 0 ? dataLinex : [],
          },
        ]
        setDataDonutStatus({
          ...dataDonutStatus,
          donutXaxis,
          donutSeries,
          donutColor,
        })

        setDataHeatMap({
          ...dataHeatMap,
          heatmapData,
        })
        if (dataGraph?.summary_rating) {
          setDataLine({
            ...dataLine,
            dataLineSeries,
            dataLineXaxis,
          })
        } else {
          setDataLine({
            dataLineSeries: [],
            dataLineXaxis: [],
          })
        }

        setDataOfficer({
          ...dataOfficer,
          dataOfficerSeries,
          dataOfficersXaxis,
        })

        setDataStatusDepart({
          ...dataStatusDepart,
          dataStatusXaxis,
          dataStatusSeries,
        })

        setDataSumDepartment(Department)
        setDataUrgency(Urgencys)

        setDataDonutOvertime({
          ...dataDonutOvertime,
          donutOverTimeSeries,
          donutOverTimeXaxis,
          donutOverTimeColor,
          AllOvertime,
        })
      } else {
        setNodata(true)
      }
    } catch (error) {
      setNodata(true)
      setDataSumDepartment([])

      setDataUrgency([])

      setDataOfficer({
        dataOfficerSeries: [],
        dataOfficersXaxis: [],
      })

      setDataStatusDepart({
        dataStatusXaxis: [],
        dataStatusSeries: [],
      })

      setDataDonutStatus({
        donutXaxis: [],
        donutSeries: [],
        donutColor: [],
      })

      setDataDonutOvertime({
        donutOverTimeSeries: [],
        donutOverTimeXaxis: [],
        donutOverTimeColor: [],
        AllOvertime: [],
      })
    }
    // setLoading(false)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `reportdash`,
    onAfterPrint: () => setIsPrint(false),
  })

  const onPrintQuick = () => {
    setIsPrint(true)
    setTimeout(() => {
      handlePrint()
    }, 2000)
  }

  const onLoadFile = async typeFile => {
    try {
      const checkStartDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[0]).startOf('day')?.unix()}`
          : moment(new Date()).subtract(1, 'months').unix()
      const checkEndDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[1]).endOf('day')?.unix()}`
          : moment(new Date())?.endOf('day')?.unix()
      // const queryParams = {
      //   params: {
      //     filters: [
      //       searchData?.category?.length > 0 ? `category_id:eq:${searchData?.category}` : undefined,
      //       searchData?.status?.length > 0 ? `status_data.name:inLast:${searchData?.status}` : undefined,
      //       searchData?.department?.length > 0 ? `officer_data.department_id:eq:${searchData?.department}` : undefined,
      //       searchData?.chanel?.length > 0 ? `channel:eq:${searchData?.chanel}` : undefined,
      //       `urgency_id:isNotNull`,
      //     ],
      //   },
      //   paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      // }

      const filterCattegory = searchData?.category?.length > 0 ? `&filters=category_id:eq:${searchData?.category}` : ''
      const filterStatus =
        searchData?.status?.length > 0 ? `&filters=status_data.name:inLast:${searchData?.status}` : ''
      const ftDepartment =
        searchData?.department?.length > 0 ? `&filters=officer_data.department_id:eq:${searchData?.department}` : ''

      const ftChanel = searchData?.chanel?.length > 0 ? `&filters=channel:eq:${searchData?.chanel}` : ''

      const queryParams = `&filters=urgency_id:isNotNull`

      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/dashboard-report/api/v1/dashboard/status/export?start_at=${checkStartDate}&end_at=${checkEndDate}&file_extension=${typeFile}${queryParams}${filterStatus}${ftDepartment}${ftChanel}${filterCattegory}`,
        {
          responseType: 'blob',
        }
      )

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `dashboard-admin-status.${typeFile}`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {}
  }

  return (
    <>
      <NotiStyle />
      {contextHolder}

      <BoxOverAll>
        <HeaderTopic>
          <div>แดชบอร์ดแสดงสถานะการทำงานของเจ้าหน้าที่สำนักปลัดเทศบาล </div>
          <BoxTitle>
            <div style={{ fontSize: '24px', fontWeight: 500, color: '#19263F' }}></div>
            <Dropdown
              overlay={() => menuImport()}
              trigger={['click']}
              getPopupContainer={triggerNode => triggerNode.parentElement}
              onVisibleChange={handleDropdownVisibleChange}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '12px',
                  width: '160px',
                  backgroundColor: '#F36B24',
                  borderRadius: '8px',
                  height: '40px',
                  cursor: 'pointer',
                }}
              >
                <img src={IconImport} alt="add" style={{ display: 'inline' }} />
                <span style={{ paddingLeft: '4px', paddingTop: '1px', fontSize: '16px', color: '#ffffff' }}>
                  ส่งออกข้อมูล
                </span>
                <img
                  src={IconArrowDown}
                  alt="add"
                  style={{
                    display: 'inline',
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </div>
            </Dropdown>
          </BoxTitle>
        </HeaderTopic>
        <BoxForm>
          {
            <FormSelect
              masterData={masterData}
              setSearchData={setSearchData}
              searchData={searchData}
              conversDate={conversDate}
              disabledDate={disabledDate}
            />
          }
        </BoxForm>

        {NoData ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <div>
              <img src={EmtyData} alt="emty" />
            </div>
            <div>ไม่มีข้อมูล</div>
          </div>
        ) : (
          <BoxAllState>
            <FlexContainer>
              <BoxContainer>
                <BoxNumOfComp>
                  <LeftBoxNumOfComp>
                    <StyledImage src={MegaphoneIcon} alt="Megaphone Icon" />
                    <TextSatus>จำนวนเรื่องร้องเรียนทั้งหมด</TextSatus>
                    <TextSatusStay>(เรื่อง)</TextSatusStay>
                  </LeftBoxNumOfComp>
                  <NumStyle>{dataTotal}</NumStyle>
                </BoxNumOfComp>
                <BoxsSatus>
                  <TopicHeaderStatus>
                    <TextSatus>จำนวนเรื่องร้องเรียนตามสถานะ</TextSatus>
                    <TextSatusStay>(เรื่อง)</TextSatusStay>
                  </TopicHeaderStatus>
                  <div style={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
                    <DonutChart
                      width="325px"
                      height="300px"
                      legendShow={'noShow'}
                      labels={dataDonutStatus?.donutXaxis}
                      series={dataDonutStatus?.donutSeries}
                      colors={dataDonutStatus?.donutColor}
                    />
                    <div>
                      {dataDonutStatus.donutXaxis?.map((item, index) => {
                        return (
                          index < 5 && (
                            <BoxLegendChart BgColor={checkColorDonut(item)} key={index}>
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: '20px 50px 1fr',
                                  fontSize: '10px',
                                  color: '#717171',
                                  marginBottom: '15px',
                                  alignItems: 'center',
                                }}
                              >
                                <div className="point" />
                                <div style={{ fontSize: '12px', fontWeight: 500, color: '#150F0B' }}>
                                  {/* {Donutseries[index].toLocaleString()} */}
                                  {dataDonutStatus?.donutSeries[index]}
                                </div>
                                <div> {convertStatusToThai(item)}</div>
                              </div>
                            </BoxLegendChart>
                          )
                        )
                      })}
                    </div>
                  </div>
                </BoxsSatus>
                <BoxSatisfaction>
                  <TopicHeaderStatus>
                    <TextSatus>ผลประเมินความพึงพอใจ</TextSatus>
                    <TextSatusStay>(เรื่อง)</TextSatusStay>
                  </TopicHeaderStatus>
                  <LinCharts
                    series={dataLine?.dataLineSeries}
                    xaxis={dataLine?.dataLineXaxis}
                    width="480px"
                    height="200px"
                  />
                </BoxSatisfaction>
              </BoxContainer>
              <RightBox>
                <TopicHeaderStatus>
                  <TextSatus style={{ padding: '20px', display: 'flex', gap: '10px' }}>
                    แผนที่แสดงความหนาแน่นของเรื่องร้องเรียนตามพื้นที่ <TextSatusStay>(เรื่อง)</TextSatusStay>
                  </TextSatus>
                </TopicHeaderStatus>
                <div style={{ position: 'relative' }}>
                  <div
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '10px',
                      padding: '10px',
                      position: 'absolute',
                      zIndex: 999,
                      width: '145px',
                      height: '160px',
                      top: '0px',
                      left: '20px',
                    }}
                  >
                    {Donutlabels?.map((item, index) => (
                      <BoxPointMap BgColor={checkColorStatusPoint(item)} key={index}>
                        <div className="point" />
                        <span>{item}</span>
                      </BoxPointMap>
                    ))}
                  </div>
                  {!isPrint && (
                    <>
                      <OpenLayersHeatmap
                        mapData={dataHeatMap?.heatmapData}
                        zooms={13}
                        height={'69vh'}
                        isPrint={isPrint}
                      />
                    </>
                  )}
                </div>
              </RightBox>
            </FlexContainer>

            <FlexOvEx>
              <BoxOverLoad>
                <TopicHeaderStatus>
                  <TextSatus>จำนวนเรื่องร้องที่อยู่ระหว่างดำเนินการและเกินระยะเวลาที่กำหนด</TextSatus>
                  <TextSatusStay>(เรื่อง)</TextSatusStay>
                </TopicHeaderStatus>
                <div style={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
                  <DonutChart
                    width="325px"
                    height="300px"
                    legendShow={'noShow'}
                    series={dataDonutOvertime.donutOverTimeSeries}
                    colors={dataDonutOvertime.donutOverTimeColor}
                    labels={dataDonutOvertime.donutOverTimeXaxis}
                  />
                  <div>
                    {dataDonutOvertime?.AllOvertime?.map((item, index) => {
                      return (
                        <BoxLegendChart BgColor={checkColorDonutOvertTime(item?.status_date)} key={index}>
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '20px 50px 50px 1fr',
                              fontSize: '10px',
                              color: '#717171',
                              marginBottom: '15px',
                              alignItems: 'center',
                            }}
                          >
                            <div className="point" />
                            <div style={{ fontSize: '12px', fontWeight: 500, color: '#150F0B' }}>
                              {/* {DonutseriesInProgress[index].toLocaleString()} */}

                              {item?.total_date}
                            </div>
                            <div>{item?.percent_date} %</div>
                            <div>
                              {' '}
                              {item?.status_date === 'overdate' ? 'เกินระยะเวลาที่กำหนด' : 'อยู่ระหว่างดำเนินการ'}
                            </div>
                          </div>
                        </BoxLegendChart>
                      )
                    })}
                  </div>
                </div>
              </BoxOverLoad>
              <BoxExigent>
                <TopicHeaderStatus>
                  <TextSatus>สัดส่วนเรื่องร้องเรียนตามความเร่งด่วน</TextSatus>
                  <TextSatusStay>(เรื่อง)</TextSatusStay>
                </TopicHeaderStatus>
                <div>
                  {dataUrgency?.map((item, index) => (
                    <Boxflex key={index} Margin={'40px 0'}>
                      <BoxDepart>
                        <Dotstar DtColor={NotdefaultColor[item?.urgent_name]} />
                        <div>{item?.urgent_name}</div>
                      </BoxDepart>
                      <Progress PgColor={NotdefaultColor[item]}>
                        <Bar
                          className="men"
                          fontSize={10}
                          percen={item?.precent_urgent}
                          color={NotdefaultColor[item?.urgent_name]}
                        >
                          {item?.precent_urgent ? item?.precent_urgent?.toFixed() + ' %' : ''}
                        </Bar>
                      </Progress>

                      <Boxnum>{item?.total_urgent}</Boxnum>
                    </Boxflex>
                  ))}
                </div>
              </BoxExigent>
              {/* progress */}
            </FlexOvEx>

            {/* department Progress */}
            <FlexAgency>
              <TopicHeaderStatus>
                <TextSatus>จำนวนเรื่องร้องเรียนของแต่ละสำนัก</TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </TopicHeaderStatus>
              <div>
                {dataSumDepartment &&
                  dataSumDepartment?.map((item, index) => (
                    <Boxflex key={index} Margin={'40px 0'}>
                      <BoxDepart>
                        <Dotstar DtColor={departMentColor[item?.department_name]} />
                        <div>{item?.department_name}</div>
                      </BoxDepart>

                      <Progress PgColor={AllProgessColor[item?.department_name]}>
                        <Bar
                          className="men"
                          fontSize={10}
                          percen={item?.parcent}
                          color={departMentColor[item?.department_name]}
                        >
                          {item?.parcent ? item?.parcent?.toFixed() + ' %' : ''}
                        </Bar>
                      </Progress>
                      <Boxnum>{item?.total}</Boxnum>
                    </Boxflex>
                  ))}
              </div>
            </FlexAgency>

            <FlexAgency>
              <TopicHeaderStatus>
                <TextSatus>กราฟแสดงจำนวนสถานะของแต่ละหน่วยงาน</TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </TopicHeaderStatus>
              <ColumnsChart
                xaxis={dataStatusDepart.dataStatusXaxis}
                series={dataStatusDepart.dataStatusSeries}
              ></ColumnsChart>
            </FlexAgency>

            {/* ///dashbaord */}
            <FlexAgency>
              <TopicHeaderStatus>
                <TextSatus>จำนวนเจ้าหน้าที่และเรื่องร้องเรียนในความรับผิดชอบ</TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </TopicHeaderStatus>
              <ColumnsChart
                xaxis={dataOfficer.dataOfficersXaxis}
                series={dataOfficer.dataOfficerSeries}
                columnWidth={'100%'}
                borderRadius={2}
                strokeWidth={0}
              ></ColumnsChart>
            </FlexAgency>

            {/* ///dashbaord */}

            <FlexAgency>
              <TopicHeaderStatus>
                <TextSatus>จำนวนการกดเปลี่ยนสถานะของเจ้าหน้าที่</TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </TopicHeaderStatus>
              <ColumnsChart
                xaxis={dataOfficer.dataOfficersXaxis}
                series={dataOfficer.dataOfficerSeries}
                columnWidth={'100%'}
                borderRadius={2}
                strokeWidth={0}
              ></ColumnsChart>
            </FlexAgency>
          </BoxAllState>
        )}
      </BoxOverAll>

      <div ref={componentRef} style={{ display: isPrint ? 'block' : 'none' }}>
        <ReportPdf
          meData={meData}
          searchData={searchData}
          isPrint={isPrint}
          dataSumDepartment={dataSumDepartment}
          dataStatusDepart={dataStatusDepart}
          dataOfficer={dataOfficer}
          dataLine={dataLine}
          dataDonutStatus={dataDonutStatus}
          dataDonutOvertime={dataDonutOvertime}
          dataUrgency={dataUrgency}
          dataTotal={dataTotal}
          dataHeatMap={dataHeatMap}
        />
      </div>
    </>
  )
}

export default DashboardStatusAdmin

const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #ec906c;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }
    .ant-notification-notice-message {
      font-family: 'IBM Plex Sans Thai', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 8px;
      a {
        line-height: 18px;
      }
      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }
    .ant-notification-notice-close {
      padding-top: 5px;
    }
  }
`

const Boxnum = styled.div`
  margin: 0 10px;
  font-size: ${props => (props.fontSize ? props.fontSize : '18px')};
`

const Dotstar = styled.div`
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  margin: 0 10px;
  background: ${props => props.DtColor || '#ededed'};
`

const BoxDepart = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: ${props => (props.minWidth ? props.minWidth : '200px')};
`

const Boxflex = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => (props.Margin ? props.Margin : '40px 0')};
  &.time-line {
    display: flex;
    justify-content: start;
    align-items: start;
  }
`

const Progress = styled.div`
  max-width: ${props => (props.MaxwidthProgress ? props.MaxwidthProgress : '')};
  min-width: ${props => (props.MinwidthProgress ? props.MinwidthProgress : '')};
  width: ${props => (props.PgWidth ? props.PgWidth : '100%')};
  height: ${props => (props.PgHeight ? props.PgHeight : '44px')};
  /* height: 44px; */
  border-radius: 10px;
  box-shadow: 0 0 3px #b0b0;
  background-color: #fecccc;
  display: flex;
  overflow: hidden;
  background: ${props => props.PgColor || '#ededed'};
`

export const Bar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: ${props => props.percen || 0}%;
  color: white;
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize || 10}px;
  line-height: 16px;
  background: ${props => props.color || '#ededed'};
`
