import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
export default function ButtonUpdateMedia({ onClick }) {
  return (
    <div>
      <ButtonEdit onClick={onClick}>แก้ไข</ButtonEdit>
    </div>
  )
}

export const ButtonEdit = styled(Button)`
  width: 60px;
  height: 25px;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background: #f36b24 !important;
  border-color: #f36b24;
  color: #fbfbfb;
  :focus,
  :hover {
    background: #f36b24 !important;
    border-color: #f36b24;
    color: #fbfbfb;
  }
`
