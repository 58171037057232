import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import NoCom from 'assets/images/png/Group 48097700.png'
import { ReactComponent as LocationIcon } from 'assets/images/svg/Location.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/svg/arrow-icon.svg'
import { ReactComponent as IconRepair } from 'assets/images/svg/repair-icon.svg'
import { ReactComponent as IconBeforbox } from 'assets/images/svg/update-box.svg'
import { ReactComponent as IconDone } from 'assets/images/svg/success-update-e-service.svg'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import IconUpdateOrg from 'assets/images/svg/gear-orange-update.svg'
import IconUpdateGreen from 'assets/images/svg/gear-green.svg'
import { Button } from 'antd'
// import DisplayAddress from '../../getAddress'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as IconGear } from 'assets/images/svg/department/gear-icon.svg'
import { ReactComponent as IconMoney } from 'assets/images/svg/department/money.svg'
import { ReactComponent as IconStudy } from 'assets/images/svg/department/study.svg'
import { ReactComponent as IconHuman } from 'assets/images/svg/department/human.svg'
import { ReactComponent as IconStaff } from 'assets/images/svg/department/staff.svg'
import { ReactComponent as IconHospital } from 'assets/images/svg/department/hospital.svg'

import axios from 'axios'
const CardResultBox = ({
  complaintId,
  dataTranSaction,
  setPreviewImg,
  setPreviewVdo,
  setOpenModalImage,
  setOpenModalVdo,
  dataComplaint,
}) => {
  const [MeOrganize, setMeOrganize] = useState([])
  const navigate = useNavigate()
  const token = localStorage.getItem('access_token')

  const dePartMentIcon = {
    สำนักช่าง: <IconGear />,
    สำนักคลัง: <IconMoney />,
    สำนักการศึกษา: <IconStudy />,
    กองสวัสดิการสังคม: <IconHuman />,
    กองการเจ้าหน้าที่: <IconMoney />,
    สำนักปลัดเทศบาล: <IconStaff />,
    กองยุทธศาตร์และงบประมาณ: <IconMoney />,
    สำนักสาธารณสุขและสิ่งแวดล้อม: <IconHospital />,
  }

  useEffect(() => {
    GetMedata()
  }, [])

  const GetMedata = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/me`
      const resMedata = await axios.get(`${shemadata}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if ((resMedata.status === 200) | (resMedata.status === 201)) {
        setMeOrganize(resMedata?.data?.data)
      }
    } catch (error) {}
  }

  const Mapmedia = dataMedia => {
    const CardUi =
      dataMedia &&
      dataMedia.map((item, index) => {
        if (item.includes('mp4')) {
          return (
            <>
              <div
                className="show-image-div click"
                onClick={() => {
                  setPreviewVdo(item)
                  setOpenModalVdo(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <video style={{ width: '180px', height: '120px', borderRadius: '8px', margin: '10px 5px' }}>
                    <source src={item} type="video/mp4" />
                  </video>
                  <div style={{ position: 'absolute' }}>
                    {/* นำ icon มาวางที่นี่ */}
                    <IvdoPlay />
                  </div>
                </div>
              </div>
            </>
          )
        } else {
          return (
            <div>
              <img
                style={{ borderRadius: '8px', width: '180px', height: '120px', margin: '10px 5px' }}
                className="show-image click"
                src={item}
                alt="image"
                onClick={() => {
                  setOpenModalImage(true)
                  setPreviewImg(item)
                }}
              />
            </div>
          )
        }
      })
    return CardUi
  }

  const onPageForm = (type, dataId) => {
    if (dataTranSaction?.length > 1) {
      navigate('/e-service/bof-eservice/update-form-status', {
        state: {
          type,
          complaintId,
          department_id: dataId?.department_id,
          dataComplaint: dataComplaint,
          manyOrganize: true,
        },
      })
    } else {
      navigate('/e-service/bof-eservice/update-form-status', {
        state: {
          type,
          complaintId,
          department_id: dataId?.department_id,
          dataComplaint: dataComplaint,
          manyOrganize: false,
        },
      })
    }
  }

  const onCheckMeOrg = itemCard => {
    if (itemCard?.department_id === MeOrganize?.organize_data?.org_id) {
      return false
    } else {
      return true
    }
  }
  return (
    <>
      {dataTranSaction.length > 0 ? (
        dataTranSaction.map((itemBox, indexBox) => {
          if (itemBox.length === 2 && itemBox.status !== '') {
            return (
              <ContainerView>
                <div style={{ width: '100%', margin: '0' }}>
                  <Title>
                    ผลการปฎิบัติงาน
                    <BoxnameDepart>
                      {itemBox?.map((itemIcon, indexIcon) => (
                        <>
                          <div style={{ margin: '0 3px' }} key={indexIcon}>
                            {indexIcon === 0 && dePartMentIcon[itemIcon?.department_name]}
                          </div>
                          <div key={indexIcon}>{indexIcon === 0 && itemIcon?.department_name}</div>
                        </>
                      ))}
                    </BoxnameDepart>
                  </Title>
                  <BoxMedia>
                    {itemBox?.map((itemCard, indexCard) => {
                      return (
                        <>
                          {indexCard === 1 && (
                            <ArrowIconBox>
                              <ArrowIcon />
                            </ArrowIconBox>
                          )}

                          <Boxbg
                            Bgcolor={indexCard === 0 ? '#F9F9F9' : '#F1FCFB'}
                            Padding="1.5em"
                            BdRadius="24px"
                            Bwidth={'49%'}
                            key={indexCard}
                          >
                            <BoxResult>
                              <HeadeResult>
                                <div style={{ margin: '0 5px' }}>
                                  {/* {indexCard === 0 ? <IconRepair /> : <IconBeforbox />} */}
                                  {indexCard === 0 ? <IconRepair /> : <IconDone />}
                                </div>
                                <div className="header-result">
                                  {indexCard === 0 ? 'ก่อนการปฏิบัติงาน' : 'หลังการปฏิบัติงาน'}
                                  <div style={{ fontSize: '14px' }}>เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน</div>
                                </div>
                              </HeadeResult>

                              <div style={{ display: 'flex' }}>{Mapmedia(itemCard?.media_url)}</div>

                              <div style={{ display: 'flex' }}>
                                <LocationIcon />
                                <div>
                                  <p style={{ color: '#C4C4C4' }}>
                                    {itemCard?.address}
                                    {/* {itemCard?.coordinates && <DisplayAddress coordinates={itemCard?.coordinates} />} */}
                                  </p>
                                </div>
                              </div>
                            </BoxResult>
                          </Boxbg>
                        </>
                      )
                    })}
                  </BoxMedia>
                </div>
              </ContainerView>
            )
          } else if (itemBox.find(itemx => itemx.type === 'befor')) {
            return (
              <ContainerView>
                <div style={{ width: '100%', margin: '0' }}>
                  <Title>
                    ผลการปฎิบัติงาน
                    <BoxnameDepart>
                      {itemBox?.map((itemIcon, indexIcon) => (
                        <>
                          <div style={{ margin: '0 3px' }} key={indexIcon}>
                            {indexIcon === 0 && dePartMentIcon[itemIcon?.department_name]}
                          </div>
                          <div key={indexIcon}>{indexIcon === 0 && itemIcon?.department_name}</div>
                        </>
                      ))}
                    </BoxnameDepart>
                  </Title>
                  <BoxMedia>
                    {itemBox?.map((itemCard, indexCard) => {
                      return (
                        <>
                          <Boxbg Bgcolor="#F9F9F9" Padding="2%" BdRadius="24px" Bwidth={'49%'}>
                            <BoxResult>
                              <HeadeResult>
                                <IconRepair />
                                <div className="header-result">
                                  ก่อนการปฏิบัติงาน
                                  <div style={{ fontSize: '14px' }} className="title-color">
                                    เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน
                                  </div>
                                </div>
                              </HeadeResult>

                              <div style={{ display: 'flex' }}>{Mapmedia(itemCard?.media_url)}</div>

                              <div style={{ display: 'flex' }}>
                                <LocationIcon />
                                <div>
                                  <p style={{ color: '#C4C4C4' }}>
                                    {itemCard?.address}
                                    {/* {itemCard?.coordinates && <DisplayAddress coordinates={itemCard?.coordinates} />} */}
                                  </p>
                                </div>
                              </div>
                            </BoxResult>
                          </Boxbg>
                          <Boxbg Bgcolor="#ffff" Padding="2%" BdRadius="24px" Bwidth={'49%'} className="shadow">
                            <BoxResult>
                              <HeadeResult>
                                <IconBeforbox />
                                <div className="header-result">
                                  หลังการปฏิบัติงาน
                                  <div style={{ fontSize: '14px' }} className="title-color">
                                    {' '}
                                    เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน
                                  </div>
                                </div>
                              </HeadeResult>

                              <ImageCard className="center-image">
                                <div>
                                  <img src={IconUpdateGreen} alt="update-org" />
                                </div>
                              </ImageCard>

                              <div className="center-image" onClick={() => onPageForm('after', itemCard)}>
                                <ButtonEdit Bgcolor="#13AFA6" disabled={onCheckMeOrg(itemCard)}>
                                  อัปเดต
                                </ButtonEdit>
                              </div>
                            </BoxResult>
                          </Boxbg>
                        </>
                      )
                    })}
                  </BoxMedia>
                </div>
              </ContainerView>
            )
          } else {
            return (
              <ContainerView>
                <div style={{ width: '100%', margin: '1em' }}>
                  <Title>
                    ผลการปฎิบัติงาน
                    <BoxnameDepart>
                      {itemBox?.map((itemIcon, indexIcon) => (
                        <>
                          <div style={{ margin: '0 3px' }} key={indexIcon}>
                            {indexIcon === 0 && dePartMentIcon[itemIcon?.department_name]}
                          </div>
                          <div key={indexIcon}>{indexIcon === 0 && itemIcon?.department_name}</div>
                        </>
                      ))}
                    </BoxnameDepart>
                  </Title>

                  <div style={{ margin: '10px 10px 20px 10px', color: '#C4C4C4' }}>
                    เพื่อให้การดำเนินงานเป็นไปอย่างโปร่งใส และสะดวกเรียบร้อย
                    เจ้าหน้าที่ต้องอัปเดตข้อมูลในขั้นตอนก่อนเริ่มปฏิบัติงาน และเมื่อปฏิบัติงานเสร็จสิ้น{' '}
                  </div>

                  <BoxMedia>
                    {itemBox.map((itemCard, indexCard) => {
                      return (
                        <Boxbg Bgcolor="#F9F9F9" Padding="2%" BdRadius="24px" Bwidth={'49%'}>
                          <BoxResult>
                            <HeadeResult>
                              <IconRepair />
                              <div className="header-result">
                                ก่อนการปฏิบัติงาน
                                <div style={{ fontSize: '14px' }} className="title-color">
                                  เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน
                                </div>
                              </div>
                            </HeadeResult>

                            <ImageCard className="center-image">
                              <div>
                                <img src={IconUpdateOrg} alt="update-org" />
                              </div>
                            </ImageCard>

                            <div className="center-image" onClick={() => onPageForm('befor', itemCard)}>
                              <ButtonEdit disabled={onCheckMeOrg(itemCard)}>อัปเดต</ButtonEdit>
                            </div>
                          </BoxResult>
                        </Boxbg>
                      )
                    })}

                    <ArrowIconBox>
                      <ArrowIcon />
                    </ArrowIconBox>

                    <Boxbg Bgcolor="#F9F9F9" Padding="2%" BdRadius="24px" Bwidth={'49%'}>
                      <BoxResult>
                        <HeadeResult>
                          <IconRepair />
                          <div className="header-result">
                            หลังการปฏิบัติงาน
                            <div style={{ fontSize: '14px' }} className="title-color">
                              เพิ่มข้อมูลส่วนของงานหลังเริ่มการปฏิบัติงาน
                            </div>
                          </div>
                        </HeadeResult>

                        <ImageCard className="center-image">
                          <div>
                            <img src={NoCom} alt="update-org" />
                          </div>
                        </ImageCard>

                        <div className="center-image">
                          <ButtonEdit disabled={true}>อัปเดต</ButtonEdit>
                        </div>
                      </BoxResult>
                    </Boxbg>
                  </BoxMedia>
                </div>
              </ContainerView>
            )
          }
        })
      ) : (
        // ไม่มีประโยชน์
        <ContainerView></ContainerView>
      )}
    </>
  )
}

export default CardResultBox

const BoxnameDepart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 4px 0 0 15px;
`

const Title = styled.div`
  display: flex;
  font-size: 18px;
  margin: 10px;
`

const Boxcard = styled.div`
  display: flex;
`

const ImageCard = styled.div`
  /* margin: 3%; */
  align-self: center;
  img {
    /* min-height: 5.5vw; */
    /* 
 width: '180px', height: '120px' */
    min-width: 180px;
    min-height: 120px;
  }
`

const ContainerView = styled.div`
  background-color: #ffff;
  border-radius: 24px;
  padding: 2em;
  margin-bottom: 20px;

  .title-color {
    color: #c4c4c4;
  }
`
const Boxbg = styled.div`
  background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#ffff')};
  border-radius: ${props => (props.BdRadius ? `${props.BdRadius}` : '16px')};
  padding: ${props => (props.Padding ? `${props.Padding}` : '1em')};
  width: ${props => (props.Bwidth ? `${props.Bwidth}` : '100%')};

  &.shadow {
    box-shadow: 0px 4px 30px 0px rgba(46, 60, 79, 0.08);
  }
`
const HeadeResult = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  /* margin: 0 1em; */
`

const BoxMedia = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 19px;
`
const ArrowIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`

const BoxResult = styled.div`
  display: flex;
  flex-direction: column;
  margin: -1 10px;

  .header-result {
    display: flex;
    flex-direction: column;
    font-size: 19px;
    margin: 0 4px;
  }

  .center-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ButtonEdit = styled(Button)`
  &.ant-btn {
    box-shadow: none;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    /* background: #f36b24; */

    background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#f36b24')};
    width: 140px;
    height: 40px;
  }

  &.ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    border-radius: 6px;
    border: none;
    width: 140px;
    height: 40px;
  }
`
