import React, { useRef, useEffect, useState, useContext } from 'react'
import { Button, Dropdown, Pagination, Spin, Tooltip, notification } from 'antd'
import TableCustom from 'components/TableCustom/Row'
import PaginationCustom from 'components/PaginationCustom'
import { BoxTable, Menucustom, BoxMap, CustomModal } from './list.style'
import AddOfficer from './AddOfficer'
import EditOfficer from './EditOfficer'
import Search from './search'
import { useReactToPrint } from 'react-to-print'
import ExportComplante from './export'
import UserContext from 'core/contexts/userContext'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as File } from '../image/svg/File.svg'
import { ReactComponent as View } from '../image/svg/View.svg'
import { ReactComponent as Update } from '../image/svg/Update.svg'
import { ReactComponent as Edit } from '../image/svg/Edit.svg'
import { ReactComponent as Export } from '../image/svg/Export.svg'
import { ReactComponent as Arrow } from '../image/svg/Arrow.svg'
import { ReactComponent as Arrow_Active } from '../image/svg/Arrow_Active.svg'
import { ReactComponent as AddStraff } from '../image/svg/AddStraff.svg'
import { ReactComponent as AddComplant } from '../image/svg/AddComplant.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import IconNoti from 'assets/images/svg/icon-noti.svg'
import IconClose from 'assets/images/svg/icon-close.svg'
import { MoreOutlined } from '@ant-design/icons'
import { checkColorStatusBg, checkColorStatusText, checkStatusText, checkDueDate } from './container'
import { getData, getList, getMe } from 'core/action/collection'
import moment from 'moment'
import { adminauth2 } from 'core/schemas'
const DropdownCustom = Dropdown
const Index = () => {
  const organizeId = localStorage.getItem('organize')
  const { meData } = useContext(UserContext)

  const componentRef = useRef()
  const navigate = useNavigate()
  const [dataList, setDataList] = useState([])
  const [masterData, setMasterData] = useState([])
  const [searchData, setSearchData] = useState({
    date: [moment().subtract(30, 'days'), moment()],
  })
  const [dataExport, setdataExport] = useState([])
  const [isPrint, setIsPrint] = useState(false)
  const [curantPage, setCurantPage] = useState(1)
  const [dataLength, setdataLength] = useState(0)
  const [loadingTable, setLoadingTable] = useState(true)
  const [sortDate, setSortDate] = useState(false)
  const [loadingAddOfficer, setLoadingAddOfficer] = useState(true)
  const [deparmentData, setDeparmentData] = useState([])
  const [complaintId, setComplaintId] = useState('')
  const [openDrawerAddOfficer, setOpenDrawerAddOfficer] = useState(false)
  const [openDrawerEditOfficer, setOpenDrawerEditOfficer] = useState(false)
  const [leaveModalOpen, setLeaveModalOpen] = useState(false)
  const [listAdmin, setListAdmin] = useState([])

  useEffect(() => {
    getDataEservice()
    getMasterData()
  }, [curantPage])

  useEffect(() => {
    // getMeData()
    getListAllAdmin()
  }, [])

  useEffect(() => {
    setLoadingTable(true)
    setCurantPage(1)
    getDataEservice()
  }, [searchData])

  useEffect(() => {
    setCurantPage(1)
    getDataEservice()
  }, [sortDate])
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setIsPrint(false)
    },
    documentTitle: `ใบงานเรื่องร้องเรียน_${
      dataExport?.sub_category_name ? dataExport?.sub_category_name : ''
    }_${moment().add(543, 'year').format('DD-MM-YYYY')}.pdf`,
  })
  const getPopupContainer = triggerNode => {
    return triggerNode.parentNode
  }
  const handleMenuList = (e, data) => {
    e.domEvent.stopPropagation()
    const key = e?.key.split('/')
    if (key[3] === 'view' || key[3] === 'view-group') {
      navigate(e.key, {
        state: {
          record: data?.id,
        },
      })
    } else if (key[3] === 'update') {
      navigate(e.key, {
        state: {
          record: '',
          isEdit: true,
        },
      })
    } else if (key[0] === 'view-update-status') {
      navigate(e.key, {
        state: {
          record: data?.id,
          isEdit: true,
        },
      })
    } else if (key[3] === 'delete') {
      navigate('/operation-tools/bof-group/view', {
        state: {
          record: '',
          isDelete: true,
        },
      })
    } else if (key[0] === 'export') {
      setIsPrint(true)
      setdataExport(data)
      setTimeout(() => {
        handlePrint(data)
      }, 1000)
    }
  }
  const getDataEservice = async () => {
    const res = await getList({
      schema: '/complaint/api/v1/e-service/complaint',
      params: {
        page: curantPage,
        per_page: 14,
        sort: [sortDate ? `created_at:asc` : `created_at:desc`],
        filters: [
          meData?.position_data?.id === '1730459515121299456'
            ? undefined
            : meData?.position_data?.id === '1730459515154853888'
            ? `department_data:eq:${meData?.organize_data?.org_id}`
            : `officer_data.staff_by:eq:${meData?.id}`,
          searchData?.status ? `status_data.name:inLast:${searchData?.status}` : undefined,
          searchData?.channel ? `channel:eq:${searchData?.channel}` : undefined,
          searchData?.type_of_complaint ? `type_of_complaint:eq:${searchData?.type_of_complaint}` : undefined,
          searchData?.date
            ? `created_at:between:${moment(searchData?.date[0]).startOf('day').unix()}|${moment(searchData?.date[1])
                .endOf('day')
                .unix()}`
            : undefined,
        ],
        search: [
          searchData?.search ? `title_complaint:${searchData?.search}` : undefined,
          searchData?.search ? `complaint_no:${searchData?.search}` : undefined,
          searchData?.search ? `mobile_number:${searchData?.search}` : undefined,
        ],
      },
    })
    if (res?.code === 200) {
      setTimeout(() => {
        setDataList(res?.items)
        setdataLength(res?.dataLength ? res?.dataLength : 0)
        setLoadingTable(false)
      }, 500)
    } else {
      setDataList([])
      setdataLength(0)
      setTimeout(() => {
        setLoadingTable(false)
      }, 500)
    }
  }

  const getListAllAdmin = async () => {
    const res = await getData({
      schema: `${adminauth2}/all/admin`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        setListAdmin(res.data)
        setLoadingAddOfficer(false)
      } else {
        console.log('Err', res)
      }
    }
  }

  const menu = value => {
    const filteredOrganize = value?.department_data
      ? Array.isArray(value?.department_data)
        ? value?.department_data?.filter(item => item?.id === organizeId)
        : value?.department_data
      : ''
    const deparmentData = filteredOrganize
      ? Array.isArray(filteredOrganize)
        ? filteredOrganize[0]
        : filteredOrganize
      : ''
    const checkStatus = Array.isArray(value?.status_data)
      ? value?.status_data[value?.status_data?.length - 1]?.name !== 'done'
      : value?.status_data?.name !== 'done'

    return (
      <Menucustom onClick={e => handleMenuList(e, value)}>
        {meData?.position_data?.id === '1730459515121299456' ? (
          <>
            <Menucustom.Item key={`/e-service/bof-eservice/view`}>
              <View style={{ marginRight: '5px' }} />
              ดูข้อมูล
            </Menucustom.Item>
            <Menucustom.Item key={'export'}>
              <Export style={{ marginRight: '5px' }} />
              ออกใบงาน
            </Menucustom.Item>
          </>
        ) : (
          <>
            <Menucustom.Item key={`/e-service/bof-eservice/view`}>
              <View style={{ marginRight: '5px' }} />
              ดูข้อมูล
            </Menucustom.Item>
            {value?.status_data?.name !== 'done' ? (
              value?.department_data?.officer_data?.id ? (
                <Menucustom.Item onClick={e => showDrawerEditOfficer(e, value)}>
                  <Edit style={{ marginRight: '5px' }} />
                  โอนย้าย/แก้ไขผู้รับผิดชอบ
                </Menucustom.Item>
              ) : (
                <Menucustom.Item onClick={e => showDrawerAddOfficer(e, value)}>
                  <AddStraff style={{ marginRight: '5px' }} />
                  เพิ่มผู้รับผิดชอบ
                </Menucustom.Item>
              )
            ) : (
              ''
            )}
            {/* {value?.department_data?.officer_data?.id === meData?.id && value?.status_data?.name !== 'done' && (
              <Menucustom.Item key={'view-update-status'}>
                <Update style={{ marginRight: '5px' }} />
                อัปเดตข้อมูล
              </Menucustom.Item>
            )} */}
            {(deparmentData?.officer_data?.id === meData?.id && checkStatus) ||
            (filteredOrganize && checkStatus && deparmentData?.officer_data?.officer_id) ? (
              <Menucustom.Item key={'view-update-status'}>
                <Update style={{ marginRight: '5px' }} />
                อัปเดตข้อมูล
              </Menucustom.Item>
            ) : (
              ''
            )}
            <Menucustom.Item key={'export'}>
              <Export style={{ marginRight: '5px' }} />
              ออกใบงาน
            </Menucustom.Item>
          </>
        )}
      </Menucustom>
    )
  }

  const getMasterData = async () => {
    const resCategories = await getList({
      schema: '/master/api/v1/all/eservice',
      params: '',
    })
    const resStatus = await getList({
      schema: '/master/api/v1/all/status',
      params: '',
    })
    const resUrgency = await getList({
      schema: '/master/api/v1/all/urgency',
      params: '',
    })
    const resChannel = await getList({
      schema: '/master/api/v1/all/channel',
      params: '',
    })
    setMasterData({
      type_of_complaint: resCategories?.items,
      status: resStatus?.items,
      urgency: resUrgency?.items,
      channel: resChannel?.items,
    })
  }

  const columnsDataTable = [
    {
      title: '',
      width: '50px',
      render: (_, data) => {
        return (
          <DropdownCustom overlay={() => menu(data)} trigger={['click']} getPopupContainer={getPopupContainer}>
            <MoreOutlined style={{ cursor: 'pointer', fontSize: '26px' }} />
          </DropdownCustom>
        )
      },
    },
    {
      title: 'ลำดับ',
      dataIndex: 'no',
      width: '70px',
      render: text => <div style={{ textAlign: 'center' }}>{text}.</div>,
    },
    {
      title: 'บริการสาธารณะ',
      dataIndex: 'title_complaint',
      render: text => (
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100px' }}>{text}</div>
      ),
    },
    {
      title: 'เลขที่บริการสาธารณะ',
      dataIndex: 'complaint_no',
      render: (text, data) =>
        text ? (
          <div style={{ display: 'grid', gridTemplateColumns: '10px 1fr', alignItems: 'center', gap: '8px' }}>
            <div>{data?.is_group ? <File /> : ''}</div>
            <div>{text}</div>
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: 'ประเภทบริการสาธารณะ',
      dataIndex: 'type_of_complaint',
      render: (text, data) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {data?.type_of_complaint?.icon_url && (
            <img src={data?.type_of_complaint?.icon_url} style={{ width: '14px', height: '14px' }} />
          )}
          {data?.type_of_complaint?.name ? data?.type_of_complaint?.name : '-'}
        </div>
      ),
    },
    {
      title: 'ผู้รับผิดชอบ',
      dataIndex: 'sub_category_name',
      render: (text, data) => {
        const staffData = data?.department_data
        return (
          <div className={staffData?.officer_data?.id === meData?.id ? 'responsible-me' : 'responsible-other'}>
            {staffData?.officer_data !== null
              ? `${staffData?.officer_data?.first_name} ${staffData?.officer_data?.last_name}`
              : '-'}
          </div>
        )
      },
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: sortDate ? '#F36B24' : '#717171' }}>
          วันที่แจ้งเรื่อง
          {sortDate ? (
            <Arrow_Active style={{ cursor: 'pointer' }} onClick={() => setSortDate(!sortDate)} />
          ) : (
            <Arrow style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => setSortDate(!sortDate)} />
          )}
        </div>
      ),
      render: (text, data) => <div>{conversDate(data?.created_at)}</div>,
    },

    {
      title: 'สถานะการให้บริการ',
      render: (text, data) =>
        data?.status_data ? (
          <span
            style={{
              width: 'auto',
              height: '24px',
              background: checkColorStatusBg(data?.status_data?.name),
              borderRadius: '4px',
              textAlign: 'center',
              padding: '6px',
              color: checkColorStatusText(data?.status_data?.name),
            }}
          >
            {data?.status_data.name ? checkStatusText(data?.status_data?.name) : '-'}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'ช่องทาง',
      dataIndex: 'channel',
      render: data => <>{data ? data ? data : '-' : <span>-</span>}</>,
      ellipsis: true,
    },
    {
      title: 'ผู้ขอรับบริการ',
      render: (text, data) => (
        <div style={{ color: '#717171', fontSize: '12px', fontWeight: '400' }}>
          {data?.name?.first_name} {data?.name?.last_name}
        </div>
      ),
    },
  ]

  const showDrawerAddOfficer = (e, value) => {
    setDeparmentData(value?.department_data)
    setComplaintId(value?.id)
    setOpenDrawerAddOfficer(true)
    setTimeout(() => {
      setLoadingAddOfficer(false)
    }, 1000)
  }
  const onCloseAddOfficer = () => {
    setOpenDrawerAddOfficer(false)
    setDeparmentData([])
    setLoadingAddOfficer(true)
  }

  const showDrawerEditOfficer = (e, value) => {
    setDeparmentData(value?.department_data)
    setComplaintId(value?.id)
    setOpenDrawerEditOfficer(true)
    setTimeout(() => {
      setLoadingAddOfficer(false)
    }, 1000)
  }
  const onCloseEditOfficer = () => {
    setOpenDrawerEditOfficer(false)
    setDeparmentData([])
    setLoadingAddOfficer(true)
  }

  const openNotification = typeNoti => {
    notification.info({
      message: (
        <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>
          {typeNoti === 'transfer'
            ? 'โอนย้ายเรื่องร้องเรียนสำเร็จ'
            : typeNoti === 'editofficer'
            ? 'แก้ไขข้อมูลเจ้าหน้าที่แล้ว'
            : 'เพิ่มข้อมูลเจ้าหน้าที่แล้ว'}
        </div>
      ),
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 3,
    })
  }

  const openNotificationCreate = typeNoti => {
    notification.info({
      message: (
        <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>ส่งเรื่องร้องเรียนสำเร็จ</div>
      ),
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 3,
    })
  }

  const onRowClick = (record, event) => {
    if (event.target.tagName !== 'svg') {
      navigate('view', {
        state: {
          record: record?.id,
          group: record?.is_group,
        },
      })
    }
  }

  return (
    <>
      <BoxMap>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginRight: '5%',
            position: 'absolute',
            top: '2%',
            right: '0%',
          }}
        >
          {meData?.position_data?.id !== '1730459515121299456' && (
            <Button onClick={() => navigate('/e-service/bof-eservice/create-e-service')}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                <AddComplant /> เพิ่มเรื่องขอรับบริการ
              </div>
            </Button>
          )}
        </div>

        <BoxTable>
          <div>
            <Search
              masterData={masterData}
              setSearchData={setSearchData}
              searchData={searchData}
              conversDate={conversDate}
            />
            {loadingTable ? (
              <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
                <Spin />
              </div>
            ) : (
              <>
                <div>
                  <div
                    style={{
                      height: '75vh',
                      width: '100%',
                      paddingRight: '1%',
                      overflow: 'auto',
                    }}
                  >
                    <TableCustom
                      columns={columnsDataTable}
                      dataSource={dataList}
                      noDataText="ไม่พบข้อมูลที่ต้องการค้นหา"
                      widthDisplay={window.innerWidth}
                      onRow={onRowClick}
                      scroll={{
                        x: 1000,
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                      <PaginationCustom
                        page={curantPage}
                        setPage={setCurantPage}
                        dataLength={dataLength}
                        pageSize={13}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </BoxTable>
        <div style={{ display: isPrint ? 'block' : 'none', position: 'absolute', bottom: '-100%' }}>
          <ExportComplante componentRef={componentRef} data={dataExport} />
        </div>
      </BoxMap>

      {/* Drawer add officer */}
      <AddOfficer
        openDrawerAddOfficer={openDrawerAddOfficer}
        setLeaveModalOpen={setLeaveModalOpen}
        loadingAddOfficer={loadingAddOfficer}
        deparmentData={deparmentData}
        organizeId={organizeId}
        complaintId={complaintId}
        listAdmin={listAdmin}
        // setSubmitAddModal={setSubmitAddModal}
        onCloseAddOfficer={onCloseAddOfficer}
        openNotification={openNotification}
        getDataEservice={getDataEservice}
      />
      {/* Drawer edit officer */}
      <EditOfficer
        openDrawerEditOfficer={openDrawerEditOfficer}
        setLeaveModalOpen={setLeaveModalOpen}
        loadingAddOfficer={loadingAddOfficer}
        deparmentData={deparmentData}
        organizeId={organizeId}
        complaintId={complaintId}
        listAdmin={listAdmin}
        // setSubmitAddModal={setSubmitAddModal}
        onCloseEditOfficer={onCloseEditOfficer}
        openNotification={openNotification}
        getDataEservice={getDataEservice}
      />

      {/* Modal Confirm Leave*/}
      <CustomModal
        transitionName=""
        centered
        open={leaveModalOpen}
        onOk={() => {
          setLeaveModalOpen(false)
          onCloseAddOfficer()
          onCloseEditOfficer()
        }}
        onCancel={() => setLeaveModalOpen(false)}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
        zIndex={1200}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
        <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
      </CustomModal>
    </>
  )
}

export default Index
