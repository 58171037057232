import React, { useState, useEffect } from 'react'
import {
  CustomDrawer,
  BtSubmit,
  LabelText,
  BoxLabel,
  StyleDropdown,
  CustomModal,
} from './view/ViewPc/view_drawer.style'
import DropdownForm from 'components/Inputs/DropdownForm'
import { Spin } from 'antd'

import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-drawer.svg'
import { ReactComponent as IconTitleAddOfficer } from 'assets/images/svg/icon-title-add-officer.svg'

import { ReactComponent as IconGear } from 'assets/images/svg/department/gear-icon.svg'
import { ReactComponent as IconMoney } from 'assets/images/svg/department/money.svg'
import { ReactComponent as IconEngineer } from 'assets/images/svg/department/engineer.svg'
import { ReactComponent as IconStudy } from 'assets/images/svg/department/study.svg'
import { ReactComponent as IconHuman } from 'assets/images/svg/department/human.svg'
import { ReactComponent as IconStaff } from 'assets/images/svg/department/staff.svg'
import { ReactComponent as IconHospital } from 'assets/images/svg/department/hospital.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getData, putData } from 'core/action/collection'
import { complaint } from 'core/schemas'

const EditOfficer = ({
  openDrawerEditOfficer,
  setLeaveModalOpen,
  loadingAddOfficer,
  deparmentData,
  organizeId,
  listAdmin,
  onCloseEditOfficer,
  openNotification,
}) => {
  const dePartMentIcon = {
    สำนักช่าง: <IconGear />,
    สำนักคลัง: <IconMoney />,

    สำนักการศึกษา: <IconStudy />,
    กองสวัสดิการสังคม: <IconHuman />,
    กองการเจ้าหน้าที่: <IconMoney />,
    สำนักงานปลัดเทศบาล: <IconStaff />,
    กองยุทธศาสตร์และงบประมาณ: <IconMoney />,
    สำนักสาธารณสุขและสิ่งแวดล้อม: <IconHospital />,
  }

  const [openModal, setOpenModal] = useState(false)

  const selectedDepartment = deparmentData?.find(item => item?.id === organizeId)

  const userId = selectedDepartment?.officer_data?.id

  const officerId = selectedDepartment?.officer_data?.officer_id

  const filteredUsers = listAdmin.filter(user => user.organize_data.org_id === selectedDepartment?.id)

  const optionsUser = filteredUsers.map(user => ({
    label: `${user.first_name.th} ${user.last_name.th}`,
    value: user.id,
  }))

  const Schema = Yup.object().shape({
    staff_by: Yup.string().required(''),
  })

  const formik = useFormik({
    initialValues: { staff_by: userId ? userId : '' },
    validationSchema: Schema,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  const isValidate = formik.dirty && formik.isValid

  const onChangeDropdwon = (id, value) => {
    formik.setFieldValue('staff_by', value)
  }

  const onSubmit = async () => {
    const payload = {
      department_id: selectedDepartment.id,
      staff_by: formik.values.staff_by,
    }

    const res = await putData(`${complaint}/officer`, officerId, payload)

    if (res) {
      if (res?.code === 200) {
        setOpenModal(false)
        formik.resetForm()
        onCloseEditOfficer()
        openNotification('แก้ไขข้อมูลเจ้าหน้าที่แล้ว')
      } else {
        console.log('Err', res)
        setOpenModal(false)
      }
    }
  }

  const showModal = () => {
    setOpenModal(true)
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <CustomDrawer
        placement="right"
        open={openDrawerEditOfficer}
        onClose={() => setLeaveModalOpen(true)}
        closable={false}
        width={600}
        key={openDrawerEditOfficer ? 'open' : 'close'}
      >
        {loadingAddOfficer ? (
          <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
            <Spin />
          </div>
        ) : (
          <>
            <div style={{ height: '6.5em', position: 'relative' }}>
              <div className="close-icon" onClick={() => setLeaveModalOpen(true)}>
                <IconClear />
              </div>
            </div>
            <div className="wrap-body">
              <div className="wrap-title">
                <IconTitleAddOfficer style={{ marginRight: '16px' }} />
                <div className="wrap-box-title">
                  <div className="title">แก้ไขข้อมูลเจ้าหน้าที่</div>
                  <div className="description">กรุณาเลือกผู้รับผิดชอบใหม่</div>
                </div>
              </div>

              <div style={{ marginBottom: '1rem' }}>
                <LabelText>
                  <BoxLabel>
                    <div style={{ marginRight: '8px' }}>{dePartMentIcon[selectedDepartment?.name]}</div>
                    <div>{selectedDepartment?.name}</div>
                  </BoxLabel>
                </LabelText>
                <StyleDropdown>
                  <DropdownForm
                    title="เจ้าหน้าที่"
                    placeholder="กรุณาเลือก"
                    showSearch={false}
                    required={true}
                    onChange={value => onChangeDropdwon(selectedDepartment?.id, value)}
                    className="big-input"
                    options={optionsUser}
                    value={formik.values.staff_by}
                  />
                </StyleDropdown>
              </div>

              <div
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '4em',
                  marginBottom: '4em',
                }}
              >
                <BtSubmit disabled={!isValidate} onClick={() => showModal()}>
                  ยืนยัน
                </BtSubmit>
              </div>
            </div>
          </>
        )}
      </CustomDrawer>

      <CustomModal
        transitionName=""
        centered
        open={openModal}
        onOk={() => onSubmit()}
        onCancel={() => onCloseModal()}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
        zIndex={1200}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">ยืนยันการแก้ไขข้อมูลเจ้าหน้าที่</p>
        <p className="modal-message">หากคุณแก้ไขข้อมูลนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้</p>
      </CustomModal>
    </>
  )
}

export default EditOfficer
