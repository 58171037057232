import ImgDefault from 'assets/images/png/defaultUnSelected.png'

export const imageError = ev => {
  ev.target.src = ImgDefault
}

export const chanelData = {
  ไลน์: 'ไลน์',
  lineOA: 'lineOA',
  1132: '1132',
  'damrongtham center': 'ศูนย์ดำรงธรรม',
  ศูนย์ดำรงธรรม: 'ศูนย์ดำรงธรรม',
  'traffy fondue': 'Traffy Fondue',
  เว็บไซต์: 'เว็บไซต์',
  เว็บบอร์ด: 'เว็บบอร์ด',
  'walk in': 'Walk in',
  facebook: 'Facebook',
  other: 'อื่นๆ',
}
