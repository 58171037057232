import React, { useState, memo } from 'react'
import { StyledInputChat } from '../chatbot.style'
import SendMessage from 'assets/images/svg/send-message-chatbot.svg'

const InputChat = ({ handleEnter, sendmessage }) => {
  const [inputValue, setInputValue] = useState('')
  const SuffixIcons = e => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={SendMessage}
        alt="Sticker"
        style={{ marginLeft: 8, cursor: 'pointer', width: '20px', height: '20px' }}
        onClick={e => {
          sendmessage(inputValue)
          setInputValue('')
        }}
      />
    </div>
  )
  return (
    <div>
      <StyledInputChat
        value={inputValue}
        onPressEnter={e => {
          handleEnter(e.target.value)
          setInputValue('')
        }}
        placeholder="Aa"
        onChange={e => setInputValue(e.target.value)}
        suffix={<SuffixIcons />}
      />
    </div>
  )
}
export default memo(InputChat)
