import styled, { createGlobalStyle } from 'styled-components'
import { Button, Space, Modal, Menu, Drawer, Checkbox } from 'antd'

export const BoxSearch = styled.div`
  width: 100%;
  overflow-x: auto;
  .ant-input-affix-wrapper-focused {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #f36b24;
    border-right-width: 1px;
    z-index: 1;
  }
  .ant-input-affix-wrapper {
    border-color: #ffeee7;
    border-radius: 8px;
    height: 40px;
    min-width: 150px !important;
  }
  .ant-input::placeholder {
    color: #ffc4aa !important;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: #f36b24 !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #ffeee7;
    color: #f36b24 !important;
    height: 40px;
    border-radius: 8px;
  }

  .ant-picker {
    background: #ffffff !important;
    border: 1px solid #ffeee7 !important;
    border-radius: 8px !important;
    height: 40px !important;
    width: 100% !important;
    color: #ffc4aa !important;
  }
  .ant-picker-input > input {
    color: #ffc4aa !important;
  }
  .ant-picker-input::placeholder > input {
    color: #ffc4aa !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #ffc4aa;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow {
    color: #f36b24;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 5px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px 10px;
    background: none !important;

    .ant-input-affix-wrapper {
      min-width: 200px !important;
    }
  }

  @media only screen and (max-width: 375px) {
    padding: 10px 10px;
    background: none !important;
    .ant-input-affix-wrapper {
      min-width: 200px !important;
    }
  }
`
export const BoxTable = styled.div`
  width: 100%;
  transition: 1s;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1.2%;
  position: relative;
  height: ${props => (props.isFullTable ? '100vh' : '60vh')};

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #f36b24 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f36b24;
    border-color: #f36b24;
  }
  .ant-checkbox-inner:after {
    top: 45% !important;
    left: 15.5% !important;
  }
  .ant-checkbox-indeterminate {
    top: 50%;
    left: 50%;
    background-color: #f36b24;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    display: none !important;
  }
  .selected-row {
    background-color: #ffeee7;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #ffeee7;
    border-color: #f36b24;
  }
  .color-row-new-data {
    background-color: #e8f2f9 !important;
  }
  .color-row-new-data td {
    border-top: 1px solid #f6fcff;
    border-bottom: 1px solid #f6fcff;
  }
  .color-row-new-data td:first-child {
    border-left: 1px solid #f6fcff;
  }
  .color-row-new-data td:last-child {
    border-right: 1px solid #f6fcff;
  }

  td {
    font-size: 12px;
  }
  .box-btn-full-table {
    width: 80px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 50%;
    cursor: pointer;
    background-color: #f9f9f9;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    justify-content: center;
    svg {
      transform: ${props => (props.isFullTable ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
  }
  @media only screen and (max-width: 768px) {
    overflow-x: auto !important;

    table {
      table-layout: auto !important;
      overflow-x: auto !important;
    }
  }

  @media only screen and (max-width: 375px) {
    overflow-x: auto !important;

    table {
      padding-bottom: 20px !important;
      table-layout: auto !important;
    }
  }
`
export const Menucustom = styled(Menu)`
  .ant-dropdown-menu-item:hover {
    background-color: #ffffff;
  }
  .ant-dropdown-menu-title-content:hover {
    background-color: #ffeee7;
    color: #f36b24;
    border-radius: 4px;
  }
`
export const BoxMap = styled.div`
  .gm-style .gm-style-iw-tc::after {
    display: none;
  }
  .gm-style-iw-d {
    overflow: hidden !important;
  }
  .gm-style-iw-d {
    overflow: hidden !important;
  }
  .gm-style .gm-style-iw-c {
    padding: 0px;
    border-radius: 0px;
  }

  .gm-style iframe + div {
    border: none !important;
  }
  .gm-style .gm-style-iw-c {
    background: transparent !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
  button.gm-ui-hover-effect {
    visibility: hidden;
  }
  .box-info-detail {
    p {
      font-family: Prompt !important;
    }
  }

  .ant-btn {
    box-shadow: none;
    border-radius: 6px;
    /* color: #ffffff; */
    color: #f36b24;
    border: 1px solid #f36b24;
    background: #ffffff;
    /* background: #f36b24; */
  }
`

export const BoxBtn = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  .btn-cancel-group {
    background-color: #fff;
    border: 1px solid #f36b24;
    color: #f36b24;
    align-items: center;
    display: flex;
  }

  .btn-create-group {
    background-color: #f36b24;
    border: 1px solid #f36b24;
    color: #fff;
    align-items: center;
    display: flex;
  }
  .btn-create-group:disabled {
    background: #d9d9d9 !important;
    border-color: #d9d9d9 !important;
    color: #fff;
  }
`

export const CustomDrawer = styled(Drawer)`
  font-family: 'Prompt' !important;
  /* .ant-drawer-body {
    padding: 40px;
  } */

  .close-icon {
    position: absolute;
    right: 0px;
    cursor: pointer;
  }

  .wrap-body {
    padding: 0 16px;
    width: 100%;
    height: auto;
  }

  .wrap-title {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .wrap-box-title {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    color: #150f0b;
  }

  .description {
    color: #c4c4c4;
    font-weight: 300;
    font-size: 12px;
  }
`

export const LabelText = styled.div`
  font-family: 'Prompt';
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: #556a82;
  /* padding-bottom: 8px; */
  margin-bottom: 8px;
  /* width: auto; */
`

export const StyleDropdown = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #fee0d3;
    height: 40px;
    width: 100%;
    padding: 4px 11px;

    @media only screen and (max-width: 768px) {
      padding: 12px 16px;
    }

    @media only screen and (max-width: 375px) {
      padding: 12px 16px;
    }
  }

  .ant-select-disabled .ant-select-selector {
    border: 1px solid #fff3ee !important;
    background-color: #fff3ee !important;
    color: #fff3ee !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-disabled .ant-select-selector {
    border: 1px solid #fff3ee !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #fff3ee;
  }
`

export const BoxLabel = styled.div`
  box-shadow: 0px 0px 8px 0px rgba(46, 60, 79, 0.06);
  border-radius: 8px;
  display: flex;
  padding: 4px 8px;
  align-items: center;
`

export const BtSubmit = styled(Button)`
  background-color: #fc6521;
  border-color: #fc6521;
  /* background-color: ${props => (props.disabled ? 'transparent' : '#fc6521')}; */
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border-radius: 8px;
  width: 120px;
  height: 48px;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    background-color: #fc6521;
    border-color: #fc6521;
  }

  &.ant-btn[disabled] {
    background: rgba(252, 101, 33, 1) !important;
    color: #ffffff;
    opacity: 0.2;
  }
`

export const CustomModal = styled(Modal)`
  font-family: 'Prompt';
  z-index: 1400 !important;

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      color: #c4c4c4 !important;
      font-style: normal;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #DFF6F5;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }
    .ant-notification-notice-message {
      font-family: 'IBM Plex Sans Thai', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 8px;
      a {
        line-height: 18px;
      }
      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }
    .ant-notification-notice-close {
      padding-top: 5px;
    }
  }
`

export const CustomCheckBox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 4px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f36b24;
    border-color: #f36b24;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    /* left: 7px !important; */
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #f36b24 !important;
  }

  .ant-checkbox-checked:after {
    border: 1px solid #f36b24 !important;
  }

  .ant-checkbox .ant-checkbox-inner {
    border-color: #f36b24;
  }
`
