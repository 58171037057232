import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  position: relative;
  align-items: center;
  grid-template-columns: 2fr 180px;
  gap: 10px;
  .ant-pagination-item-active a {
    color: #fff;
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: #f36b24 !important;
    border-color: #f36b24 !important;
    border-radius: 4px;
  }
  .ant-pagination-item {
    border: none;
  }
  .ant-pagination-item:hover {
    border-color: #f36b24 !important;
    transition: all 0.3s;
  }
  .ant-pagination-item:hover a {
    color: #000 !important;
  }

  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    border: none;
  }

  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: #f36b24 !important;
    border-color: none !important;
  }
`
export const CustomText = styled.div`
  color: #48708e;
  font-weight: 400;
`
