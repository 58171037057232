import moment from 'moment'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'

export const checkColorStatusBg = val => {
  switch (val) {
    case 'received':
      return '#EFE9FE'
    case 'in progress':
      return '#FDF5DD'
    case 'done':
      return '#DFF6F5'
    case 'outside the area':
      return '#E2E5F8'
    case 'tracking':
      return '#FFE6DB'
    case 'over duedate':
      return '#FFE4E4'
    default:
      break
  }
}

export const checkColorStatusText = val => {
  switch (val) {
    case 'received':
      return '#915EFF'
    case 'in progress':
      return '#FFBB0B'
    case 'done':
      return '#13AFA6'
    case 'outside the area':
      return '#3F54D1'
    case 'tracking':
      return '#F36B24'
    case 'over duedate':
      return '#F85858'
    default:
      break
  }
}

export const checkColorStatusPoint = val => {
  switch (val) {
    case 'รับเรื่อง':
      return '#915EFF'
    case 'อยู่ระหว่างดำเนินการ':
      return '#FFBB0B'
    case 'ดำเนินการแล้วเสร็จ':
      return '#13AFA6'
    case 'ไม่อยู่ในความรับผิดชอบ':
      return '#3F54D1'
    case 'ติดตาม':
      return '#F36B24'
    case 'เกินระยะเวลาที่กำหนด':
      return '#F85858'
    default:
      break
  }
}

export const checkColorStatusLegendNewText = val => {
  switch (val) {
    case 'โทรศัพท์':
      return '#915EFF'
    case 'แอปพลิเคชัน':
      return '#21ADFC'
    case 'ไลน์':
      return '#915EFF'
    case 'เว็บบอร์ด':
      return '#79c3ee'
    case 'lineOA':
      return '#f0dc2e'
    case 'เว็บไซต์':
      return '#13AFA6'
    case 'เข้ามาโดยตรง':
      return '#9EBBD1'
    case 'facebook':
      return '#3F54D1'
    case 'E-Service':
      return '#F36B24'
    case 'หนังสือ':
      return '#FFA643'
    case '1132':
      return '#21ADFC'
    case 'ศูนย์ดำรงธรรม':
      return '#556A82'
    case 'traffy fondue':
      return '#F85858'
    case 'walk in':
      return '#9EBBD1'
    case 'อื่นๆ':
      return '#f208c3'
    case '1111':
      return '#f208c3'
    default:
      break
  }
}

export const checkColorStatusLegend = val => {
  switch (val) {
    case 'โทรศัพท์':
      return '#915EFF'
    case 'แอปพลิเคชัน':
      return '#21ADFC'
    case 'ไลน์':
      return '#915EFF'
    case 'เว็บไซต์':
      return '#13AFA6'
    case 'เข้ามาโดยตรง':
      return '#9EBBD1'
    case 'facebook':
      return '#3F54D1'
    case 'E-Service':
      return '#F36B24'
    case 'หนังสือ':
      return '#FFA643'
    case '1132':
      return '#21ADFC'
    case 'ศูนย์ดำรงธรรม':
      return '#556A82'
    case 'traffy fondue':
      return '#F85858'
    case 'walk in':
      return '#9EBBD1'
    case 'อื่นๆ':
      return '#f208c3'

    default:
      break
  }
}

export const checkRating = val => {
  switch (val) {
    case 1:
      return 'ควรปรับปรุง'
    case 2:
      return 'พอใช้'
    case 3:
      return 'ดี'
    case 4:
      return 'ดีมาก'
    case 5:
      return 'ยอดเยี่ยม'
    default:
      break
  }
}

export const checkDueDate = val => {
  let currant = moment().unix() * 1000
  let due = val * 1000
  const date1 = moment(due)
  const date2 = moment(currant)
  const diffInDays = date1.diff(date2, 'days') + 1
  const formattedDate1 = date1.format('DD/MM/YYYY')
  if (diffInDays > 0 && diffInDays <= 3) {
    return 'เหลืออีก ' + diffInDays + ' วัน'
  } else {
    const dateMoment = moment(formattedDate1, 'DD/MM/YYYY')
    const unixTimestamp = dateMoment.valueOf() / 1000 // Convert milliseconds to seconds
    return conversDate(unixTimestamp)
  }
}
