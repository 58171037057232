import React, { useRef, useEffect, useState, useContext } from 'react'
import { Button, Dropdown, Pagination, Spin, Tooltip, notification } from 'antd'
import TableCustom from 'components/TableCustom/Row'
import PaginationCustom from 'components/PaginationCustom'
import { BoxTable, Menucustom, BoxMap, CustomModal, NotiStyle, BoxBtn } from './list.style'
import Search from './search'
import { ReactComponent as Ungroup } from '../image/svg/Ungroup file.svg'
import axios from 'axios'
import { useReactToPrint } from 'react-to-print'
import ExportComplante from '../export'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as File } from '../image/svg/File.svg'
import { ReactComponent as View } from '../image/svg/View.svg'
import { ReactComponent as Export } from '../image/svg/Export.svg'
import { ReactComponent as Update } from '../image/svg/Update.svg'
import { ReactComponent as Officer } from 'assets/images/svg/Officer.svg'
import { ReactComponent as Edit } from '../image/svg/Edit.svg'
import { ReactComponent as Arrow } from '../image/svg/Arrow.svg'
import { ReactComponent as Arrow_Active } from '../image/svg/Arrow_Active.svg'
import { ReactComponent as Pin } from '../image/svg/Pin 32 px.svg'
import { ReactComponent as AddComplant } from '../image/svg/AddComplant.svg'
import { ReactComponent as Delete } from '../image/svg/Delete.svg'
import { ReactComponent as Transfer } from '../image/svg/Transfer.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import IconNoti from 'assets/images/svg/icon-noti.svg'
import IconClose from 'assets/images/svg/icon-close.svg'
import { MoreOutlined } from '@ant-design/icons'
import { checkColorStatusBg, checkColorStatusText, checkRating, checkDueDate, checkStatusText } from '../container'
import { ReactComponent as Group } from '../image/svg/Group.svg'
import { ReactComponent as FileGroup } from '../image/svg/Component Group.svg'
import { getData, getList } from 'core/action/collection'
import UserContext from 'core/contexts/userContext'
import moment from 'moment'
import { adminauth2 } from 'core/schemas'
import { Polygon } from '@react-google-maps/api'
import AddOfficer from '../AddOfficer'
import EditOfficer from '../EditOfficer'
import TransferComplaint from '../TransferComplaint'
import DeleteComplant from '../deleteComplant'
const DropdownCustom = Dropdown
const Index = () => {
  const organizeId = localStorage.getItem('organize')
  const location = useLocation()
  const { meData } = useContext(UserContext)
  const componentRef = useRef()
  const navigate = useNavigate()
  const [isFullTable, setIsFullTable] = useState(false)
  const [dataList, setDataList] = useState([])
  const [masterData, setMasterData] = useState([])
  const [searchData, setSearchData] = useState({
    date: [moment().subtract(30, 'days'), moment()],
  })
  const [dataExport, setdataExport] = useState([])
  const [isPrint, setIsPrint] = useState(false)
  const [curantPage, setCurantPage] = useState(1)
  const [dataLength, setdataLength] = useState(0)
  const [loadingTable, setLoadingTable] = useState(true)
  const [sortDate, setSortDate] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [openDrawerAddOfficer, setOpenDrawerAddOfficer] = useState(false)
  const [deparmentData, setDeparmentData] = useState([])
  const [loadingAddOfficer, setLoadingAddOfficer] = useState(true)

  const [openDrawerEditOfficer, setOpenDrawerEditOfficer] = useState(false)
  const [leaveModalOpen, setLeaveModalOpen] = useState(false)
  const [submitAddModal, setSubmitAddModal] = useState(false)
  const [typeDrawer, setTypeDrawer] = useState('')
  const [listAdmin, setListAdmin] = useState([])
  const [openDrawerTransfer, setOpenDrawerTransfer] = useState(false)
  const [complaintId, setComplaintId] = useState('')
  const isSizeMobile = 768
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isGroup, setIsGroup] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [arrId, setArrId] = useState([])
  const [arrGroup, setArrGroup] = useState([])
  const [isDeleteCompalnt, setIsDeleteCompalnt] = useState({
    Modal: false,
    id: '',
  })

  const [modalHeadGroup, setModalHeadGroup] = useState({
    modal: false,
  })

  const token = localStorage?.getItem('access_token')

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    setLoadingTable(true)
    getListAllAdmin()
    getMasterData()
  }, [])

  useEffect(() => {
    if (localStorage.getItem('isCreateComplaint')) {
      openNotificationCreate()
      setTimeout(() => {
        localStorage.removeItem('isCreateComplaint')
      }, 5000)
    }
  }, [])

  useEffect(() => {
    if (!location?.state?.ugency) {
      localStorage?.removeItem('ugency-dashboard')
    }
    if (location?.state?.code) {
      openNotification('ส่งเรื่องร้องเรียนสำเร็จ')
    } else if (localStorage.getItem('isCreateGroup')) {
      openNotification('สร้างกลุ่มการร้องเรียนเสร็จสิ้น')
      setTimeout(() => {
        localStorage.removeItem('isCreateGroup')
      }, 5000)
    } else if (localStorage.getItem('isDeleteSingle')) {
      openNotification('ลบข้อมูลเรื่องร้องเรียนสำเร็จ')
      setTimeout(() => {
        localStorage.removeItem('isDeleteSingle')
      }, 5000)
    } else if (localStorage.getItem('isExpandGroup')) {
      openNotification('แตกกลุ่มข้อมูล​เรื่องร้องเรียนสำเร็จ')
      setTimeout(() => {
        localStorage.removeItem('isExpandGroup')
      }, 5000)
    } else if (localStorage.getItem('isDeleteGroup')) {
      openNotification('ลบกลุ่มเรื่องร้องเรียนสำเร็จ')
      setTimeout(() => {
        localStorage.removeItem('isDeleteGroup')
      }, 5000)
    } else if (localStorage.getItem('isEditComplant')) {
      openNotification('แก้ไขข้อมูลสำเร็จแล้ว')
      setTimeout(() => {
        localStorage.removeItem('isEditComplant')
      }, 5000)
    }
  }, [location])

  useEffect(() => {
    if (!searchData?.search) {
      setLoadingTable(true)
    }
    setCurantPage(1)
    if (curantPage === 1) {
      getDataComplant()
    }
  }, [searchData, isFullTable, sortDate])

  useEffect(() => {
    setLoadingTable(true)
    getDataComplant()
  }, [curantPage, isGroup])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setIsPrint(false)
    },
    documentTitle: `ใบงานเรื่องร้องเรียน_${
      dataExport?.sub_category_name ? dataExport?.sub_category_name : ''
    }_${moment().add(543, 'year').format('DD-MM-YYYY')}.pdf`,
  })

  const handleMenuList = (e, data) => {
    const key = e?.key.split('/')
    if (key[0] === 'view' || key[0] === 'view-group') {
      navigate(e.key, {
        state: {
          record: data?.id,
          group: data?.is_group,
        },
      })
    } else if (key[0] === 'update') {
      navigate(e.key, {
        state: {
          record: '',
          isEdit: true,
        },
      })
    } else if (key[0] === 'view-update-status') {
      navigate(e.key, {
        state: {
          record: data?.id,
          isEdit: true,
          path: 'previous-list',
        },
      })
    } else if (key[0] === 'delete-group') {
      setIsDeleteCompalnt({
        Modal: true,
        id: data?.id,
        isGroup: data?.is_group,
      })
    } else if (key[0] === 'delete') {
      setIsDeleteCompalnt({
        Modal: true,
        id: data?.id,
      })
    } else if (key[0] === 'export') {
      setIsPrint(true)
      setdataExport(data)
      setTimeout(() => {
        handlePrint(data)
      }, 2000)
    }
  }

  const getDataComplant = async () => {
    const res = await getList({
      schema: '/complaint/api/v1/complaint',
      params: {
        page: curantPage,
        per_page: 13,
        sort: [sortDate ? `created_at:asc` : `created_at:desc`],
        filters: [
          searchData?.category ? `category_id:eq:${searchData?.category}` : undefined,
          isGroup && !searchData?.status
            ? [`status_data.name:inLast:received|in progress|tracking|over duedate`]
            : searchData?.status
            ? `status_data.name:inLast:${searchData?.status}`
            : localStorage.getItem('ugency-dashboard')
            ? `status_data.name:inLast:over duedate`
            : undefined,
          searchData?.channel ? `channel:eq:${searchData?.channel}` : undefined,
          searchData?.urgency ? `urgency_id:eq:${searchData?.urgency}` : 'urgency_id:isNotNull',
          searchData?.satisfaction ? `rating.rating:eqInt:${searchData?.satisfaction}` : undefined,
          searchData?.date
            ? `created_at:between:${moment(searchData?.date[0]).startOf('day').unix()}|${moment(searchData?.date[1])
                .endOf('day')
                .unix()}`
            : undefined,
          meData?.position_data?.id === '1730459515154853888'
            ? `department_data:eq:${meData?.organize_data?.org_id}`
            : `officer_data.staff_by:eq:${meData?.id}`,
          meData?.position_data?.id !== '1730459515154853888'
            ? `department_data:eq:${meData?.organize_data?.org_id}`
            : undefined,
          ,
        ],
        search: [
          searchData?.search ? `complaint_name:${searchData?.search}` : undefined,
          searchData?.search ? `complaint_no:${searchData?.search}` : undefined,
          searchData?.search ? `mobile_number:${searchData?.search}` : undefined,
          searchData?.search ? `name.first_name:${searchData?.search}` : undefined,
          searchData?.search ? `name.last_name:${searchData?.search}` : undefined,
          ////เพื่มใหม่
          searchData?.search ? `group_data.complaint_data.complaint_no:${searchData?.search}` : undefined,
          searchData?.search ? `group_data.complaint_data.mobile_number:${searchData?.search}` : undefined,
          searchData?.search ? `group_data.complaint_data.name.fist_name:${searchData?.search}` : undefined,
          searchData?.search ? `group_data.complaint_data.name.last_name:${searchData?.search}` : undefined,
          searchData?.search ? `group_data.complaint_data.complaint_name:${searchData?.search}` : undefined,
        ],
      },
    })
    if (res?.code === 200) {
      setTimeout(() => {
        setDataList(res?.items)
        setdataLength(res?.dataLength ? res?.dataLength : 0)
        setLoadingTable(false)
      }, 500)
    } else {
      setDataList([])
      setdataLength(0)
      setLoadingTable(false)
    }
  }

  const menu = value => {
    const filteredOrganize = value?.department_data
      ? Array.isArray(value?.department_data)
        ? value?.department_data?.filter(item => item?.id === organizeId)
        : value?.department_data
      : ''
    const deparmentData = filteredOrganize
      ? Array.isArray(filteredOrganize)
        ? filteredOrganize[0]
        : filteredOrganize
      : ''
    return (
      <Menucustom onClick={e => handleMenuList(e, value)}>
        <Menucustom.Item key={`view`}>
          <View style={{ marginRight: '5px' }} />
          ดูข้อมูล
        </Menucustom.Item>

        {value?.status_data[value?.status_data?.length - 1]?.name !== 'done' ? (
          deparmentData?.officer_data?.officer_id ? (
            <Menucustom.Item onClick={e => showDrawerEditOfficer(e, value, 'editOfficer')}>
              <Edit style={{ marginRight: '5px' }} />
              โอนย้าย/แก้ไขผู้รับผิดชอบ
            </Menucustom.Item>
          ) : (
            <Menucustom.Item onClick={e => showDrawerAddOfficer(e, value, 'addOfficer')}>
              <Officer style={{ marginRight: '5px' }} />
              เพิ่มผู้รับผิดชอบ
            </Menucustom.Item>
          )
        ) : (
          ''
        )}
        {(deparmentData?.officer_data?.id === meData?.id &&
          value?.status_data[value?.status_data?.length - 1]?.name !== 'done') ||
        (filteredOrganize &&
          value?.status_data[value?.status_data?.length - 1]?.name !== 'done' &&
          deparmentData?.officer_data?.officer_id) ? (
          <Menucustom.Item key={'view-update-status'}>
            <Update style={{ marginRight: '5px' }} />
            อัปเดตข้อมูล
          </Menucustom.Item>
        ) : (
          ''
        )}
        {value?.status_data[value?.status_data?.length - 1]?.name === 'received' &&
          !value?.is_group &&
          meData?.position_data?.id === '1730459515154853888' && (
            <Menucustom.Item onClick={e => showDrawerTransfer(e, value)}>
              <Transfer style={{ marginRight: '5px' }} />
              โอนย้าย
            </Menucustom.Item>
          )}
        {value?.is_group && meData?.position_data?.id === '1730459515154853888' && (
          <Menucustom.Item key={`/out-of-group`} onClick={e => handleAllOutOfGroup(e, value)}>
            <Ungroup style={{ marginRight: '5px' }} />
            แตกกลุ่ม
          </Menucustom.Item>
        )}
        <Menucustom.Item key={'export'}>
          <Export style={{ marginRight: '5px' }} />
          ออกใบงาน
        </Menucustom.Item>
      </Menucustom>
    )
  }
  const getMasterData = async () => {
    const resCategories = await getList({
      schema: '/master/api/v1/all/categories',
      params: '',
    })
    const resStatus = await getList({
      schema: '/master/api/v1/all/status',
      params: '',
    })
    const resUrgency = await getList({
      schema: '/master/api/v1/all/urgency',
      params: '',
    })
    const resChannel = await getList({
      schema: '/master/api/v1/all/channel',
      params: '',
    })
    setMasterData({
      categories: resCategories?.items,
      status: resStatus?.items,
      urgency: resUrgency?.items,
      channel: resChannel?.items,
    })
  }

  const handleClickGroup = () => {
    setIsGroup(true)
    setIsFullTable(true)
    setCurantPage(1)
  }

  const handleCancelCreateGroup = () => {
    setSelectedRowKeys([])
    setArrId([])
    setArrId([])
    setIsGroup(false)
    setIsFullTable(false)
  }

  const columnsDataTable = [
    {
      title: '',
      fixed: 'left',
      width: '40px',
      render: (_, data) => {
        return isGroup ? null : (
          <DropdownCustom overlay={() => menu(data)} trigger={['click']} zIndex={1000}>
            <MoreOutlined style={{ cursor: 'pointer', fontSize: '26px' }} />
          </DropdownCustom>
        )
      },
    },
    {
      title: 'ลำดับ',
      dataIndex: 'no',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      width: '70px',
      render: text => <div style={{ textAlign: 'center' }}>{text}.</div>,
    },
    {
      title: 'เรื่องที่ร้องเรียน',
      dataIndex: 'complaint_name',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: text => (
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100px' }}>{text}</div>
      ),
    },
    {
      title: 'เลขที่เรื่องร้องเรียน',
      dataIndex: 'complaint_no',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) =>
        text ? (
          <div style={{ display: 'grid', gridTemplateColumns: '10px 1fr', alignItems: 'center', gap: '8px' }}>
            <div>{data?.is_group ? <File /> : ''}</div>
            <div>{text}</div>
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: 'ประเภทเรื่องร้องเรียน',
      dataIndex: 'category',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {data?.category?.icon_url && <img src={data?.category?.icon_url} style={{ width: '14px', height: '14px' }} />}
          {data?.category?.name ? data?.category?.name : '-'}
        </div>
      ),
    },
    {
      title: 'ประเภทย่อย',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => <div>{data?.sub_category_data?.name ? data?.sub_category_data?.name : '-'}</div>,
    },

    {
      title: 'ผู้รับผิดชอบ',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => {
        const officerData = Array.isArray(data?.department_data)
          ? data?.department_data?.find(department => department?.id === organizeId)
          : data?.department_data?.id === organizeId
        const staffData = officerData?.officer_data
        return (
          <div className={staffData?.id === meData?.id ? 'responsible-me' : 'responsible-other'}>
            {staffData ? `${staffData?.first_name} ${staffData?.last_name}` : '-'}
          </div>
        )
      },
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: '#717171' }}>สถานะความเร่งด่วน</div>
      ),
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) =>
        data?.urgency?.name ? (
          <span
            style={{
              backgroundColor: data?.urgency?.name === 'ทำทันที' ? '#F85858' : '#F3F3F3',
              borderRadius: '10px',
              color:
                data?.urgency?.name === 'ปกติ'
                  ? '#13AFA6'
                  : data?.urgency?.name === 'ด่วน'
                  ? '#FFA643'
                  : data?.urgency?.name === 'ด่วนมาก'
                  ? '#EC82F8'
                  : data?.urgency?.name === 'ด่วนที่สุด'
                  ? '#F36B24'
                  : '#FFFFFF',
              padding: '10px 20px',
              height: '40px',
            }}
          >
            {data?.urgency?.name}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'ช่องทาง',
      dataIndex: 'channel',
      render: data => <>{data ? data ? data : '-' : <span>-</span>}</>,
      ellipsis: true,
    },
    {
      title: 'ความพึงพอใจ',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => <div>{checkRating(data?.rating) ? checkRating(data?.rating) : 'รอการยืนยัน'}</div>,
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: sortDate ? '#F36B24' : '#717171' }}>
          วันที่แจ้งเรื่อง
          {sortDate ? (
            <Arrow_Active
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSortDate(!sortDate)
              }}
            />
          ) : (
            <Arrow
              style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
              onClick={() => {
                setSortDate(!sortDate)
              }}
            />
          )}
        </div>
      ),
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => <div>{conversDate(data?.created_at)}</div>,
    },
    {
      title: 'วันที่กำหนดเสร็จ',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => <div>{data?.duedate_at ? checkDueDate(data?.duedate_at) : '-'}</div>,
    },
    {
      title: 'สถานะการร้องเรียน',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) =>
        data?.status_data?.length ? (
          <span
            style={{
              width: 'auto',
              height: '24px',
              background: checkColorStatusBg(data?.status_data[data?.status_data?.length - 1]?.name),
              borderRadius: '4px',
              textAlign: 'center',
              padding: '6px',
              color: checkColorStatusText(data?.status_data[data?.status_data?.length - 1]?.name),
            }}
          >
            {data?.status_data[data?.status_data?.length - 1]?.name
              ? checkStatusText(data?.status_data[data?.status_data?.length - 1]?.name)
              : '-'}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'ผู้ร้องเรียน',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => (
        <div
          style={{
            color: '#717171',
            fontSize: '12px',
            fontWeight: '400',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '150px',
          }}
        >
          {data?.name?.first_name} {data?.name?.last_name}
        </div>
      ),
    },
  ]

  const getListAllAdmin = async () => {
    const res = await getData({
      schema: `${adminauth2}/all/admin`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        setListAdmin(res.data)
        setLoadingAddOfficer(false)
      } else {
        console.log('Err', res)
      }
    }
  }

  const showDrawerAddOfficer = (e, value, typeDrawer) => {
    setDeparmentData(value?.department_data)
    setComplaintId(value?.id)
    setOpenDrawerAddOfficer(true)
    setTypeDrawer(typeDrawer)
    setTimeout(() => {
      setLoadingAddOfficer(false)
    }, 1000)
  }
  const onCloseAddOfficer = () => {
    setOpenDrawerAddOfficer(false)
    setDeparmentData([])
    setLoadingAddOfficer(true)
  }

  const showDrawerEditOfficer = (e, value, typeDrawer) => {
    setDeparmentData(value?.department_data)
    setComplaintId(value?.id)
    setOpenDrawerEditOfficer(true)
    setTypeDrawer(typeDrawer)
    setTimeout(() => {
      setLoadingAddOfficer(false)
    }, 1000)
  }
  const onCloseEditOfficer = () => {
    setOpenDrawerEditOfficer(false)
    setDeparmentData([])
    setLoadingAddOfficer(true)
  }

  const showDrawerTransfer = (e, value) => {
    setDeparmentData(value?.department_data)
    setComplaintId(value?.id)
    setOpenDrawerTransfer(true)
  }

  const onCloseTransfer = () => {
    setOpenDrawerTransfer(false)
    setDeparmentData([])
  }

  const openNotification = typeNoti => {
    notification.info({
      message: (
        <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>
          {typeNoti === 'transfer' ? 'โอนย้ายเรื่องร้องเรียนสำเร็จ' : typeNoti}
        </div>
      ),
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 5,
    })
    getDataComplant()
  }

  const openNotificationCreate = typeNoti => {
    notification.info({
      message: (
        <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>ส่งเรื่องร้องเรียนสำเร็จ</div>
      ),
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 3,
    })
  }

  const onRowClick = (record, event) => {
    if (event.target.tagName === 'DIV' || event.target.tagName === 'TD') {
      navigate('view', {
        state: {
          record: record?.id,
          group: record?.is_group,
        },
      })
    }
  }

  const onSelectChange = (selectedKeys, selectedRows) => {
    const checkKey = selectedRowKeys.includes(selectedKeys?.id)
    if (checkKey) {
      setSelectedRowKeys(prevArrRow => prevArrRow.filter(id => id !== selectedKeys?.id))
      setArrId(prevArrId => prevArrId.filter(id => id !== selectedKeys?.id))
      setArrGroup(prevArrGroup => prevArrGroup.filter(group => group !== selectedKeys?.id))
    } else {
      const lastSelectedKey = selectedKeys?.id
      setSelectedRowKeys(prevArrRow => [...prevArrRow, lastSelectedKey])
      if (selectedKeys?.is_group) {
        setArrGroup(prevArrGroup => [...prevArrGroup, lastSelectedKey])
      } else {
        setArrId(prevArrId => [...prevArrId, lastSelectedKey])
      }
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectChange,
  }

  const handleAllOutOfGroup = (e, val) => {
    setModalHeadGroup({
      modal: true,
      id: val?.id,
    })
  }

  const handleSelectGroup = () => {
    navigate(`${location?.pathname}/group`, {
      state: {
        id: arrId,
        idGroup: arrGroup,
      },
    })
  }

  const handleConfirmOutOfGroup = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    }
    axios
      .delete(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/group/${modalHeadGroup?.id}`, config)
      .then(res => {
        setModalHeadGroup({
          modal: false,
        })
        openNotification('แตกกลุ่มข้อมูล​เรื่องร้องเรียนสำเร็จ')
        getDataComplant()
      })
      .catch(err => {
        console.log('err', err)
      })
  }
  return (
    <>
      <NotiStyle />
      {contextHolder}
      <BoxMap>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginRight: '5%',
            position: 'absolute',
            top: '2%',
            right: '0%',
          }}
        >
          {isGroup ? (
            <BoxBtn>
              <Button
                className="btn-cancel-group"
                onClick={() => {
                  handleCancelCreateGroup()
                }}
              >
                ยกเลิก
              </Button>
              <Button
                onClick={handleSelectGroup}
                className="btn-create-group"
                disabled={
                  (arrId?.length > 1 && !arrGroup?.length) ||
                  (arrId?.length >= 1 && arrGroup?.length >= 1) ||
                  arrGroup?.length >= 1
                    ? false
                    : true
                }
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                  <FileGroup /> {arrGroup?.length === 1 && !arrId?.length ? 'แก้ไขกลุ่ม' : 'สร้างกลุ่ม'}
                </div>
              </Button>
            </BoxBtn>
          ) : (
            <BoxBtn style={{ display: 'flex', gap: '20px' }}>
              {meData?.position_data?.id === '1730459515154853888' && (
                <Button onClick={handleClickGroup} className="btn-select-group">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                    <Group /> จัดกลุ่ม
                  </div>
                </Button>
              )}
              <Button onClick={() => navigate('create')} className="btn-create-complaint">
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                  <AddComplant /> เพิ่มเรื่องร้องเรียน
                </div>
              </Button>
            </BoxBtn>
          )}
        </div>

        <BoxTable>
          <div>
            <Search
              masterData={masterData}
              setSearchData={setSearchData}
              searchData={searchData}
              conversDate={conversDate}
              loadingTable={loadingTable}
            />
            <div style={{ marginTop: '1%' }}>
              <div
                style={{
                  height: '75vh',
                  width: '100%',
                  paddingRight: '1%',
                  overflow: 'auto',
                }}
              >
                <TableCustom
                  columns={columnsDataTable}
                  dataSource={dataList}
                  noDataText="ไม่พบข้อมูลที่ต้องการค้นหา"
                  widthDisplay={window.innerWidth}
                  onRow={onRowClick}
                  isLoading={loadingTable}
                  scroll={{
                    x: 2000,
                    y: 1000,
                  }}
                  rowSelection={
                    isGroup
                      ? {
                          type: 'checkbox',
                          ...rowSelection,
                        }
                      : undefined
                  }
                />
              </div>
              <div style={{ display: loadingTable ? 'none' : 'block' }}>
                <div style={{ marginTop: windowWidth <= isSizeMobile ? '50px' : '38px', marginBottom: '10px' }}>
                  <PaginationCustom page={curantPage} setPage={setCurantPage} dataLength={dataLength} pageSize={13} />
                </div>
              </div>
            </div>
          </div>
        </BoxTable>
        <div style={{ display: isPrint ? 'block' : 'none', position: 'absolute', bottom: '100%' }}>
          <ExportComplante componentRef={componentRef} data={dataExport} />
        </div>
      </BoxMap>

      {/* Drawer add officer */}
      <AddOfficer
        openDrawerAddOfficer={openDrawerAddOfficer}
        setLeaveModalOpen={setLeaveModalOpen}
        loadingAddOfficer={loadingAddOfficer}
        deparmentData={deparmentData}
        organizeId={organizeId}
        complaintId={complaintId}
        listAdmin={listAdmin}
        setSubmitAddModal={setSubmitAddModal}
        onCloseAddOfficer={onCloseAddOfficer}
        openNotification={openNotification}
      />
      {/* Drawer edit officer */}
      <EditOfficer
        openDrawerEditOfficer={openDrawerEditOfficer}
        setLeaveModalOpen={setLeaveModalOpen}
        loadingAddOfficer={loadingAddOfficer}
        deparmentData={deparmentData}
        organizeId={organizeId}
        complaintId={complaintId}
        listAdmin={listAdmin}
        setSubmitAddModal={setSubmitAddModal}
        onCloseEditOfficer={onCloseEditOfficer}
        openNotification={openNotification}
      />

      <DeleteComplant
        isDeleteCompalnt={isDeleteCompalnt}
        setIsDeleteCompalnt={setIsDeleteCompalnt}
        openNotification={openNotification}
        deleteGroup={isDeleteCompalnt?.isGroup}
      />

      {/* DrawerTransfer */}
      <TransferComplaint
        openDrawerTransfer={openDrawerTransfer}
        onCloseTransfer={onCloseTransfer}
        meData={meData}
        complaintId={complaintId}
        openNotification={openNotification}
      />
      <CustomModal
        transitionName=""
        centered
        open={modalHeadGroup?.modal}
        zIndex={1200}
        onOk={() => {
          handleConfirmOutOfGroup()
        }}
        onCancel={() =>
          setModalHeadGroup({
            modal: false,
          })
        }
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">ต้องการแตกกลุ่มข้อมูล ?</p>
        <p className="modal-message">คุณสามารถรวมกลุ่มได้อีกครั้งโดยการกดเครื่องมือ “จัดกลุ่ม”</p>
      </CustomModal>

      {/* Modal Confirm Leave*/}
      <CustomModal
        transitionName=""
        centered
        open={leaveModalOpen}
        onOk={() => {
          setLeaveModalOpen(false)
          onCloseAddOfficer()
          onCloseEditOfficer()
        }}
        onCancel={() => setLeaveModalOpen(false)}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
        zIndex={1200}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
        <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
      </CustomModal>
    </>
  )
}
export default Index
