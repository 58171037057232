import React from 'react'
import { PaginationCustom, Container, CustomText } from './pagination.style'
import { Pagination } from 'antd'

const Paginations = props => {
  let { defaultCurrent, total, onChange, current, page, setPage, dataLength, pageSize } = props
  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {dataLength > pageSize && (
          <Pagination
            className="middle"
            defaultCurrent={page && page}
            total={dataLength && dataLength}
            pageSize={pageSize && pageSize}
            current={page && page}
            onChange={setPage && setPage}
            showSizeChanger={false}
          />
        )}
      </div>

      {dataLength > 0 && (
        <div style={{ border: '1px solid #f36b24', padding: '5px', borderRadius: '8px', textAlign: 'center' }}>
          ทั้งหมด : {dataLength} รายการ
        </div>
      )}
    </Container>
  )
}

export default Paginations
