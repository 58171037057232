import styled from 'styled-components'
import { Space, Button, Modal } from 'antd'

export const Content = styled.div`
  div {
    font-size: 10px;
  }
`
export const Title = styled.div`
  font-size: 16px !important;
  font-weight: 500;
`

export const ButtonBack = styled(Button)`
  width: 40px;
  height: 40px;
  border: none !important;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus,
  :hover {
    border-color: transparent;
    background: #fbfbfb !important;
  }
`
export const CustomImage = styled.div`
  img {
    max-height: 800px;
  }
`

export const BoxIcon = styled.div`
  background-color: #fff;
  padding: 5px;
  margin: 0 3px;
  display: flex;
  color: #556a82;
  /* max-width: 100px; */
`

export const ModalPreviewImage = styled(Modal)`
  font-family: 'IBM Plex Sans Thai', sans-serif;

  .close-ui {
    position: fixed;
    top: 35px;
    right: 30px;
    padding: 0px;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    cursor: pointer;
    line-height: 35px;
    font-size: 14px;
    font-weight: 800;
    color: #3f54d1;
    z-index: 100;
    transition: none;
    img {
      width: 70px;
      height: 70px;
    }
  }
  .ant-modal-content {
    border-radius: 16px;
    width: auto;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
    .ant-btn-default {
      border-radius: 12px;
      border-color: #00ada4 !important;
      color: #00ada4 !important;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #00ada4 !important;
      border-color: #00ada4;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

export const ModelContent = styled.div`
  .vdo-content {
    position: relative;
    padding: 0;
  }
  .model-ui {
    font-style: normal;
    .header-ui {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 47px;
      color: #19263f;
      text-align: left;
    }
    .model-crop {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
    .controls {
      background: #f8e2e2;
      border-radius: 35px;
      width: 200px;
      padding: 0.2em 12px;
      margin: auto;
      margin-top: 10px;
      display: flex;
      .slider {
        width: 160px;
        padding-left: 4px;
        padding-right: 4px;
      }
      .button-zoom {
        line-height: 34px;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        color: #1f3983;
        width: 20px;
        height: 20px;
        user-select: none;
      }
    }
  }
`

export const BoxTopView = styled.div`
  margin-bottom: 20px;
  .text-title {
    color: #c4c4c4;
    font-size: 10px;
  }
  .text-sub-title {
    font-size: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .to-right {
    font-size: 10px;
  }
`

export const BoxDescription = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1em;
`

export const BoxOrganize = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 1em;
`
export const Boxbg = styled.div`
  background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#ffff')};
  border-radius: ${props => (props.BdRadius ? `${props.BdRadius}` : '16px')};
  padding: ${props => (props.Padding ? `${props.Padding}` : '1em')};
  width: ${props => (props.Bwidth ? `${props.Bwidth}` : '100%')};
  height: 100%;
`

export const TextTile = styled.p`
  color: #f36b24;
  display: -webkit-box;
  max-width: 700px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Prompt;
  font-size: 16px;
  margin: 0;
`

export const ContainerView = styled.div`
  background-color: #ffff;
  border-radius: 24px;
  padding: 12px;
  margin-bottom: 20px;

  .head-color {
    color: #c4c4c4;
  }
`
export const HeaderboxView = styled.div`
  border-radius: 15px;
  border: 1px solid var(--web-backend-extended-orange-400, #ffeee7);
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  span {
    font-size: 8px;
  }
`
export const BtnSubmit = styled.div`
  display: flex;
  justify-content: end;

  margin: 20px;
  padding: 10px 10px 15px 10px;

  .ant-btn-primary {
    border-radius: 8px;
    background-color: #f36b24;
    border-color: #f36b24;
    font-size: 14px;
    width: 180px;
    height: 40px;
    font-weight: 500;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
`
