import { Input, Select, DatePicker, Space, Divider } from 'antd'
import React, { useCallback, memo } from 'react'
import { BoxSearch } from './list.style'
import styled from 'styled-components'
import moment from 'moment'
const { Option } = Select
const { RangePicker } = DatePicker
const Search = ({ masterData, setSearchData, searchData, loadingTable }) => {
  let optionsCategories = masterData?.categories?.map(item => {
    return {
      label: (
        <div>
          <img src={item?.icon_url} style={{ marginRight: '10px', width: '14px', height: '14px' }} />
          {item.name}
        </div>
      ),
      value: item.id,
    }
  })

  let optionsStatus = masterData?.status?.map(item => {
    return {
      label: item.name,
      value: item.name_en,
    }
  })
  let optionsUrgency = masterData?.urgency?.map(item => {
    return {
      label: item?.name,
      value: item.id,
    }
  })
  let optionsChannel = masterData?.channel?.map((items, index) => ({
    value: items?.name_en,
    label: items?.name_th,
    key: index,
  }))
  let optionsSatisfaction = [
    {
      label: 'ยอดเยี่ยม',
      value: 5,
    },
    {
      label: 'ดีมาก',
      value: 4,
    },
    {
      label: 'ดี',
      value: 3,
    },
    {
      label: 'พอใช้',
      value: 2,
    },
    {
      label: 'ควรปรับปรุง',
      value: 1,
    },
  ]

  const handleChange = (value, type) => {
    if (type === 'input') {
      setSearchData({
        ...searchData,
        search: value?.target?.value ? value?.target?.value : '',
      })
    } else {
      setSearchData({
        ...searchData,
        [type]: value ? value : null,
      })
    }
  }

  const thaiYearFormat = date => {
    if (date) {
      const thaiYear = date.year() + 543
      return moment(date).year(thaiYear)
    }
    return null
  }
  const startDate = thaiYearFormat(searchData?.date ? searchData.date[0] : null)
  const endDate = thaiYearFormat(searchData?.date ? searchData.date[1] : null)

  const disabledDate = current => {
    return current && current > moment().endOf('day')
  }

  const RangPickerCallBack = useCallback(() => {
    return (
      <RangePicker
        disabledDate={disabledDate}
        //  locale={customLocale}
        //   format={value => thaiYearFormat(value).format('DD/MM/YYYY')}
        defaultValue={[searchData.date[0], searchData.date[1]]}
        onChange={val => handleChange(val, 'date')}
        format={'DD/MM/YYYY'}
        allowClear={false}
      />
    )
  }, [searchData])

  return (
    <BoxSearch>
      <BoxSelect>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 250px 1fr',
            gap: '10px',
          }}
          className="search-component"
        >
          <Input
            placeholder="ค้นหาจากเรื่องร้องเรียน,เลขที่เรื่องร้องเรียน,ชื่อ,เบอร์โทร"
            value={searchData?.search}
            onChange={val => handleChange(val, 'input')}
            allowClear={true}
          />
          <Select
            placeholder="ประเภททั้งหมด"
            value={searchData?.category}
            options={optionsCategories}
            onSelect={val => handleChange(val, 'category')}
            onClear={val => handleChange(val, 'category')}
            allowClear
          />

          <Select
            placeholder="สถานะทั้งหมด"
            options={optionsStatus}
            value={searchData?.status}
            onSelect={(val, e) => {
              handleChange(e?.value, 'status')
            }}
            onClear={val => handleChange(val, 'status')}
            allowClear
          />
          <Select
            placeholder="ความเร่งด่วน"
            optionFilterProp="label"
            value={searchData?.urgency}
            options={optionsUrgency}
            onSelect={val => handleChange(val, 'urgency')}
            onClear={val => handleChange(val, 'urgency')}
            optionLabelProp="label"
            allowClear
          />
          <Select
            placeholder="ความพึงพอใจ"
            optionFilterProp="label"
            value={searchData?.satisfaction}
            options={optionsSatisfaction}
            onSelect={val => handleChange(val, 'satisfaction')}
            onClear={val => handleChange(val, 'satisfaction')}
            allowClear
          />
          <Select
            placeholder="ประเภทช่องทางการร้องเรียน"
            optionFilterProp="label"
            value={searchData?.channel}
            options={optionsChannel}
            onSelect={val => handleChange(val, 'channel')}
            onClear={val => handleChange(val, 'channel')}
            allowClear
          />
          {/* <RangePicker
            placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
            value={[startDate, endDate]}
            onChange={val => handleChange(val, 'date')}
            format={'DD/MM/YYYY'}
            className="date-picker"
            disabledDate={disabledDate}
          /> */}
          <RangPickerCallBack />
          <span
            style={{
              color: '#556A82',
              textDecorationLine: 'underline',
              paddingTop: '10px',
              cursor: 'pointer',
              width: '60px',
            }}
            onClick={() =>
              setSearchData({
                date: [moment().subtract(30, 'days'), moment()],
              })
            }
          >
            ล้างข้อมูล
          </span>
        </div>
      </BoxSelect>
    </BoxSearch>
  )
}
export default memo(Search)

const BoxSelect = styled.div`
  margin-top: 5px;
  .date-picker,
  .ant-picker-input > input {
    color: #f36b24 !important;
  }

  .ant-picker-range .ant-picker-input > input::placeholder {
    /* Change the placeholder color to red */
    color: #ffc4aa !important;
  }
  .ant-select-dropdown {
    min-width: 155px !important;
    width: 155px !important;
  }
  .ant-select-item-option:hover {
    background-color: red !important; /* Change the background color on hover */
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: red !important; /* Change the background color of the active item */
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f36b24;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffeee7;
  }
`
