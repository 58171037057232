import * as React from 'react'
import { Input, Tooltip, Form } from 'antd'
import classNames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'

import UserContext from 'core/contexts/userContext'
import { getData, postData, getMe, putData, getList } from 'core/action/collection'
import { adminauth2 } from 'core/schemas'

// import ArrowUp from 'assets/images/png/arrowUp.png'
// import ArrowDown from 'assets/images/png/arrowDown.png'
import ArrowDown from 'assets/images/svg/filter-chevron-org.svg'
import ArrowUp from 'assets/images/svg/filter-invert-chevron-org.svg'
import operationTools from 'assets/images/svg/operation-tools-icon.svg'
import alertAnnouncement from 'assets/images/svg/alert-announcement-icon.svg'
import category from 'assets/images/svg/category-icon.svg'
import request from 'assets/images/svg/request-icon.svg'
import report from 'assets/images/svg/report-icon.svg'
import profileIcon from 'assets/images/png/profileIcon.png'
import IconProfileDrawer from 'assets/images/svg/icon-profile-drawer.svg'
import overview from 'assets/images/svg/icon-overview-menu.svg'
import overviewhover from 'assets/images/svg/icon-overview-menu-hover.svg'
import statistic from 'assets/images/svg/icon-statistic-menu.svg'
import chat from 'assets/images/png/chat.png'
import dashboard from 'assets/images/png/dashboard.png'
import Report from 'assets/images/png/report.png'

import manageuser from 'assets/images/svg/icon-manageuser-menu.svg'
import complaint from 'assets/images/png/complaint.png'
import eservice from 'assets/images/png/e-service.png'
import userGroup from 'assets/images/png/user-group.png'

import operationtool from 'assets/images/png/operation-tools.png'
import operation from 'assets/images/png/operation.png'
import usage from 'assets/images/png/usage.png'
import InputFormProfile from 'components/input/InputFormProfile'
import { MockMenuStraf } from './container'
import SiderMenu from './SiderMenu'

import { ReactComponent as Logo } from 'assets/images/svg/logo-korat-city.svg'
import { ReactComponent as LogoName } from 'assets/images/svg/logo-name.svg'
import { ReactComponent as IconMore } from 'assets/images/svg/icon-more.svg'
import { ReactComponent as IconProfile } from 'assets/images/svg/profile-menu-krccs.svg'
import { ReactComponent as IconLogout } from 'assets/images/svg/icon-logout.svg'
// import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-primary.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-drawer.svg'
import { ReactComponent as IconAllowClear } from 'assets/images/svg/icon-allowclear.svg'
// import {ReactComponent as IconProfileDrawer} from 'assets/images/svg/icon-profile-drawer.svg'

import version from 'core/constants/version'

import {
  Content,
  TextMenu,
  BoxVersion,
  BtCollap,
  BoxName,
  CustomMenu,
  CustomDrawer,
  CustomDivider,
  BtLogout,
  CustomInputDrawer,
  BtSubmit,
  MainMenuStyle,
} from './Sider.style'
import './Sider.css'

import { mockUserData, defaultValues, Schema } from './container'
import { useState } from 'react'

const Sider = props => {
  const { setUser, setRoleId } = React.useContext(UserContext)
  const [checkclick, setcheckclick] = React.useState()
  const [checkclickmenu, setcheckclickmenu] = React.useState(0)
  const [collapAction, setCollapAction] = React.useState(false)
  const [subSiderMenu, setSubSiderMenu] = React.useState(false)
  const [slugSelect, setSlugSelect] = React.useState('')
  const [subMenuTitle, setSubMenuTitle] = React.useState()
  const [mainMenu, setMainMenu] = React.useState()
  const [state, setState] = React.useState({})
  const [openMenuProfile, setOpenMenuProfile] = React.useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [dataProfile, setDataProfile] = React.useState(mockUserData)
  const [allMenu, setAllMenu] = React.useState([])
  const [edit, setEdit] = React.useState(false)
  const [defaultValueFromList, setDefaultValueFromList] = React.useState('')
  const [userData, setUserData] = useState('')
  const [dataFromApi, setDataFromApi] = useState('')
  const [phone, setPhone] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const [formAntd] = Form.useForm()
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')
  const [isValidForm, setIsValidForm] = React.useState(false)
  const [isValidFormEdit, setIsValidFormEdit] = React.useState(false)
  const [isUnique, setIsUnique] = React.useState(false)

  const [typeMenu, setTypeMenu] = useState(false)

  React.useEffect(() => {
    getUserProfile()
  }, [typeMenu])

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const formik = useFormik({
    initialValues: userData ? userData : defaultValueFromList,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {
      // console.log(values)
    },
  })

  const initialValues = formik.initialValues.name
  // const isValidForm = formik.isValid && formik.dirty

  const MenuIcon = {
    dashboard,
    chat,
    complaint,
    'e-service': eservice,
    operation,
    'operation-tools': operationtool,
    usage,
    report: Report,
    'user-group': userGroup,
  }

  React.useEffect(() => {
    setMainMenu(window.localStorage.getItem('mainMenu'))
    setSubMenuTitle(window.localStorage.getItem('subMenuTitle'))
    setState(JSON.parse(window.localStorage.getItem('state')) || {})
    setSlugSelect(window.localStorage.getItem('mainMenu'))
  }, [])

  React.useEffect(() => {
    // window.localStorage.setItem('subMenuTitle', subMenuTitle)
    // window.localStorage.setItem('state', JSON.stringify(state))
  }, [mainMenu, subMenuTitle, state])

  React.useEffect(() => {
    formik.values?.mobile_number?.length === 12 && checkValidatePhoneNumber()
  }, [formik.values?.mobile_number])

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const onCollapse = () => {
    setCollapAction(!collapAction)
  }

  const onClickMenu = (value, submenu, isShowSubMenu) => {
    const setclick = checkclick == undefined ? isShowSubMenu : isShowSubMenu
    setcheckclick(setclick)
    const setcheck = checkclickmenu == 0 ? value.sort : value.sort
    if (!submenu) {
      setcheckclickmenu(setcheck)
      setState({ state, [value.key_slug]: isShowSubMenu === undefined ? false : !isShowSubMenu })
      setMainMenu(value.key_slug)
      if (!value?.sub_menus) {
        window.localStorage.setItem('mainMenu', value?.key_slug)
      }
    }
    const slugSub =
      value?.key_slug.split('/').length > 0
        ? value?.key_slug?.split('/')[value?.key_slug?.split('/').length - 1]
        : value?.key_slug
    if (userData?.position_id === '1730459515121299456') {
      if (submenu) {
        setSubMenuTitle(slugSub)
        setSubSiderMenu(false)
        setSlugSelect(submenu?.key_slug)
        window.localStorage.setItem('mainMenu', submenu?.key_slug)
        navigate(`/${submenu?.key_slug}/${value?.key_slug}`)
      } else if (value?.sub_menus) {
        setCollapAction(false)
      } else {
        setSubMenuTitle()
        setSlugSelect(value.key_slug)
        setSubSiderMenu(true)
        value?.key_slug !== '/' && navigate(`/${value?.key_slug}`)
        value?.key_slug === '/' && navigate(`/`)
      }
    } else {
      if (submenu) {
        setSubMenuTitle(slugSub)
        setSubSiderMenu(false)
        setSlugSelect(submenu?.key_slug)
        if (submenu?.key_slug === 'complaint') {
          window.localStorage.setItem('mainMenu', submenu?.key_slug + '-staff')
          navigate(`/${submenu?.key_slug}-staff/${value?.key_slug}`)
        } else {
          window.localStorage.setItem('mainMenu', submenu?.key_slug)
          navigate(`/${submenu?.key_slug}/${value?.key_slug}`)
        }
      } else if (value?.sub_menus) {
        setCollapAction(false)
      } else {
        setSubMenuTitle()
        setSlugSelect(value.key_slug)
        setSubSiderMenu(true)
        if (value?.key_slug === 'complaint') {
          window.localStorage.setItem('mainMenu', submenu?.key_slug + '-staff')
          navigate(`/${value?.key_slug}-staff`)
        } else {
          window.localStorage.setItem('mainMenu', submenu?.key_slug)
          value?.key_slug !== '/' && navigate(`/${value?.key_slug}`)
          value?.key_slug === '/' && navigate(`/`)
        }
      }
    }
  }

  React.useEffect(() => {
    const path = pathname?.split('/')

    const result = allMenu && allMenu?.find(({ key_slug }) => key_slug === path[2])
    if (result?.sub_menus && result?.sub_menus.length > 0) {
      setSlugSelect(path[2])
      setSubSiderMenu(false)
    } else {
      // setSlugSelect(path[2])
      setSubSiderMenu(true)
    }
    // eslint-disable-next-line
  }, [pathname])

  React.useEffect(() => {
    getUserProfile()
  }, [])

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    }
  }

  const items = [getItem('โปรไฟล์', 'profile', <IconProfile />), getItem('ออกจากระบบ', 'logout', <IconLogout />)]

  const onClickMenuProfile = async e => {
    if (e.key === 'logout') {
      await postData('/logout').catch(err => {
        console.log(err)
      })
      navigate('/login', { state: { showNoti: true } })
      setUser(null)
      localStorage.removeItem('state')
      localStorage.user = ''
      localStorage.clear()
      localStorage.removeItem('token')
      setRoleId(null)
    } else if (e.key === 'profile') {
      setOpenDrawer(true)
    }
    setOpenMenuProfile(false)
  }

  const getToken = localStorage.getItem('access_token')
  const getUserProfile = async () => {
    const res = await getMe({ token: getToken })
    const path = pathname.split('/')
    if (res) {
      if (res?.code === 200) {
        setAllMenu(res?.data?.menus)
        setPhone(phoneNumberAutoFormat(res.data.mobile_number))
        setUserData({
          firstname: res?.data?.first_name?.th,
          lastname: res?.data?.last_name?.th,
          position: res?.data?.position_data?.name,
          position_id: res?.data?.position_data?.id,
          email: res?.data?.email,
          department: res?.data?.organize_data?.name,
          mobile_number: phoneNumberAutoFormat(res?.data?.mobile_number),
          line_id: res?.data?.line_id,
          role: res?.data?.roles_data[0]?.name,
          line_name: res?.data?.line_name,
        })
        localStorage.setItem('user_id', res.data?.id)
        setDataFromApi(res.data)
        setTel(phoneNumberAutoFormat(res?.data?.mobile_number))
        setEmail(res.data.email)
        formAntd.setFieldValue('tel', phoneNumberAutoFormat(res?.data?.mobile_number))
        formAntd.setFieldValue('email', res?.data?.email)

        const result = res?.data?.menus?.find(({ key_slug }) => key_slug === path[1])
        if (result?.sub_menus && result?.sub_menus?.length > 0) {
          // setSubMenuTitle(result?.sub_menus)
          setSlugSelect(path[1])
          setSubSiderMenu(false)
        } else {
          setSlugSelect(path[1])
          setSubSiderMenu(true)
        }
      }
    }
  }

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber?.trim().replace(/[^0-9]/g, '')

    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = async e => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formAntd.setFieldValue('tel', targetValue)
  }

  const checkValidatePhoneNumber = async () => {
    if (formik.values?.mobile_number?.length === 12 && formik.values.mobile_number !== phone) {
      const mobile_number = formik.values.mobile_number.replace(/-/g, '')
      const res = await getList({
        schema: `${adminauth2}/admin`,
        params: {
          page: 1,
          per_page: 10,
          sorts: [],
          search: [],
          filters: [`mobile_number:eq:${mobile_number}`],
        },
        url: '',
        baseURL: '',
      })

      if (res?.code === 200) {
        formik.setFieldError('mobile_number', 'เบอร์โทรศัพท์ ได้ถูกใช้ไปแล้ว กรุณากรอกใหม่อีกครั้ง')
      } else {
        console.log('resError', res)
      }
    }
  }

  const onLogout = async () => {
    const res = await postData(`${adminauth2}/logout`)

    if (res?.code === 200) {
      navigate('/login', { state: { showNoti: true } })
      setUser(null)
      localStorage.removeItem('state')
      localStorage.user = ''
      localStorage.clear()
      localStorage.removeItem('token')
      setRoleId(null)
    } else {
      console.log('logoutError', res)
    }
  }

  const onSubmit = async () => {
    const mobileNumber = formAntd.getFieldValue('tel')?.replace(/-/g, '')
    const payload = {
      available_at: dataFromApi?.available_at,
      email: formAntd.getFieldValue('email'),
      first_name: {
        en: '',
        requiredEn: false,
        requiredTh: true,
        th: dataFromApi?.first_name?.th,
      },
      last_name: {
        en: '',
        requiredEn: false,
        requiredTh: true,
        th: dataFromApi?.last_name?.th,
      },
      group_id: dataFromApi?.group_data?.id,
      mobile_number: mobileNumber,
      organize_id: dataFromApi?.organize_data?.org_id,
      position_id: dataFromApi?.position_data?.id,
      prefix: {
        en: 'string',
        requiredEn: false,
        requiredTh: false,
        th: 'string',
      },
      role_id: [dataFromApi?.roles_data[0].id],
      status: dataFromApi?.status,
      status_activate: dataFromApi?.status_activate,
      password: 'a0123456789',
    }

    const res = await putData(`${adminauth2}/admin`, dataFromApi?.id, payload)

    if (res?.code === 200) {
      setEdit(false)
      getUserProfile()
    } else {
    }
  }

  const onDrawerProfileClose = () => {
    setOpenDrawer(false)
    setEdit(false)
  }

  const changeModeEdit = () => {
    setEdit(!edit)
    formAntd.setFieldValue('tel', tel)
    formAntd.setFieldValue('email', email)
  }

  const TogggoleSidebar = () => {
    props?.setOpenSiderMobile(false)
  }

  const uniqueValidateMobile = async value => {
    if (formAntd?.getFieldValue('tel') === tel) return false
    if (formAntd.getFieldValue('tel')?.length < 12) {
      formAntd.setFields([
        {
          name: 'tel', // required
          value: formAntd.getFieldValue('tel'), //optional
          errors: ['รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง'],
        },
      ])
      setIsValidForm(false)
      setIsValidFormEdit(false)
    }
    if (formAntd.getFieldValue('tel')?.length === 12) {
      const mobileNumber = formAntd.getFieldValue('tel')?.replace(/-/g, '')
      const res = await getList({
        schema: `${adminauth2}/admin`,
        params: { page: 1, per_page: 10, filters: [`mobile_number:eq:${mobileNumber}`] },
        url: '',
        baseURL: '',
      }).catch(err => {
        console.log('err', err)
      })

      if (res?.code === 200) {
        setTimeout(() => {
          formAntd.setFields([
            {
              name: 'tel', // required
              value: formAntd.getFieldValue('tel'), //optional
              errors: ['หมายเลขโทรศัพท์นี้ ถูกใช้งานแล้ว กรุณากรอกหมายเลขอื่น'],
            },
          ])
          setIsUnique(value)
          setIsValidForm(false)
          setIsValidFormEdit(false)
        }, 100)
      } else {
        formAntd.setFields([
          {
            name: 'tel', // required
            value: formAntd.getFieldValue('tel'), //optional
            errors: '',
          },
        ])
      }
    }
  }

  const uniqueValidateEmail = async value => {
    if (formAntd?.getFieldValue('email') === email) return false
    if (
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formAntd?.getFieldValue('email')) === false &&
      formAntd?.getFieldValue('email')?.length
    ) {
      formAntd.setFields([
        {
          name: 'email', // required
          value: formAntd.getFieldValue('email'), //optional
          errors: ['รูปแบบอีเมลล์ไม่ถูกต้อง'],
        },
      ])
      setIsValidForm(false)
      setIsValidFormEdit(false)
    } else {
      const res = await getList({
        schema: `${adminauth2}/admin`,
        params: { page: 1, per_page: 10, filters: [`email:eq:${formAntd?.getFieldValue('email')}`] },
        url: '',
        baseURL: '',
      }).catch(err => {
        console.log('err', err)
      })

      if (state?.isEdit) {
        if (res?.code === 200 && res?.items?.[0]?.name === initialValues) {
          setIsUnique(false)
          return
        }
      }
      if (res?.code === 200) {
        setTimeout(() => {
          formAntd.setFields([
            {
              name: 'email', // required
              value: formAntd.getFieldValue('email'), //optional
              errors: ['E-mail นี้ ถูกใช้งานแล้ว กรุณากรอก E-mail อื่น'],
            },
          ])
          setIsUnique(value)
          setIsValidForm(false)
          setIsValidFormEdit(false)
        }, 1000)
      } else {
        formAntd.setFields([
          {
            name: 'email', // required
            value: formAntd.getFieldValue('email'), //optional
            errors: '',
          },
        ])
      }
    }
  }

  const handleChange = () => {
    setIsValidForm(
      formik.isValid &&
        formik.dirty &&
        formAntd.getFieldsError()[0]?.errors?.length === 0 &&
        formAntd.getFieldsError()[1]?.errors?.length === 0
    )
    setIsValidFormEdit(
      (formik.isValid || formik.dirty) &&
        (formAntd?.getFieldValue('tel') !== tel || formAntd?.getFieldValue('email') !== email) &&
        formAntd?.getFieldValue('tel').length === 12
    )
  }
  return (
    <Content collapAction={collapAction} subSiderMenu={subSiderMenu}>
      <div style={{ height: '100%' }}>
        <div className="sider-menu">
          <div className="touch-logo">{windowWidth <= 1366 ? <Logo onClick={TogggoleSidebar} /> : <Logo />}</div>
          <SiderMenu
            allMenu={allMenu}
            checkclick={checkclick}
            checkclickmenu={checkclickmenu}
            slugSelect={slugSelect}
            onClickMenu={onClickMenu}
            MenuIcon={MenuIcon}
            state={state}
            userData={userData}
            subMenuTitle={subMenuTitle}
            responsive={windowWidth < 900 ? true : false}
          />
          <BoxVersion>{version}</BoxVersion>
          <BoxName onClick={() => setOpenDrawer(true)}>
            <IconProfile />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p>{formik.values?.firstname ? `${formik.values.firstname} ${formik.values.lastname}` : `-`}</p>
              <p>({userData?.position})</p>
              <p>({userData?.department})</p>
            </div>
            {/* <IconMore style={{ cursor: 'pointer' }} onClick={() => setOpenMenuProfile(!openMenuProfile)} /> */}
          </BoxName>
          {openMenuProfile && <CustomMenu onClick={onClickMenuProfile} mode="vertical" items={items} />}
          <CustomDrawer
            placement="right"
            onClose={onDrawerProfileClose}
            closable={false}
            open={openDrawer}
            closeIcon={<IconClear />}
            width={600}
          >
            <div style={{ height: '6.5em', position: 'relative' }}>
              <div className="close-icon" onClick={onDrawerProfileClose}>
                <IconClear />
              </div>
            </div>
            <div className="title">
              <h1>โปรไฟล์ผู้ใช้งาน</h1>
              <img src={IconProfileDrawer} alt="profile icon" />
            </div>

            {formik.values && (
              <>
                <form onSubmit={formik.handleSubmit}>
                  <div style={{ padding: '20px 40px' }}>
                    <Form
                      form={formAntd}
                      layout="vertical"
                      // onValuesChange={handleChange}
                      style={{ paddingBottom: '0px', height: '190px', maxWidth: '824px' }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className="info-title">ข้อมูลส่วนตัว</p>
                        <p className="edit-profile" onClick={changeModeEdit} style={{ cursor: 'pointer' }}>
                          {edit ? 'ยกเลิก' : 'แก้ไขข้อมูล'}
                        </p>
                      </div>
                      <CustomDivider />
                      <div className="row-data">
                        <div className="col-1">
                          <p className="text-head">ชื่อ-นามสกุล</p>
                          <p className="text-data">
                            {dataFromApi.first_name.th
                              ? `${dataFromApi.first_name.th} ${dataFromApi.last_name.th}`
                              : '-'}
                          </p>
                        </div>
                      </div>
                      <CustomDivider />
                      <div className="row-data">
                        <div className="col-1">
                          <p className="text-head">ตำแหน่ง</p>
                          <p className="text-data">
                            {dataFromApi.position_data.name ? dataFromApi.position_data.name : '-'}
                          </p>
                        </div>
                        <div className="col-2">
                          <CustomDivider type="vertical" />
                        </div>
                        <div className="col-1">
                          <p className="text-head-right">หน่วยงาน</p>
                          <p className="text-data-department">
                            {dataFromApi.organize_data.name ? dataFromApi.organize_data.name : '-'}
                          </p>
                        </div>
                      </div>
                      <CustomDivider />
                      <div className="row-data">
                        <div className="col-1">
                          <p className="text-head">เบอร์โทรติดต่อ</p>
                          {!edit && (
                            <p className="text-data">
                              {dataFromApi.mobile_number ? phoneNumberAutoFormat(dataFromApi.mobile_number) : '-'}
                            </p>
                          )}
                          {edit && (
                            <div style={{ width: '244px', height: 'auto' }}>
                              <Form.Item name="tel">
                                <Input
                                  placeholder="กรอกหมายเลขโทรศัพท์"
                                  onBlur={uniqueValidateMobile}
                                  onMouseLeave={uniqueValidateMobile}
                                  maxLength={12}
                                  onChange={e => {
                                    handleChange()
                                    onPhoneNumberChange(e)
                                    formAntd.setFields([
                                      {
                                        name: 'tel', // required
                                        value: formAntd.getFieldValue('tel'), //optional
                                        errors: '',
                                      },
                                    ])
                                  }}
                                />
                              </Form.Item>
                            </div>
                          )}
                        </div>
                        <div className="col-2">
                          <CustomDivider type="vertical" />
                        </div>
                        <div className="col-1">
                          <p className="text-head-right">อีเมลล์</p>
                          {!edit && (
                            <p className="text-data-right" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {dataFromApi.email ? dataFromApi.email : '-'}
                            </p>
                          )}
                          {edit && (
                            <div style={{ width: '244px', height: 'auto', paddingLeft: '32px' }}>
                              <Form.Item name="email">
                                <Input
                                  placeholder="กรอกอีเมลล์"
                                  onBlur={uniqueValidateEmail}
                                  onMouseLeave={uniqueValidateEmail}
                                  onChange={e => {
                                    handleChange()
                                    formAntd.setFields([
                                      {
                                        name: 'email', // required
                                        value: formAntd.getFieldValue('email'), //optional
                                        errors: '',
                                      },
                                    ])
                                  }}
                                />
                              </Form.Item>
                            </div>
                          )}
                        </div>
                      </div>
                      <CustomDivider />
                      <div className="row-data">
                        <div className="col-1">
                          <p className="text-head">ไอดีไลน์</p>
                          <p className="text-data">{formik.values.line_name ? formik.values.line_name : '-'}</p>
                        </div>
                        <div className="col-2">
                          <CustomDivider type="vertical" />
                        </div>
                        <div className="col-1">
                          <p className="text-head-right">สิทธิการใช้งาน</p>
                          <p className="text-data-right">
                            {dataFromApi.roles_data[0].name ? dataFromApi.roles_data[0].name : '-'}
                          </p>
                        </div>
                      </div>
                      <CustomDivider />
                      {!edit && (
                        <div
                          style={{
                            width: '100%',
                            height: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '7.5em',
                            marginBottom: '7.5em',
                          }}
                        >
                          <BtLogout onClick={onLogout}>ออกจากระบบ</BtLogout>
                        </div>
                      )}
                      {edit && (
                        <div
                          style={{
                            width: '100%',
                            height: 'auto',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '4em',
                            marginBottom: '4em',
                          }}
                        >
                          <BtSubmit disabled={!isValidFormEdit} onClick={onSubmit}>
                            ยืนยัน
                          </BtSubmit>
                        </div>
                      )}
                    </Form>
                  </div>
                </form>
              </>
            )}
          </CustomDrawer>
        </div>
      </div>
    </Content>
  )
}

export default Sider
