import styled from 'styled-components'
import { Menu } from 'antd'
export const Menucustom = styled(Menu)`
  padding: 8px !important;
  width: 160px !important;
  .ant-dropdown-menu-item:hover {
    background-color: #ffffff;
  }
  .ant-dropdown-menu-title-content:hover {
    background-color: #ffeee7;
    color: #f36b24;
    border-radius: 4px;
  }
`
export const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-radius: 8px;
  gap: 15px;

  .ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    width: 162px;
    border-radius: 8px;
  }
`
export const BoxbgChart = styled.div`
  background-color: rgb(251, 251, 251);
  border-radius: 12px;
  padding: 24px;
  margin: 0 10px;
`

export const BoxPointMap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  .point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => (props.BgColor ? props.BgColor : '')};
  }

  span {
    font-size: 10px;
    color: #717171;
    font-weight: 300;
  }
`
export const BoxLegendChart = styled.div`
  .point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => (props.BgColor ? props.BgColor : '')};
  }
`

export const BoxOverAll = styled.div`
  margin: 0 40px 40px 40px;

  @media only screen and (max-width: 820px) {
    margin: 0 20px 20px 20px;
  }
`

export const Stat = styled.div`
  display: inline-flex;
  align-items: flex-start;
`

export const StatPath = styled.p`
  color: #717171;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 20px;
`

export const StatNow = styled.p`
  color: #f36b24;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 20px;
`

export const HeaderBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 18px;
  .btn-dowload {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f36b24;
    color: #f36b24;
    align-items: center;
    display: flex;
    height: 40px;
  }

  .btn-print {
    border-radius: 10px;
    background-color: #f36b24;
    border: 1px solid #f36b24;
    color: #fff;
    align-items: center;
    display: flex;
    height: 40px;
  }
`

export const HeaderTopic = styled.h1`
  font-family: Prompt;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  text-align: center;
  margin-top: 33px;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    text-align: left;
  }

  @media only screen and (max-width: 635px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    text-align: left;
  }

  @media only screen and (max-width: 376px) {
    font-size: 14px;
    text-align: left;
  }
`

export const ButtonBox = styled.button`
  width: 160px;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 8px;
  background: #f36b24;
  border: none;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  margin-right: 16px;
  color: #fff;
`

export const ButtonPrint = styled.button`
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #f36b24;
  background: #fff;
  color: #f36b24;
`

export const BoxForm = styled.div`
  display: flex;
  flex: 1;
  /* height: 90px; */
  border-radius: 12px;
  background: rgba(252, 101, 33, 0.03);
  margin: 16px 0px 16px 0px;
  padding: 10px 15px;
`

export const BoxAllState = styled.div`
  padding: 24px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
`

export const MonthText = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const FlexContainer = styled.div`
  display: flex;
  gap: 24px;

  @media only screen and (max-width: 820px) {
    flex-direction: column;
  }
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  @media only screen and (max-width: 820px) {
  }
`

export const StyledImage = styled.img`
  padding-bottom: 10px;
`

export const BoxNumOfComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 12px 24px;
  border-radius: 12px;
  background: #fffbf8;
`

export const LeftBoxNumOfComp = styled.div`
  display: flex;
  padding-top: 15px;
  gap: 8px;
`

export const BoxsSatus = styled.div`
  /* height: 400px; */
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

export const TopicHeaderStatus = styled.div`
  display: flex;
  gap: 8px;
`

export const TextSatus = styled.h2`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

export const TextSatusStay = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
`

export const BoxSatisfaction = styled.div`
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

export const RightBox = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
`

export const FlexOvEx = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;

  @media only screen and (max-width: 820px) {
    flex-direction: column;
  }
`

export const BoxOverLoad = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

export const BoxExigent = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

export const FlexAgency = styled.div`
  border-radius: 12px;
  background: #fbfbfb;
  padding: ${props => (props.padding ? props.padding : '24px')};
  margin-top: ${props => (props.isPrint ? '15px' : '24px')};
  flex: 1;
`

export const BottomBox = styled.div`
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
  margin-top: 24px;
  flex: 1;
  min-height: 360px;
`

export const NumStyle = styled.p`
  font-family: Prompt;
  padding-top: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
`
