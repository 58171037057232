import List from './list'
import View from './view'
import Form from './form'
import Group from './group'
import UpdateStatusPc from './update-status/view'
import FormUpdateStatus from './update-status/form'

const ComplaintAdmin = { List, View, Group, Form, UpdateStatusPc, FormUpdateStatus }

export default ComplaintAdmin
