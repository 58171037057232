import React, { useEffect } from 'react'
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import { OSM, TileWMS } from 'ol/source'
import Heatmap from 'ol/layer/Heatmap'
import Point from 'ol/geom/Point'
import { Feature } from 'ol'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { fromLonLat } from 'ol/proj'
import TileImage from 'ol/source/TileImage'
import { ReactComponent as Add } from './Image/Add.svg'
import { ReactComponent as Minus } from './Image/Minus.svg'
import OLVectorLayer from 'ol/layer/Vector'
import { Icon, Style, Fill, Stroke } from 'ol/style'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import GeoJSON from 'ol/format/GeoJSON'

const OpenLayersHeatmap = ({ mapData, zooms, height, isPrint }) => {
  const styles = feature => {
    return new Style({
      fill: new Fill({
        color: '#EAEAEB',
      }),
      stroke: new Stroke({
        color: '#fefeff',
        width: 2,
      }),
    })
  }

  const checkClorGa = data => {
    if (data === 'tracking') {
      return '#bd5626'
    } else if (data === 'received') {
      return '#390768'
    } else if (data === 'in progress') {
      return '#c2da6e'
    } else if (data === 'done') {
      return '#2abd80'
    } else if (data === 'over duedate') {
      return '#c92626'
    } else {
      return '#3e4ccc'
    }
  }

  const ColorGadiant = mapData && mapData?.map(item => checkClorGa(item?.status_name))

  if (mapData.length === 1) {
    const colorCustom = checkClorGa(mapData[0].status_name)

    ColorGadiant.push(colorCustom)
  }

  useEffect(() => {
    const points = mapData?.flatMap(status =>
      status?.coordinates_data?.map(coord => {
        const point = new Point(fromLonLat([coord?.longitude, coord?.latitude]))
        return new Feature(point, { status_name: status?.status_name })
      })
    )

    const heatmapLayer = new Heatmap({
      source: new VectorSource({
        features: points,
      }),
      blur: mapData?.length === 1 ? 10 : 15,
      radius: 5,
      // gradient: ColorGadiant,
      // gradient: ['#af4343', '#ffff', '#ffff', '#ffff', '#fff', '#fff'],
      gradient: ColorGadiant,
    })

    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(NakhonRatchasima, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      }),
    })

    const vectorLayer = new OLVectorLayer({
      source: vectorSource,
      style: styles,
      background: '#FAFAFA',
    })

    const tileLayer = new TileLayer({
      source: new TileImage({
        url: 'https://mt1.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}',
        crossOrigin: 'anonymous',
      }),
      style: styles,
    })

    const center = fromLonLat([102.0859614, 14.9742147]) // Convert coordinates to OpenLayers format

    const map = new Map({
      target: 'map',
      layers: [vectorLayer, heatmapLayer],
      view: new View({
        center: center,
        zoom: zooms ? zooms : 13,
      }),
      controls: [],
    })

    return () => {
      map.dispose()
    }
  }, [mapData, zooms, height])
  return (
    <div
      id="map"
      style={{ width: isPrint ? '380px' : '100%', height: height ? height : '400px', position: 'relative' }}
    >
      {!isPrint && (
        <div
          style={{
            position: 'absolute',
            bottom: '2%',
            right: '2%',
            background: '#fff',
            borderRadius: '10px',
            padding: '10px',
            zIndex: 1,
          }}
        >
          <div style={{ cursor: 'pointer' }}>
            <Add />
          </div>
          <hr style={{ margin: 0, border: '1px solid #FFC4AA' }} />
          <div style={{ marginTop: '5px', cursor: 'pointer' }}>
            <Minus />
          </div>
        </div>
      )}
    </div>
  )
}

export default OpenLayersHeatmap
